import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { SelectBar } from 'components/SelectBar';
import { CommonDataGrid } from 'components/CommonDataGrid';
import useHttp from 'hooks/useHttp';
import { isLoading } from 'utils/misc';
import { employeeTeamsColumns, renderRemoveButton } from 'utils/dataColumns/employeeTeamsColumns';
import { DEFAULT_EMPLOYEE_TEAM_URL } from 'api/employeeTeam';
import { DEFAULT_TEAM_URL } from 'api/team';
import { HTTP_METHODS } from 'constants';

const StyledOutput = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
}));

export const EmployeeTeamsModal = ({ open, onClose, employeeId }) => {
    const {
        data: teamsData,
        error: errorGetTeams,
        sendRequest: getTeams,
        loading: loadingTeamsData,
    } = useHttp();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: addToTeam,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataDelete,
        error: errorDelete,
        clearData: clearDataDelete,
        sendRequest: removeFromTeam,
        loading: loadingDelete,
    } = useHttp();
    const {
        data: dataEmployeeTeams,
        error: errorGetEmployeeTeams,
        sendRequest: getEmployeeTeams,
        loading: loadingEmployeeTeamsData,
    } = useHttp();
    const [messageType, setMessageType] = useState();
    const [responseMessage, setResponseMessage] = useState(
        'When you select the team, the employee will be automatically included. To remove an employee from the team, click on the X.'
    );
    const [refreshData, setRefreshData] = useState(false);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        (async () => {
            await getTeams({
                url: DEFAULT_TEAM_URL,
            });
        })();
    }, []);

    useEffect(() => {
        if (employeeId || refreshData) {
            (async () => {
                getEmployeeTeams({
                    url: `${DEFAULT_EMPLOYEE_TEAM_URL}/employee/${employeeId}`,
                });
                setRefreshData(false);
            })();
        }
    }, [employeeId, refreshData]);

    useEffect(() => {
        if (errorGetTeams !== null || errorGetEmployeeTeams != null) {
            setResponseMessage(
                errorGetTeams.response?.data?.message ||
                errorGetEmployeeTeams.response?.data?.message ||
                'An error occurred'
            );
            setMessageType('error');
        }
    }, [errorGetTeams, errorGetEmployeeTeams]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            setRefreshData(true);
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataDelete();

        if (errorDelete !== null) {
            setResponseMessage(errorDelete.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataDelete) {
            setRefreshData(true);
        }
    }, [errorDelete, dataDelete, messageType]);

    useEffect(() => {
        if (dataEmployeeTeams) {
            const updatedRows = dataEmployeeTeams.map((employeeTeam) => ({
                id: employeeTeam.id,
                name: employeeTeam.name,
            }));
            setRows(updatedRows);
        }
    }, [dataEmployeeTeams]);

    const handleSelect = async (selectedOption) => {
        setMessageType('information');
        if (selectedOption && !dataEmployeeTeams.some((team) => team.id === selectedOption.id)) {
            await addEmployeeToTeam(selectedOption.id);
        }
    };

    const removeEmployeeFromTeam = async (teamId) => {
        await removeFromTeam({
            url: `${DEFAULT_EMPLOYEE_TEAM_URL}/employee/${employeeId}/team/${teamId}`,
            method: HTTP_METHODS.DELETE,
        });
    };

    const addEmployeeToTeam = async (teamId) => {
        const requestBody = {
            employeeId: employeeId,
            teamId: teamId,
        };

        await addToTeam({
            url: DEFAULT_EMPLOYEE_TEAM_URL,
            method: HTTP_METHODS.POST,
            data: requestBody,
        });
    };

    const displayOptions = (option) =>
        `${option.name} ${dataEmployeeTeams.some((team) => team.id === option.id) ? ' ✔' : ''}`;

    const options = [
        ...(teamsData || []).map((team) => ({
            id: `${team.id}`,
            name: `${team.name}`,
        })),
    ];

    const columnsForEmployeeTeams = employeeTeamsColumns.map((column) => {
        if (column.field === 'remove') {
            return {
                ...column,
                renderCell: (params) =>
                    renderRemoveButton(
                        params,
                        removeEmployeeFromTeam,
                        loadingEmployeeTeamsData,
                        loadingTeamsData,
                        loadingCreate,
                        loadingDelete
                    ),
            };
        }
        return column;
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                Teams
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent
                style={{
                    minHeight: '280px',
                    overflowY: 'auto',
                }}
            >
                <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                {dataEmployeeTeams.length === 0 && (
                    <MessageBox messageType="warning">
                        The employee is not a member of any team.
                    </MessageBox>
                )}
                <StyledOutput>
                    <SelectBar
                        onSelect={handleSelect}
                        options={options}
                        displayOptions={displayOptions}
                        isDisabled={isLoading(
                            loadingEmployeeTeamsData,
                            loadingTeamsData,
                            loadingCreate,
                            loadingDelete
                        )}
                        placeholder="Choose a team"
                    />
                    {dataEmployeeTeams.length > 0 && (
                        <div style={{ height: '100%', width: '100%', paddingTop: '24px' }}>
                            <CommonDataGrid
                                rows={rows}
                                columns={columnsForEmployeeTeams.filter(
                                    (column) => column.field !== 'id'
                                )}
                                useCustomToolbar={false}
                            />
                        </div>
                    )}
                </StyledOutput>
            </DialogContent>
        </Dialog>
    );
};

EmployeeTeamsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    employeeId: PropTypes.string,
};
