import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@mui/material';
import { hexToRgba } from 'utils/misc';
import { theme } from 'utils/theme';

export const AbsencesQuotaCardUsed = ({ title, data }) => {
    const cardStyle = {
        minWidth: 600,
        margin: '8px',
    };

    const names = Object.keys(data);
    const days = Object.values(data);

    return (
        <Card style={cardStyle}>
            <CardContent sx={{ backgroundColor: hexToRgba(theme.palette.colorGray.main, 0.2) }}>
                <Typography variant="h6">{title}</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {names.map((name) => (
                                    <TableCell key={name} align="center">
                                        {name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {days.map((day, index) => (
                                    <TableCell
                                        key={index}
                                        align="center"
                                        style={{
                                            color:
                                                day > 0
                                                    ? theme.palette.blackColor.main
                                                    : theme.palette.colorGray.main,
                                            fontSize: '16px',
                                        }}
                                    >
                                        {day}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

AbsencesQuotaCardUsed.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
};
