import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Timeline from 'react-calendar-timeline/lib';
import 'react-calendar-timeline/lib/Timeline.css';
import DateHeader from 'react-calendar-timeline/lib/lib/headers/DateHeader';
import TimelineHeaders from 'react-calendar-timeline/lib/lib/headers/TimelineHeaders';
import { format, startOfMonth, endOfMonth, addDays, differenceInDays } from 'date-fns';
import { COLORS, DATE_FORMAT_BACKEND, EMPLOYEE, LOADER_APPEAR_AFTER } from 'constants';
import useHttp from 'hooks/useHttp';
import { GET_EMPLOYEES_SUMMARY } from 'api/employeDashboard';
import { CustomTooltip } from 'components/CustomTooltip';
import '../assets/css/calendar.css';
import { AbsenceCancelModal } from 'components/AbsenceCancelModal';
import { AbsencesSummaryModal } from 'components/AbsencesSummaryModal';
import { getEmployeeFullName, getEmployeeId, isAdmin } from 'utils/auth';
import { AbsenceCancelMenu } from 'components/AbsenceCancelMenu';
import {
    absenceTooltipContent,
    employeeTooltipContent,
    eventTooltipContent,
} from 'utils/contentTooltips';
import { Avatar, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { HOLIDAYS_BY_DATES_URL } from 'api/holiday';
import { hexToRgba } from 'utils/misc';
import SidebarHeader from 'react-calendar-timeline/lib/lib/headers/SidebarHeader';

export const Calendar = ({
    dateChangeValue,
    createAbsence,
    chipsChangeValue,
    dateValidationError,
    onError,
    employeeSummary,
    cancelAbsenceMessage,
}) => {
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(startOfMonth(currentDate));
    const [items, setItems] = useState([]);
    const [isCancelAbsenceModalOpen, setIsCancelAbsenceModalOpen] = useState(false);
    const [isCalendarDataRefreshNeeded, setIsCalendarDataRefreshNeeded] = useState(false);
    const [menuCoordinates, setMenuCoordinates] = useState(null);
    const [absenceStartDate, setAbsenceStartDate] = useState();
    const [menuOpen, setMenuOpen] = useState(false);
    const [absenceId, setAbsenceId] = useState('');
    const [groups, setGroups] = useState([]);
    const [endDate, setEndDate] = useState(endOfMonth(currentDate));
    const {
        data: employeesSummary,
        error: errorGetEmployeesSummary,
        loading,
        sendRequest: getEmployeesSummary,
    } = useHttp();
    const { data: holidaysByRange, sendRequest: getHolidays } = useHttp();
    const [employeeId, setEmployeeId] = useState('');
    const [title, setTitle] = useState('');
    const [isAbsencesSummaryModalOpen, setIsAbsencesSummaryModalOpen] = useState(false);
    const [vacationColor, setVacationColor] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const calendarHeaderWidthRef = useRef();

    const defaultStartDate = format(startDate, DATE_FORMAT_BACKEND);
    const defaultEndDate = format(endDate, DATE_FORMAT_BACKEND);

    const isDateValid = (date) => {
        return !isNaN(new Date(date));
    };

    const isDateRangeValid =
        dateChangeValue !== null &&
        isDateValid(dateChangeValue[0]) &&
        isDateValid(dateChangeValue[1]) &&
        new Date(dateChangeValue[0]).getFullYear() >= 1900 &&
        new Date(dateChangeValue[1]).getFullYear() <= 2099;

    useEffect(() => {
        if (dateValidationError === null) {
            const fetchEmployees = async (url) => {
                await getEmployeesSummary({
                    url,
                });
            };

            setShowLoader(false);
            setTimeout(() => {
                setShowLoader(true);
            }, LOADER_APPEAR_AFTER);

            const buildQueryString = () => {
                const teamIds = new Set();
                const employeeIds = new Set();

                chipsChangeValue.forEach((item) => {
                    const [id, type] = item.value.split('_');
                    if (type === 'team') {
                        teamIds.add(id);
                    } else if (type === 'user') {
                        employeeIds.add(id);
                    }
                });

                const params = new URLSearchParams();

                if (isDateRangeValid) {
                    const startDateChange = format(dateChangeValue[0], DATE_FORMAT_BACKEND);
                    const endDateChange = format(dateChangeValue[1], DATE_FORMAT_BACKEND);

                    setStartDate(new Date(dateChangeValue[0]));
                    setEndDate(addDays(new Date(dateChangeValue[1]), 1));

                    params.append('StartDate', startDateChange);
                    params.append('EndDate', endDateChange);
                }

                teamIds.forEach((id) => {
                    params.append('TeamIds', id);
                });

                employeeIds.forEach((id) => {
                    params.append('EmployeeIds', id);
                });

                return params.toString();
            };

            if (chipsChangeValue.length > 0 || isDateRangeValid) {
                const queryStringParts = buildQueryString();
                fetchEmployees(`${GET_EMPLOYEES_SUMMARY}?${queryStringParts}`);
            }

            if (chipsChangeValue.length === 0 && dateChangeValue === null) {
                fetchEmployees(
                    `${GET_EMPLOYEES_SUMMARY}?StartDate=${defaultStartDate}&EndDate=${defaultEndDate}`
                );
            }

            setIsCalendarDataRefreshNeeded(false);
        }
    }, [
        chipsChangeValue,
        dateChangeValue,
        createAbsence,
        isCalendarDataRefreshNeeded,
        dateValidationError,
    ]);

    useEffect(() => {
        const fetchHolidays = async (url) => {
            await getHolidays({
                url,
            });
        };

        if (dateChangeValue === null) {
            fetchHolidays(
                `${HOLIDAYS_BY_DATES_URL}?StartDate=${defaultStartDate}&EndDate=${defaultEndDate}`
            );
        }

        if (dateChangeValue !== null && isDateRangeValid) {
            const startDateChange = format(dateChangeValue[0], DATE_FORMAT_BACKEND);
            const endDateChange = format(dateChangeValue[1], DATE_FORMAT_BACKEND);

            fetchHolidays(
                `${HOLIDAYS_BY_DATES_URL}?StartDate=${startDateChange}&EndDate=${endDateChange}`
            );
        }
    }, [dateChangeValue]);

    useEffect(() => {
        if (errorGetEmployeesSummary !== null) {
            employeeSummary(EMPLOYEE.failedToLoad);
            onError('Failed to load employee absences data.', 'error');
        }
    }, [errorGetEmployeesSummary]);

    useEffect(() => {
        if (Array.isArray(employeesSummary)) {
            const employee = employeesSummary.find(
                (employee) => employee.employeeId === getEmployeeId()
            );
            employee
                ? employeeSummary(employee.summaryForTooltip)
                : employeeSummary(EMPLOYEE.notFound);

            const newGroups = employeesSummary.map((employee) => {
                const { employeeId, firstName, lastName } = employee;
                return {
                    id: employeeId,
                    title: `${firstName} ${lastName}`,
                    height: 40,
                };
            });

            const targetIndex = newGroups.findIndex((target) =>
                target.id.includes(getEmployeeId())
            );

            if (targetIndex !== -1) {
                const targetEmployee = newGroups.splice(targetIndex, 1)[0];
                const updatedEmployee = { ...targetEmployee, title: 'You' };
                newGroups.unshift(updatedEmployee);
            }

            setGroups(newGroups);

            const newItems = employeesSummary.reduce((accumalator, employee) => {
                const { absencesWithType, firstName, lastName, pictureUrl } = employee;
                const typeItems = absencesWithType.map((absence) => {
                    const {
                        absenceId,
                        group,
                        color,
                        absenceTypeName,
                        absenceStartDate,
                        absenceEndDate,
                    } = absence;

                    if (absenceTypeName === 'Vacation' && !vacationColor) {
                        setVacationColor(color);
                    }

                    return {
                        key: absenceId,
                        id: absenceId,
                        group: group,
                        title: absenceTypeName,
                        name: firstName + ' ' + lastName,
                        pictureUrl: pictureUrl,
                        start_time: new Date(absenceStartDate).setHours(0, 0, 0, 0),
                        end_time: addDays(new Date(absenceEndDate).setHours(0, 0, 0, 0), 1),
                        canMove: false,
                        canResize: false,
                        canChangeGroup: false,
                        itemProps: {
                            style: {
                                background: hexToRgba(color, 0.5),
                            },
                        },
                    };
                });
                return accumalator.concat(typeItems);
            }, []);

            setItems(newItems);
        }
    }, [employeesSummary]);

    const handleCancelAbsenceMenu = (event, absenceId, absenceStartDate) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        const scrollX = window.scrollX || window.pageXOffset;
        const scrollY = window.scrollY || window.pageYOffset;
        const adjustedMouseX = mouseX + scrollX;
        const adjustedMouseY = mouseY + scrollY;

        setAbsenceId(absenceId);
        setAbsenceStartDate(absenceStartDate);
        setMenuOpen((prev) => !prev);
        setMenuCoordinates({ adjustedMouseX, adjustedMouseY });
    };

    const getInitials = (fullName) => {
        if (fullName === 'You') {
            fullName = getEmployeeFullName();
        }

        return fullName
            .split(' ')
            .map((word) => word[0].toUpperCase())
            .join('');
    };

    const handleEmployeeClick = (employeeId, employeeName) => {
        setIsAbsencesSummaryModalOpen(true);
        setEmployeeId(employeeId);
        setTitle(employeeName);
    };

    const groupRenderer = ({ group }) => {
        const employee = employeesSummary.find((employee) => employee.employeeId === group.id);

        if (employee === undefined) {
            return;
        }

        return (
            <CustomTooltip
                placement="bottom-start"
                title={employeeTooltipContent(employee, vacationColor)}
            >
                <div
                    className="highlight"
                    onClick={() => handleEmployeeClick(group.id, group.title)}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <Avatar
                        variant="avatarName"
                        src={employee.pictureUrl ? employee.pictureUrl : ''}
                    >
                        {getInitials(group.title)}
                    </Avatar>
                    <Typography variant="calendarName">{group.title}</Typography>
                </div>
            </CustomTooltip>
        );
    };

    const updateCanvasDateTimeRange = (
        visibleTimeStart,
        minTime,
        visibleTimeEnd,
        maxTime,
        updateScrollCanvas
    ) => {
        if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
            updateScrollCanvas(minTime, maxTime);
        } else if (visibleTimeStart < minTime) {
            updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart));
        } else if (visibleTimeEnd > maxTime) {
            updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime);
        } else {
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
        }
    };

    const absenceContentStyle = (top) => {
        return {
            color: COLORS.BACK,
            border: 'unset',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            top: `${top}px`,
        };
    };

    const cancelAbsence = (employeeId, absenceId, absenceStartDate) => {
        if (getEmployeeId() === employeeId || isAdmin()) {
            handleCancelAbsenceMenu(event, absenceId, absenceStartDate);
        }
    };

    const absenceTooltipRenderer = (getResizeProps, getItemProps, item, itemContext) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        const itemProps = getItemProps(item.itemProps);
        const parsedTopStyle = parseFloat(itemProps.style.top);
        const topValueStyle = parsedTopStyle - 5.25;
        const startDate = format(new Date(item.start_time), DATE_FORMAT_BACKEND);
        itemProps.style = { ...itemProps.style, ...absenceContentStyle(topValueStyle) };
        itemProps.title = '';
        return (
            <CustomTooltip placement="left" title={absenceTooltipContent(item)}>
                <div onClick={() => cancelAbsence(item.group, item.id, startDate)} {...itemProps}>
                    {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
                    <div
                        className="rct-item-content"
                        style={{
                            maxHeight: `${itemContext.dimensions.height}`,
                            width: itemProps.style.width,
                            height: 'auto',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {itemContext.title}
                    </div>
                    {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
                </div>
            </CustomTooltip>
        );
    };

    const primaryHeaderUnit = (intervalContext, getIntervalProps) => {
        const scrollCanvasSize = 200;
        const width = intervalContext.interval.labelWidth;
        const left = intervalContext.interval.left;
        const absoluteLeft = Math.abs(left);
        const isNegativeLeft = intervalContext.interval.left.toString().includes('-');
        let widthResult = width - absoluteLeft;

        if (dateChangeValue) {
            const timeDifference =
                new Date(dateChangeValue[1]).getTime() - new Date(dateChangeValue[0]).getTime();
            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
            const startMonth = new Date(dateChangeValue[0]).getMonth() + 1;
            const endMonth = new Date(dateChangeValue[1]).getMonth() + 1;
            if (startMonth === endMonth && daysDifference < 31) {
                widthResult = 'inherit';
            }
            if (!isNegativeLeft && startMonth !== endMonth) {
                widthResult = calendarHeaderWidthRef.current - left;
            }
        }

        if (dateChangeValue === null) {
            calendarHeaderWidthRef.current = width;
        }

        if (
            (!isNegativeLeft && widthResult > scrollCanvasSize) ||
            (isNegativeLeft && widthResult > scrollCanvasSize) ||
            left === 0 ||
            widthResult === 'inherit'
        ) {
            return (
                <div
                    style={{
                        ...(!isNegativeLeft && { left }),
                        width: widthResult,
                    }}
                    className="date-header-interval"
                    {...getIntervalProps}
                >
                    <span>{intervalContext.intervalText}</span>
                </div>
            );
        }
    };

    const intervalDayRenderer = (intervalContext, data, getIntervalProps) => {
        const getIntervalDate = format(
            new Date(intervalContext.interval.startTime),
            DATE_FORMAT_BACKEND
        );
        const getCurrentDate = format(currentDate, DATE_FORMAT_BACKEND);

        const eventsForDay =
            data.holidays &&
            data.holidays?.filter((event) => {
                const eventStartDate = format(new Date(event.startDate), DATE_FORMAT_BACKEND);
                const eventEndDate = format(new Date(event.endDate), DATE_FORMAT_BACKEND);
                return getIntervalDate === eventStartDate && getIntervalDate === eventEndDate;
            });

        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                id="intervalPropsAttr"
                {...getIntervalProps()}
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={`interval-date${
                        getIntervalDate === getCurrentDate ? ' current-date' : ''
                    }`}
                >
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {intervalContext.intervalText}
                    </span>
                </div>
                {eventsForDay.length > 0 &&
                    eventsForDay.map((event) => (
                        <CustomTooltip
                            key={event.id}
                            title={eventTooltipContent(event)}
                            placement="bottom"
                        >
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className="upcoming-events"
                                style={{ backgroundColor: event.color }}
                            ></span>
                        </CustomTooltip>
                    ))}
            </div>
        );
    };

    let startOfMonthDiff = startDate;
    let endOfMonthDiff = endDate;
    let daysDifference = differenceInDays(endOfMonthDiff, startOfMonthDiff);

    if (daysDifference > 31) {
        endOfMonthDiff = addDays(startOfMonthDiff, 31);
    }

    const handleCloseMenu = () => {
        setIsCancelAbsenceModalOpen(true);
        setMenuCoordinates(null);
    };

    const handleCancelAbsenceModalClose = () => {
        setIsCancelAbsenceModalOpen(false);
    };

    const handleCancelSuccess = (message) => {
        cancelAbsenceMessage(message);
        setIsCalendarDataRefreshNeeded(true);
    };

    const handleOpenMenuClick = () => {
        setMenuOpen(false);
    };

    const handleAbsencesSummaryModalClose = () => {
        setIsAbsencesSummaryModalOpen(false);
    };

    const handleOutsideClick = () => {
        setMenuOpen(false);
    };

    if (
        (employeesSummary.length === 0 && Array.isArray(employeesSummary)) ||
        errorGetEmployeesSummary !== null
    ) {
        return (
            <Typography sx={{ textAlign: 'center', marginTop: '100px' }}>
                {errorGetEmployeesSummary && 'Failed to load data.'}
                {!errorGetEmployeesSummary && 'No records found.'}
            </Typography>
        );
    }

    return (
        <>
            {groups.length > 0 && !loading ? (
                <>
                    {employeesSummary.length > 0 && (
                        <Timeline
                            groups={groups}
                            key={startDate + endDate}
                            items={items}
                            defaultTimeStart={startOfMonthDiff}
                            defaultTimeEnd={endOfMonthDiff}
                            horizontalLineClassNamesForGroup={(group) =>
                                group.title ? ['highlight'] : ''
                            }
                            style={{
                                background: COLORS.WHITE,
                                borderRadius: '10px',
                                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.20)',
                            }}
                            sidebarWidth={250}
                            minZoom={2900000000}
                            maxZoom={2900000000}
                            canMove={false}
                            buffer={1}
                            canResize={false}
                            groupRenderer={groupRenderer}
                            itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
                                return absenceTooltipRenderer(
                                    getResizeProps,
                                    getItemProps,
                                    item,
                                    itemContext
                                );
                            }}
                            onTimeChange={(
                                visibleTimeStart,
                                visibleTimeEnd,
                                updateScrollCanvas
                            ) => {
                                const minTime = startDate.getTime();
                                const maxTime = endDate.getTime();

                                updateCanvasDateTimeRange(
                                    visibleTimeStart,
                                    minTime,
                                    visibleTimeEnd,
                                    maxTime,
                                    updateScrollCanvas
                                );
                            }}
                        >
                            <TimelineHeaders style={{ background: COLORS.WHITE }}>
                                <SidebarHeader>
                                    {({ getRootProps }) => {
                                        const getRootPropsStyle = getRootProps();
                                        getRootPropsStyle.style = {
                                            ...getRootPropsStyle.style,
                                            boxShadow: '1px 0 5px -2px rgba(136, 136, 136, 0.5)',
                                        };
                                        return <div {...getRootPropsStyle}></div>;
                                    }}
                                </SidebarHeader>
                                <DateHeader
                                    intervalRenderer={({ getIntervalProps, intervalContext }) => {
                                        return primaryHeaderUnit(intervalContext, getIntervalProps);
                                    }}
                                    unit="primaryHeader"
                                />
                                <DateHeader
                                    unit="day"
                                    labelFormat="DD"
                                    style={{ height: 50 }}
                                    headerData={{ holidays: holidaysByRange }}
                                    intervalRenderer={({
                                        getIntervalProps,
                                        intervalContext,
                                        data,
                                    }) => {
                                        return intervalDayRenderer(
                                            intervalContext,
                                            data,
                                            getIntervalProps
                                        );
                                    }}
                                />
                            </TimelineHeaders>
                        </Timeline>
                    )}
                </>
            ) : (
                <Box
                    sx={{
                        marginTop: '100px',
                        height: '600px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {showLoader && <CircularProgress size={50} />}
                </Box>
            )}
            {isCancelAbsenceModalOpen && (
                <AbsenceCancelModal
                    open={isCancelAbsenceModalOpen}
                    absenceId={absenceId}
                    onClose={handleCancelAbsenceModalClose}
                    onSuccess={handleCancelSuccess}
                />
            )}
            {menuCoordinates && menuOpen && (
                <AbsenceCancelMenu
                    mouseX={menuCoordinates.adjustedMouseX}
                    mouseY={menuCoordinates.adjustedMouseY}
                    startDate={absenceStartDate}
                    onClose={handleCloseMenu}
                    open={handleOpenMenuClick}
                    onOutsideClick={handleOutsideClick}
                />
            )}
            {isAbsencesSummaryModalOpen && (
                <AbsencesSummaryModal
                    open={isAbsencesSummaryModalOpen}
                    onClose={handleAbsencesSummaryModalClose}
                    employeeId={employeeId}
                    employeeName={title}
                />
            )}
        </>
    );
};

Calendar.propTypes = {
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    dateChangeValue: PropTypes.array,
    chipsChangeValue: PropTypes.array,
    createAbsence: PropTypes.any,
    dateValidationError: PropTypes.string,
    onError: PropTypes.func,
    employeeSummary: PropTypes.func,
    cancelAbsenceMessage: PropTypes.func,
};
