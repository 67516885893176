import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, InputLabel, Select, Box } from '@mui/material';

export const ColorfulSelectDropdown = ({ label, options, value, onChange }) => {
    const handleChange = (event) => {
        const newValue = event.target.value;
        onChange(newValue);
    };

    return (
        <FormControl fullWidth margin="dense">
            <InputLabel id="absence-type-label">{label}</InputLabel>
            <Select
                labelId="absence-type-label"
                id="absence-type-required"
                label={label}
                value={value}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <Box
                            style={{
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                backgroundColor: option.color,
                                marginRight: '15px',
                                display: value === option.value ? 'none' : 'block',
                            }}
                        ></Box>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

ColorfulSelectDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
