import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { COLORS } from 'constants';

export const CustomTooltip = ({ children, title, placement }) => {
    return (
        <Tooltip
            variant="custom"
            placement={placement}
            TransitionProps={{ timeout: 900 }}
            followCursor={true}
            title={title}
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: COLORS.SHADE_OF_GRAY,
                        borderRadius: 2,
                        padding: 0,
                        boxShadow: `0px 2px 6px 0px ${COLORS.GRAY}`,
                    },
                },
            }}
        >
            <Typography variant="childElement">{children}</Typography>
        </Tooltip>
    );
};
CustomTooltip.propTypes = {
    children: PropTypes.node,
    title: PropTypes.any,
    placement: PropTypes.string,
};
