import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MuiColorInput } from 'mui-color-input';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { absenceTypeModalValidationSchema } from 'utils/validationSchemas';
import { DEFAULT_ABSENCE_TYPE_URL } from 'api/absenceType';
import { HTTP_METHODS, COLORS } from 'constants';

export const AbsenceTypeModal = ({ open, onClose, onSuccess, absenceTypeId }) => {
    const topElementRef = useRef();
    const {
        data: absenceTypeData,
        error: errorGetAbsenceType,
        sendRequest: getabsenceTypeData,
        loading: loadingData,
    } = useHttp();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: createAbsenceType,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateAbsenceType,
        loading: loadingUpdate,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title] = useState(absenceTypeId ? 'Update absence type' : 'Add new absence type');
    const {
        setValue,
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(absenceTypeModalValidationSchema),
        defaultValues: {
            name: '',
            active: true,
            available: 0,
            employeeSpecific: false,
            color: COLORS.SHADE_OF_GRAY,
        },
    });

    useEffect(() => {
        if (absenceTypeId) {
            (async () => {
                await getabsenceTypeData({
                    url: `${DEFAULT_ABSENCE_TYPE_URL}/${absenceTypeId}`,
                });
            })();
        }
    }, [absenceTypeId]);

    useEffect(() => {
        if (errorGetAbsenceType !== null) {
            setResponseMessage(errorGetAbsenceType.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
    }, [errorGetAbsenceType]);

    useEffect(() => {
        reset(absenceTypeData);
    }, [absenceTypeData, reset]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            onSuccess(`Absence type ${dataCreate.name} created successfully.`, 'success');
            onClose();
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(`Absence type ${dataUpdate.name} updated successfully.`, 'success');
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    const onSubmit = async (formData) => {
        if (absenceTypeId) {
            await updateAbsenceType({
                url: `${DEFAULT_ABSENCE_TYPE_URL}/${absenceTypeId}`,
                method: HTTP_METHODS.PUT,
                data: formData,
            });
        } else {
            await createAbsenceType({
                url: `${DEFAULT_ABSENCE_TYPE_URL}`,
                method: HTTP_METHODS.POST,
                data: formData,
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        label="Name"
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        fullWidth
                                        required
                                        error={!!errors.name}
                                        helperText={errors.name ? errors.name.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="color"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <MuiColorInput
                                        label="Color"
                                        format="hex"
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        fullWidth
                                        required
                                        error={!!errors.color}
                                        helperText={errors.color ? errors.color.message : ''}
                                    />
                                )}
                            />
                            <Grid container marginTop={'16px'}>
                                <FormControl>
                                    <FormLabel>Number of days</FormLabel>
                                    <Grid item xs={12} marginLeft={'16px'}>
                                        <Controller
                                            control={control}
                                            name="employeeSpecific"
                                            render={({ field: { value, onChange } }) => (
                                                <RadioGroup
                                                    value={value}
                                                    onChange={() => {
                                                        onChange(!value);
                                                        if (!value) {
                                                            setValue('available', 0);
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label="Custom"
                                                        labelPlacement="end"
                                                    />
                                                    {!value && (
                                                        <Controller
                                                            control={control}
                                                            name="available"
                                                            render={({
                                                                field: { onChange, onBlur, value },
                                                            }) => (
                                                                <TextField
                                                                    label="Number of days"
                                                                    name="available"
                                                                    type="number"
                                                                    margin="dense"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.available}
                                                                    helperText={
                                                                        errors.available
                                                                            ? errors.available
                                                                                  .message
                                                                            : ''
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label="Employee specific"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingData, loadingCreate, loadingUpdate)}
                            >
                                {absenceTypeId ? 'Update' : 'Add'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

AbsenceTypeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    absenceTypeId: PropTypes.string,
};
