import { INVENTORY_ITEM_STATE } from 'constants';
import * as yup from 'yup';

const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
const JMBGRegExp = /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])[0-9]{9}$/;

const phoneNumber = yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(9, "Phone number is too short");
const jmbgRequired = yup.string()
    .required("Field is required")
    .matches(JMBGRegExp, 'JMBG is not valid')
    .min(13, "JMBG is too short")
    .max(13, 'JMBG is too long');
const stringRequired = yup.string().required('Field is required');
const positiveNumberRequired = yup
    .number()
    .typeError('Must be a non-negative number')
    .min(0, 'Must be a non-negative number')
    .required('Field is required');
const dateRequired = yup.date().typeError('Invalid date').required('Field is required');
const numberRequired = yup.number().typeError('Must be a number').required('Field is required');

export const employeeModalValidationSchema = yup.object().shape({
    firstName: stringRequired,
    lastName: stringRequired,
    email: stringRequired.email('Invalid email address'),
    hireDate: dateRequired,
    available: positiveNumberRequired,
    usedVacationCurrentYear: positiveNumberRequired,
    usedVacationPreviousYear: positiveNumberRequired,
});

export const deleteEmployeeModalValidationSchema = yup.object().shape({
    terminationDate: dateRequired,
});

export const teamModalValidationSchema = yup.object().shape({
    name: stringRequired,
});

export const absenceTypeModalValidationSchema = yup.object().shape({
    name: stringRequired,
    available: positiveNumberRequired,
    color: stringRequired,
});

export const holidayModalValidationSchema = yup.object().shape({
    name: stringRequired,
    startDate: dateRequired,
    endDate: dateRequired,
    type: stringRequired,
});

export const timeSheetModalValidationSchema = yup.object().shape({
    year: stringRequired,
    month: positiveNumberRequired,
    totalWorkingHours: positiveNumberRequired,
    pxRegisteredHours: positiveNumberRequired,
    flexHours: numberRequired,
    approvedOvertime: positiveNumberRequired,
});

export const inventoryTypeModalValidationSchema = yup.object().shape({
    name: stringRequired,
});

export const inventoryItemModalValidationSchema = (isSerialNumberRequired) =>
    yup.object().shape({
        name: stringRequired,
        typeId: stringRequired,
        dateOfPurchase: dateRequired,
        inventoryNumber: stringRequired,
        serialNumber: isSerialNumberRequired ? stringRequired : yup.string(),
    });

export const assignInventoryItemModalValidationSchema = yup.lazy((values) => {
    const isEmployeeIdProvided = !!values.employeeId;
    const isTeamIdProvided = !!values.teamId;

    return yup
        .object()
        .shape({
            teamId: isEmployeeIdProvided ? stringRequired.nullable() : stringRequired,
            employeeId: isTeamIdProvided ? stringRequired.nullable() : stringRequired,
            startDate: dateRequired,
            endDate: yup.date().nullable().typeError('Invalid date'),
        })
        .test({
            name: 'teamIdOrEmployeeIdRequired',
            message: 'Field is required',
            test: ({ teamId, employeeId }) => teamId || employeeId,
        });
});

export const unassignInventoryItemModalValidationSchema = yup.object().shape({
    endDate: dateRequired,
});

export const employeeInformationModalValidationSchema = yup.object().shape({
    softhousePhoneNumber: phoneNumber.required('Field is required'),
    privatePhoneNumber: phoneNumber.required('Field is required'),
    cipsAddress: stringRequired,
    residenceAddress: stringRequired,
    familyMembers: positiveNumberRequired,
    firstEmergencyContactName: stringRequired,
    firstEmergencyContactRelation: stringRequired,
    firstEmergencyContactPhoneNumber: phoneNumber.required('Field is required'),
    secondEmergencyContactName: yup.string(),
    secondEmergencyContactRelation: yup.string(),
    secondEmergencyContactPhoneNumber: phoneNumber,
    dateOfBirth: dateRequired,
    jmbg: jmbgRequired,
    passportNumber: stringRequired,
    passportIssueDate: dateRequired,
    passportExpirationDate: dateRequired,
    passportIssuedIn: stringRequired,
    allergies: stringRequired,
});

export const employeeInventoryTypeModalValidationSchema = yup.object().shape({
    state: stringRequired,
    description: yup.string().when('state', {
        is: INVENTORY_ITEM_STATE.bad,
        then: () => yup.string().required('Field is required')
    }).when('state', {
        is: INVENTORY_ITEM_STATE.critical,
        then: () => yup.string().required('Field is required')
    }),
});