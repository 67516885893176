import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const AttendanceDetailsModal = ({ open, onClose, selectedCellData }) => {
    const { year, monthData, absenceTypeDuration } = selectedCellData || {};

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle
                style={{
                    textTransform: 'none',
                    paddingRight: '80px',
                    paddingBottom: '0px',
                }}
            >
                {selectedCellData && (
                    <p>
                        {year &&
                            `${new Date(year, monthData.month - 1).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'long',
                            })} (Attendance rate: ${parseFloat(monthData.attendanceRate).toFixed(
                                0
                            )}%)`}
                    </p>
                )}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent style={{ margin: 0, padding: 0 }}>
                <DialogContentText>
                    {absenceTypeDuration && (
                        <div>
                            <div
                                style={{
                                    borderBottom: '1px solid #ccc',
                                    width: '100%',
                                    margin: '10px 0',
                                }}
                            ></div>
                            <table style={{ width: '100%', paddingBottom: '20px' }}>
                                <tbody>
                                    {absenceTypeDuration.map((leave) => (
                                        <tr key={leave.absenceType}>
                                            <td
                                                style={{
                                                    paddingRight: '24px',
                                                    paddingLeft: '24px',
                                                    width: '20px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '15px',
                                                        height: '15px',
                                                        borderRadius: '50%',
                                                        backgroundColor: leave.absenceTypeColor,
                                                    }}
                                                ></div>
                                            </td>
                                            <td>{leave.absenceType}</td>
                                            <td
                                                style={{ textAlign: 'right', paddingRight: '24px' }}
                                            >
                                                {leave.days}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

AttendanceDetailsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedCellData: PropTypes.shape({
        year: PropTypes.number.isRequired,
        monthData: PropTypes.shape({
            month: PropTypes.number.isRequired,
            attendanceRate: PropTypes.number.isRequired,
        }).isRequired,
        absenceTypeDuration: PropTypes.arrayOf(
            PropTypes.shape({
                absenceType: PropTypes.string.isRequired,
                absenceTypeColor: PropTypes.string.isRequired,
                days: PropTypes.number.isRequired,
            })
        ).isRequired,
    }),
};
