import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import RemoveIcon from '@mui/icons-material/Remove';
import PropTypes from "prop-types";
import { COLORS } from "constants";
import useHttp from "hooks/useHttp";
import { DEFAULT_TIME_SHEET_URL } from "api/timeSheet";
import { getEmployeeId } from "utils/auth";
import { AlertSnackBar } from "components/AlertSnackBar";

const Span = styled('span')(({color, fontSize}) => ({
    fontSize: fontSize,
    color: color,
}));

const CardBox = styled(Box)(({width}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '55px',
    gap: '0px',
    width: width
}));

export const EmployeeSummaryCard = ({employeeSummary}) => {
    const [snackbars, setSnackbars] = useState([]);
    const [employeeTime, setEmployeeTime] = useState({});
    const {
        data: totalFlexAndApprovedOvertimeHoursData,
        error: errorGetTotalFlexAndApprovedOvertimeHoursData,
        sendRequest: getTotalFlexAndApprovedOvertimeHours,
        loading: loading,
    } = useHttp();

    const currentYear = new Date().getFullYear();
    const employeeId = getEmployeeId();

    useEffect(() => {
        const handleErrors = (error, errorMessage) => {
            if (error !== null) {
                showSnackbar(errorMessage, 'error');
            }
        };

        handleErrors(
            errorGetTotalFlexAndApprovedOvertimeHoursData,
            'Failed to load total flex and approved overtime hours.');
    }, [errorGetTotalFlexAndApprovedOvertimeHoursData]);

    useEffect(() => {
        (async () => {
            await getTotalFlexAndApprovedOvertimeHours({
                url: `${DEFAULT_TIME_SHEET_URL}/total-flex-overtime-hours/employee${
                    employeeId ? `/${employeeId}` : ''
                }?year=${currentYear}`,
            });
        })();
    }, []);

    useEffect(() => {
        if (totalFlexAndApprovedOvertimeHoursData.length > 0) {
            const employee = totalFlexAndApprovedOvertimeHoursData[0];
            setEmployeeTime(employee);
        }
    }, [totalFlexAndApprovedOvertimeHoursData]);

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: Math.random(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const calculateFlexHours = (hours) => {
        if (hours > 20 || hours < -20) {
            return COLORS.RED;
        }
        return COLORS.BLUE;
    }

    const hasErrorOrIsEmpty = Object.keys(totalFlexAndApprovedOvertimeHoursData).length === 0
        && Array.isArray(totalFlexAndApprovedOvertimeHoursData)
        || errorGetTotalFlexAndApprovedOvertimeHoursData !== null;

    return (
        <>
            <Card variant="employeeSummaryCard">
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <CardBox width={"100px"}>
                        {employeeSummary ? (
                            <>
                                {employeeSummary.vacationLeft === null && <RemoveIcon/>}
                                <Span color={COLORS.GREEN} fontSize="25px">
                                    {employeeSummary.vacationLeft}
                                </Span>
                            </>
                        ) : <CircularProgress size={27} />}
                        <Typography>
                            Vacation left
                        </Typography>
                    </CardBox>
                    <Divider sx={{marginX: '10px'}} orientation="vertical" flexItem />
                    <CardBox width={"90px"}>
                        {employeeSummary ? (
                            <>
                                {employeeSummary.vacationPreviousYearUsed === null ? <RemoveIcon/> : (
                                <Span color={COLORS.BLUE} fontSize="25px">
                                    {employeeSummary.vacationPreviousYearUsed}/
                                    {employeeSummary.vacationPreviousYearAvailable}
                                </Span>
                                )}
                            </>
                        ) : <CircularProgress size={27} />}
                        <Typography>
                            From {new Date().getFullYear() - 1}
                        </Typography>
                    </CardBox>
                    <Divider sx={{marginX: '10px'}} orientation="vertical" flexItem />
                    <CardBox width={"80px"}>
                        {employeeSummary ? (
                            <>
                                {employeeSummary.vacationCurrentYearUsed === null ? <RemoveIcon /> : (
                                    <Span color={COLORS.BLUE} fontSize="25px">
                                        {employeeSummary.vacationCurrentYearUsed}/
                                        {employeeSummary.vacationCurrentYearAvailable}
                                    </Span>
                                )}
                            </>
                        ) : <CircularProgress size={27} />}
                        <Typography>
                            New
                        </Typography>
                    </CardBox>
                    <Divider sx={{marginX: '10px'}} orientation="vertical" flexItem />
                    <CardBox width={"100px"}>
                        {hasErrorOrIsEmpty ? (
                            <RemoveIcon />
                        ) : Object.keys(employeeTime).length > 0 && !loading ? (
                            totalFlexAndApprovedOvertimeHoursData.length > 0 && (
                                <Span color={calculateFlexHours(employeeTime.totalFlexHours)} fontSize="25px">
                                    {employeeTime.totalFlexHours}
                                </Span>)
                        ) : <CircularProgress size={27} />}
                        <Typography>
                            Flex hours
                        </Typography>
                    </CardBox>
                    <Divider sx={{marginX: '10px'}} orientation="vertical" flexItem />
                    <CardBox width={"120px"}>
                        {hasErrorOrIsEmpty ? (
                            <RemoveIcon />
                        ) : Object.keys(employeeTime).length > 0 && !loading ? (
                            totalFlexAndApprovedOvertimeHoursData.length > 0 && (
                                <Span color={COLORS.BLUE} fontSize="25px">
                                    {employeeTime.totalApprovedOvertimeHours}
                                </Span>)
                        ) : <CircularProgress size={27} />}
                        <Typography>
                            Overtime hours
                        </Typography>
                    </CardBox>
                </Box>
            </Card>
            {snackbars.map((snackbar) => (
                <AlertSnackBar
                    key={snackbar.key}
                    message={snackbar.message}
                    severity={snackbar.severity}
                    onClose={() => closeSnackbar(snackbar.key)}
                />
            ))}
        </>
    );
};

EmployeeSummaryCard.propTypes = {
    employeeSummary: PropTypes.object,
}