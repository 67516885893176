import React from 'react';
import { Button } from '@mui/material';
import { theme } from 'utils/theme';
import { replaceNullValues } from 'utils/misc';

export const renderTimeSheetCells = (params, viewOnly) => {
    const value = params.value;
    return (
        <div
            style={{
                cursor: 'pointer',
            }}
        >
            {value !== undefined ? (
                <div>
                    <span
                        style={{
                            color:
                                value < 0
                                    ? theme.palette.error.main
                                    : value > 0
                                    ? theme.palette.warning.main
                                    : '',
                        }}
                    >
                        {value}
                    </span>
                </div>
            ) : !viewOnly ? (
                <Button
                    variant="outlined"
                    style={{
                        maxWidth: '20px',
                        maxHeight: '20px',
                        minWidth: '20px',
                        minHeight: '20px',
                    }}
                >
                    +
                </Button>
            ) : (
                replaceNullValues(value)
            )}
        </div>
    );
};

export const renderTotalFlexHoursCells = (params) => {
    const value = params.value;

    return (
        <div>
            {value !== undefined ? (
                <span
                    style={{
                        color: value < -20 || value > 20 ? theme.palette.error.main : '',
                    }}
                >
                    {value}
                </span>
            ) : null}
        </div>
    );
};

export const monthsTimeSheet = Array.from({ length: 12 }, (_, index) => index + 1);

export const generateTimeSheetColumns = (viewOnly) => {
    return [
        { field: 'employeeId', headerName: 'Employee ID' },
        { field: 'employeeName', headerName: 'Employee', flex: 0.3, minWidth: 150 },
        { field: 'year', headerName: 'Year' },
        ...monthsTimeSheet.map((month) => ({
            field: `${month}`,
            headerName: `${month}`,
            flex: 0.3,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => renderTimeSheetCells(params, viewOnly),
        })),
        {
            field: 'totalFlexHours',
            headerName: 'Total Flex Hours',
            flex: 0.3,
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: renderTotalFlexHoursCells,
        },
        {
            field: 'totalApprovedOvertimeHours',
            headerName: 'Total Approved Overtime Hours',
            flex: 0.3,
            minWidth: 250,
            headerAlign: 'center',
            align: 'center',
        },
    ];
};
