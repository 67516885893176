import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "contexts/auth-context";
import { hasPermissions } from "utils/auth";
import PropTypes from "prop-types";

export const RouteGuard = ({component, roles}) => {
    const { isLoggedIn } = useContext(AuthContext);
    const permissions = hasPermissions();

    if (!Array.isArray(permissions)) {
        return false;
    }

    if (!isLoggedIn) {
        return <Navigate to="/login" replace />;
    }

    const hasPermission = roles.some(role => permissions.includes(role));

    if (!hasPermission) {
        return <Navigate to="/" replace />;
    }

    return component;
};

RouteGuard.propTypes = {
    component: PropTypes.object,
    roles: PropTypes.array,
}