import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@mui/material';
import { theme } from 'utils/theme';
import App from 'App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={googleClientId}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>
);
