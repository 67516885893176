import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Image = ({ src, sx, alt = '' }) => {
    return <Box sx={sx} component="img" src={src} alt={alt} />;
};

Image.propTypes = {
    src: PropTypes.string,
    sx: PropTypes.object,
    alt: PropTypes.string,
};

export default Image;
