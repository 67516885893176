import React from 'react';
import PropTypes from 'prop-types';
import { addDays, format } from 'date-fns';
import { Box, Typography } from '@mui/material';
import StartIcon from '@mui/icons-material/Start';
import Divider from '@mui/material/Divider';
import { DATE_FORMAT } from 'constants';

export const employeeTooltipContent = (employee, vacationColor) => {
    return (
        <div>
            <Typography variant="BigWrapperHeader">
                <Box
                    sx={{
                        textAlign: 'center',
                        paddingTop: '3px',
                    }}
                >
                    <Typography variant="grayText">Vacation left</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:
                                employee.summaryForTooltip.vacationFutureDays > 0
                                    ? 'right'
                                    : 'center',
                        }}
                    >
                        <Typography sx={{ fontSize: '18px' }} variant="boldText">
                            {employee.summaryForTooltip.vacationLeft}
                        </Typography>
                        {employee.summaryForTooltip.vacationFutureDays > 0 && (
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    paddingLeft: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                color={vacationColor}
                            >
                                {`+${employee.summaryForTooltip.vacationFutureDays}`}
                                <StartIcon
                                    sx={{
                                        paddingLeft: '3px',
                                    }}
                                />
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}
                >
                    <Box textAlign="center">
                        <Typography
                            sx={{ marginTop: '1px', marginBottom: '2px' }}
                            variant="grayText"
                        >
                            From {new Date().getFullYear() - 1}
                        </Typography>
                        <BoxInfo
                            used={employee.summaryForTooltip.vacationPreviousYearUsed}
                            available={employee.summaryForTooltip.vacationPreviousYearAvailable}
                            variantCondition={
                                employee.summaryForTooltip.vacationPreviousYearUsed ===
                                employee.summaryForTooltip.vacationPreviousYearAvailable
                            }
                        />
                    </Box>
                    <Box textAlign="center">
                        <Typography variant="blueBackground">New</Typography>
                        <BoxInfo
                            used={employee.summaryForTooltip.vacationCurrentYearUsed}
                            available={employee.summaryForTooltip.vacationCurrentYearAvailable}
                            variantCondition={
                                employee.summaryForTooltip.vacationCurrentYearUsed ===
                                employee.summaryForTooltip.vacationCurrentYearAvailable
                            }
                        />
                    </Box>
                </Box>
            </Typography>
            <Typography variant="BigWrapper">
                <Box
                    sx={{
                        textAlign: 'center',
                        paddingTop: '3px',
                    }}
                >
                    <Typography variant="grayText">Paid religious days</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <BoxInfo
                            used={employee.summaryForTooltip.paidReligiousHolidayUsed}
                            available={employee.summaryForTooltip.paidReligiousHolidayAvailable}
                            variantCondition={
                                employee.summaryForTooltip.paidReligiousHolidayUsed ===
                                employee.summaryForTooltip.paidReligiousHolidayAvailable
                            }
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        textAlign: 'center',
                        paddingTop: '3px',
                    }}
                >
                    <Typography variant="grayText">Unpaid religious days</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <BoxInfo
                            used={employee.summaryForTooltip.unpaidReligiousHolidayUsed}
                            available={employee.summaryForTooltip.unpaidReligiousHolidayAvailable}
                            variantCondition={
                                employee.summaryForTooltip.unpaidReligiousHolidayUsed ===
                                employee.summaryForTooltip.unpaidReligiousHolidayAvailable
                            }
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        textAlign: 'center',
                        paddingTop: '3px',
                    }}
                >
                    <Typography variant="grayText">
                        Parental/Marriage/ Death in family leave
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <BoxInfo
                            used={employee.summaryForTooltip.parentalMarriageDeathInFamilyUsed}
                            available={
                                employee.summaryForTooltip.parentalMarriageDeathInFamilyAvailable
                            }
                            variantCondition={
                                employee.summaryForTooltip.parentalMarriageDeathInFamilyUsed ===
                                employee.summaryForTooltip.parentalMarriageDeathInFamilyAvailable
                            }
                        />
                    </Box>
                </Box>
            </Typography>
        </div>
    );
};

export const absenceTooltipContent = (item) => {
    return (
        <Typography variant="AbsenceWrapper">
            <Box>
                <Typography variant="nameText">{item.name}</Typography>
            </Box>
            <Box>
                <Typography sx={{ marginTop: '4px' }} variant="grayText">
                    Start Date
                </Typography>
                <Typography variant="boldText">
                    {format(new Date(item.start_time), DATE_FORMAT)}
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ marginTop: '4px' }} variant="grayText">
                    End Date
                </Typography>
                <Typography variant="boldText">
                    {format(addDays(new Date(item.end_time), -1), DATE_FORMAT)}
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ marginTop: '4px' }} variant="grayText">
                    Type
                </Typography>
                <Typography variant="boldText">{item.title}</Typography>
            </Box>
        </Typography>
    );
};

export const eventTooltipContent = (event) => {
    return (
        <Typography variant="SmallWrapper">
            <Typography variant="boldText">{event.name}</Typography>
        </Typography>
    );
};

const BoxInfo = ({ used, available, variantCondition }) => (
    <Box textAlign="center">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant={variantCondition ? 'grayInfoBody' : 'infoBody'}>{used}</Typography>
            <Typography variant="grayInfoBody">/{available}</Typography>
        </Box>
    </Box>
);

BoxInfo.propTypes = {
    used: PropTypes.number,
    available: PropTypes.number,
    variantCondition: PropTypes.bool,
};
