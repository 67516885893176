import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { DEFAULT_INVENTORY_ITEM_URL } from 'api/inventoryItem';
import { HTTP_METHODS } from 'constants';

export const InventoryAssignmentFormGenerateModal = ({
    open,
    onClose,
    onSuccess,
    selectedIds,
    type,
}) => {
    const topElementRef = useRef();
    const {
        data,
        error,
        clearData,
        sendRequest: generateInventoryAssignmentForm,
        loading,
    } = useHttp();
    const title = `Generate ${type} form`;
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [override, setOverride] = useState(false);

    useEffect(() => {
        clearData();

        if (error !== null) {
            setResponseMessage(error.response?.data?.message || 'An error occurred');
            setMessageType('error');
            if (
                error.response?.data?.message &&
                error.response?.data?.message.includes('already exists')
            ) {
                setOverride(true);
            }
        }

        if (data) {
            onSuccess(data, 'success');
            setMessageType('success');

            setResponseMessage(data);
            setOverride(false);
        }
    }, [error, data, messageType]);

    const handleGenerateConfirmation = async () => {
        const requestBody = selectedIds;
        await generateInventoryAssignmentForm({
            url: `${DEFAULT_INVENTORY_ITEM_URL}/generate-inventory-${type}-form?overrideIfExists=${override}`,
            method: HTTP_METHODS.POST,
            data: requestBody,
        });
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>
                            {messageType === 'success' ? (
                                <>
                                    <Typography>Successfully generated {type} form.</Typography>
                                    <Link
                                        href={responseMessage}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View
                                    </Link>
                                </>
                            ) : (
                                responseMessage
                            )}
                        </MessageBox>
                    )}
                    {messageType !== 'success' && (
                        <Grid item>
                            {!override ? (
                                <Typography>
                                    Are you sure you want to generate inventory {type} form for
                                    selected inventory items?
                                </Typography>
                            ) : null}
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                                {override ? (
                                    <Button
                                        onClick={handleGenerateConfirmation}
                                        variant="contained"
                                        color="error"
                                        disabled={loading}
                                    >
                                        OVERRIDE
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={handleGenerateConfirmation}
                                        variant="contained"
                                        color="error"
                                        disabled={loading}
                                    >
                                        Generate
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

InventoryAssignmentFormGenerateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    selectedIds: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};
