import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import 'assets/css/customMUIPaper.css';

export const SelectBar = ({
    onSelect,
    options,
    displayOptions,
    isDisabled,
    placeholder = 'Search',
}) => {
    const [inputValue, setInputValue] = useState('');

    const handleSelect = (value) => {
        onSelect(value);
        setInputValue('');
    };

    return (
        <Autocomplete
            classes={{ paper: 'custom-paper-style' }}
            sx={{
                width: 230,
                borderRadius: '30px',
                marginRight: '30px'
            }}
            options={options}
            groupBy={(option) => option.type}
            getOptionLabel={displayOptions}
            value={null}
            inputValue={inputValue}
            onChange={(event, value) => handleSelect(value)}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            noOptionsText="There are no matching options"
            disableClearable
            renderInput={renderInput}
        />
    );

    function renderInput(params) {
        return (
            <TextField
                className="subvariant-search"
                size="small"
                {...params}
                placeholder={placeholder}
                disabled={isDisabled}
                InputProps={getInputProps(params)}
            />
        );
    }

    function getInputProps(params) {
        return {
            ...params.InputProps,
            endAdornment: (
                <InputAdornment position="end">
                    <SearchIcon style={{ color: 'black' }} />
                </InputAdornment>
            ),
            style: { paddingRight: '16px' },
        };
    }
};

SelectBar.propTypes = {
    onSelect: PropTypes.func,
    options: PropTypes.array,
    displayOptions: PropTypes.func,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
};
