import React, { useEffect, useState } from 'react';
import { Box, Button, styled, } from '@mui/material';
import useHttp from 'hooks/useHttp';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { DEFAULT_INVENTORY_TYPE_URL } from 'api/inventoryType';
import {
    inventoryTypesColumns, renderDeleteReactivateButton, renderEditButton
} from 'utils/dataColumns/inventoryTypeColumns';
import PropTypes from 'prop-types';
import { InventoryTypeModal } from 'components/InventoryTypeModal';
import { DeleteInventoryTypeModal } from 'components/DeleteInventoryTypeModal';
import { ReactivateInventoryTypeModal } from 'components/ReactivateInventoryTypeModal';
import { CommonDataGrid } from 'components/CommonDataGrid';

const InventoryType = ({ filter, showInactive, renderSearchTextField }) => {
    const [inventoryTypeId, setInventoryTypeId] = useState('');
    const [snackbars, setSnackbars] = useState([]);
    const [isInventoryTypeModalOpen, setIsInventoryTypeModalOpen] = useState(false);
    const [isDeleteInventoryTypeModalOpen, setIsDeleteInventoryTypeModalOpen] = useState(false);
    const [isReactivateInventoryTypeModalOpen, setIsReactivateInventoryTypeModalOpen] = useState(false);

    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);
    const {
        data: allInventoryTypesData,
        error: errorGetInventoryTypes,
        sendRequest: getInventoryTypes,
    } = useHttp();

    const StyledCells = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'space-between',
    }));

    useEffect(() => {
        if (isGridDataRefreshNeeded) {
            getInventoryTypes({
                url: `${DEFAULT_INVENTORY_TYPE_URL}?includeInactive=true`,
            });
            setIsGridDataRefreshNeeded(false);
        }
    }, [isGridDataRefreshNeeded]);

    useEffect(() => {
        const handleErrors = (error, errorMessage) => {
            if (error !== null) {
                showSnackbar(errorMessage, 'error');
            }
        };

        handleErrors(
            errorGetInventoryTypes,
            'Failed to load inventory types.'
        );
    }, [
        errorGetInventoryTypes,
    ]);

    const columnsForInventoryType = inventoryTypesColumns.map((column) => {
        if (column.field === 'edit') {
            return {
                ...column,
                renderCell: (params) =>
                    renderEditButton(params, setInventoryTypeId, setIsInventoryTypeModalOpen),
            };
        } else if (column.field === 'delete-reactivate') {
            return {
                ...column,
                renderCell: (params) =>
                    renderDeleteReactivateButton(
                        params,
                        setInventoryTypeId,
                        setIsDeleteInventoryTypeModalOpen,
                        setIsReactivateInventoryTypeModalOpen
                    ),
            };
        }
        return column;
    })

    const inventoryTypesDataTable = [
        ...(allInventoryTypesData || []).map((type) => ({
            id: type.id,
            name: type.name,
            active: `${type.active}`,
            isSerialNumberRequired: type.isSerialNumberRequired ? 'Required' : 'Not required'
        })
        ),
    ];

    const filteredInventoryTypesData = inventoryTypesDataTable.filter((type) => {
        return (showInactive ? type.active === 'false' : type.active === 'true') &&
            type.name.toLowerCase().includes(filter.toLowerCase())
    });

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleAddNewInventoyType = () => {
        setInventoryTypeId('');
        setIsInventoryTypeModalOpen(true);
    };

    const handleCreateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleDeleteSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleReactivateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        if (message) {
            showSnackbar(message, severity);
        }
    };

    const handleInventoryTypeModalClose = () => {
        setIsInventoryTypeModalOpen(false);
    };

    const handleDeleteInventoryTypeModalClose = () => {
        setIsDeleteInventoryTypeModalOpen(false);
    };

    const handleReactivateInventoryTypeModalClose = () => {
        setIsReactivateInventoryTypeModalOpen(false);
    };

    const renderContent = () => {
        return (
            <>
                <div>
                    <StyledCells>
                        <h1>Inventory types</h1>
                        <Button
                            onClick={handleAddNewInventoyType}
                            variant="contained"
                        >
                            + Add new inventory type
                        </Button>
                    </StyledCells>
                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                        {renderSearchTextField('Show inactive types')}
                        <Box display="flex-start" width="100%">
                            <CommonDataGrid
                                rows={filteredInventoryTypesData}
                                columns={columnsForInventoryType.filter((column) => column.field !== 'id' && column.field !== 'active')}
                                useCustomToolbar={false}
                            />
                        </Box>
                    </div>
                </div>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return (
        <>
            {renderContent()}
            {isInventoryTypeModalOpen && (
                <InventoryTypeModal
                    open={isInventoryTypeModalOpen}
                    onClose={handleInventoryTypeModalClose}
                    onSuccess={handleCreateSuccess}
                    typeId={inventoryTypeId}
                />
            )}
            {isDeleteInventoryTypeModalOpen && (
                <DeleteInventoryTypeModal
                    open={isDeleteInventoryTypeModalOpen}
                    onClose={handleDeleteInventoryTypeModalClose}
                    onSuccess={handleDeleteSuccess}
                    typeId={inventoryTypeId}
                />
            )}
            {isReactivateInventoryTypeModalOpen && (
                <ReactivateInventoryTypeModal
                    open={isReactivateInventoryTypeModalOpen}
                    onClose={handleReactivateInventoryTypeModalClose}
                    onSuccess={handleReactivateSuccess}
                    typeId={inventoryTypeId}
                />
            )}
        </>
    );
};

export default InventoryType;

InventoryType.propTypes = {
    filter: PropTypes.string.isRequired,
    showInactive: PropTypes.bool.isRequired,
    renderSearchTextField: PropTypes.func.isRequired,
};
