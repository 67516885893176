import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isValid } from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Grid } from '@mui/material';
import { enGB } from 'date-fns/locale';
import { DATE_FORMAT } from 'constants';
import { theme } from 'utils/theme';

const DATE_PICKER_TEXT_FIELD_STYLE = {
    size: 'small',
    style: { background: theme.palette.whiteColor.main },
};

export const DateRangePicker = ({
    selectedDateRange,
    onChange,
    shouldDisableStartDate = () => false,
    shouldDisableEndDate = () => false,
}) => {
    const [localSelectedDates, setLocalSelectedDates] = useState([null, null]);

    useEffect(() => {
        if (selectedDateRange) {
            setLocalSelectedDates(selectedDateRange);
        }
    }, [selectedDateRange]);

    const handleDateChange = (date, index) => {
        let validationError = null;
        const newSelectedDates = [...localSelectedDates];

        if (isValid(date)) {
            newSelectedDates[index] = date;

            const isStartDateDisabled =
                index === 0 ? shouldDisableStartDate(newSelectedDates[0]) : false;
            const isEndDateDisabled =
                index === 1 ? shouldDisableEndDate(newSelectedDates[1]) : false;

            if (isStartDateDisabled || isEndDateDisabled) {
                validationError = 'Invalid date range';
            } else {
                setLocalSelectedDates(newSelectedDates);
                validationError = null;
            }
        } else {
            validationError = 'Invalid Date';
        }
        onChange(newSelectedDates, validationError);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <Grid container spacing={1} sx={{ marginTop: '-8px' }} maxWidth={400}>
                <Grid item xs={5}>
                    <DatePicker
                        label="Start Date"
                        format={DATE_FORMAT}
                        slotProps={{
                            textField: DATE_PICKER_TEXT_FIELD_STYLE,
                        }}
                        value={localSelectedDates[0]}
                        onChange={(date) => handleDateChange(date, 0)}
                        shouldDisableDate={shouldDisableStartDate}
                    />
                </Grid>
                <Grid item xs={5}>
                    <DatePicker
                        label="End Date"
                        format={DATE_FORMAT}
                        slotProps={{
                            textField: DATE_PICKER_TEXT_FIELD_STYLE,
                        }}
                        value={localSelectedDates[1]}
                        onChange={(date) => handleDateChange(date, 1)}
                        shouldDisableDate={shouldDisableEndDate}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

DateRangePicker.propTypes = {
    selectedDateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    onChange: PropTypes.func.isRequired,
    shouldDisableStartDate: PropTypes.func,
    shouldDisableEndDate: PropTypes.func,
};
