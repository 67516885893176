import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { DEFAULT_ABSENCE_TYPE_URL } from 'api/absenceType';
import { HTTP_METHODS } from 'constants';

const TITLE = 'Deactivate absence type';

export const DeleteAbsenceTypeModal = ({ open, onClose, onSuccess, absenceTypeId }) => {
    const topElementRef = useRef();
    const {
        data: dataDelete,
        error: errorDelete,
        clearData: clearDataDelete,
        sendRequest: deleteAbsenceType,
        loading: loadingDelete,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        clearDataDelete();

        if (errorDelete !== null) {
            setResponseMessage(errorDelete.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataDelete) {
            onSuccess(`Absence type deactivated successfully.`, 'success');
            onClose();
        }
    }, [errorDelete, dataDelete, messageType]);

    const handleDeleteConfirmation = async () => {
        if (absenceTypeId) {
            await deleteAbsenceType({
                url: `${DEFAULT_ABSENCE_TYPE_URL}/${absenceTypeId}`,
                method: HTTP_METHODS.DELETE,
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    {messageType !== 'success' && (
                        <Grid item xs={5}>
                            <Typography>
                                Are you sure you want to deactivate an absence type?
                            </Typography>
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                    disabled={loadingDelete}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleDeleteConfirmation}
                                    variant="contained"
                                    color="error"
                                    disabled={loadingDelete}
                                >
                                    Deactivate
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

DeleteAbsenceTypeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    absenceTypeId: PropTypes.string.isRequired,
};
