import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { SelectBar } from 'components/SelectBar';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { CommonDataGrid } from 'components/CommonDataGrid';
import useHttp from 'hooks/useHttp';
import { isLoading } from 'utils/misc';
import { teamEmployeesColumns, renderRemoveButton } from 'utils/dataColumns/teamEmployeesColumns';
import { DEFAULT_EMPLOYEE_TEAM_URL } from 'api/employeeTeam';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { HTTP_METHODS } from 'constants';

const StyledOutput = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
}));

export const TeamEmployeesModal = ({ open, onClose, teamId }) => {
    const {
        data: employeesData,
        error: errorEmployeesGet,
        sendRequest: getEmployees,
        loading: loadingEmployees,
    } = useHttp();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: addToTeam,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataDelete,
        error: errorDelete,
        clearData: clearDataDelete,
        sendRequest: removeFromTeam,
        loading: loadingDelete,
    } = useHttp();
    const {
        data: dataTeamEmployees,
        error: errorTeamEmployeesGet,
        sendRequest: getEmployeeTeams,
        loading: loadingTeamEmployees,
    } = useHttp();
    const [messageType, setMessageType] = useState();
    const [responseMessage, setResponseMessage] = useState(
        'When you select an employee, they will be automatically included in the team. To remove an employee from the team, click on the X.'
    );
    const [refreshData, setRefreshData] = useState(false);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        (async () => {
            await getEmployees({
                url: DEFAULT_EMPLOYEE_URL,
            });
        })();
    }, []);

    useEffect(() => {
        if (teamId || refreshData) {
            (async () => {
                await getEmployeeTeams({
                    url: `${DEFAULT_EMPLOYEE_TEAM_URL}/team/${teamId}`,
                });
                setRefreshData(false);
            })();
        }
    }, [teamId, refreshData]);

    useEffect(() => {
        if (errorEmployeesGet !== null || errorTeamEmployeesGet != null) {
            setResponseMessage(
                errorEmployeesGet.response?.data?.message ||
                errorTeamEmployeesGet.response?.data?.message ||
                'An error occurred'
            );
            setMessageType('error');
        }
    }, [errorEmployeesGet, errorTeamEmployeesGet]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
        if (dataCreate) {
            setRefreshData(true);
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataDelete();

        if (errorDelete !== null) {
            setResponseMessage(errorDelete.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
        if (dataDelete) {
            setRefreshData(true);
        }
    }, [errorDelete, dataDelete, messageType]);

    useEffect(() => {
        if (dataTeamEmployees) {
            const updatedRows = dataTeamEmployees.map((employee) => ({
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
            }));
            setRows(updatedRows);
        }
    }, [dataTeamEmployees]);

    const handleSelect = async (selectedOption) => {
        setMessageType('information');
        if (
            selectedOption &&
            !dataTeamEmployees.some((employee) => employee.id === selectedOption.id)
        ) {
            await addEmployeeToTeam(selectedOption.id);
        }
    };

    const removeEmployeeFromTeam = async (employeeId) => {
        await removeFromTeam({
            url: `${DEFAULT_EMPLOYEE_TEAM_URL}/employee/${employeeId}/team/${teamId}`,
            method: HTTP_METHODS.DELETE,
        });
    };

    const addEmployeeToTeam = async (employeeId) => {
        const requestBody = {
            employeeId: employeeId,
            teamId: teamId,
        };

        await addToTeam({
            url: DEFAULT_EMPLOYEE_TEAM_URL,
            method: HTTP_METHODS.POST,
            data: requestBody,
        });
    };

    const displayOptions = (option) =>
        `${option.name} ${dataTeamEmployees.some((employee) => employee.id === option.id) ? ' ✔' : ''
        }`;

    const options = [
        ...(employeesData?.data || []).map((employee) => ({
            id: `${employee.id}`,
            name: `${employee.firstName} ${employee.lastName}`,
        })),
    ];

    const columnsForTeamEmployees = teamEmployeesColumns.map((column) => {
        if (column.field === 'remove') {
            return {
                ...column,
                renderCell: (params) =>
                    renderRemoveButton(
                        params,
                        removeEmployeeFromTeam,
                        loadingEmployees,
                        loadingTeamEmployees,
                        loadingCreate,
                        loadingDelete
                    ),
            };
        }
        return column;
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                Employees
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent
                style={{
                    minHeight: '280px',
                    overflowY: 'auto',
                }}
            >
                <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                {dataTeamEmployees.length === 0 && (
                    <MessageBox messageType="warning">
                        The team does not have any members.
                    </MessageBox>
                )}
                <StyledOutput>
                    <SelectBar
                        onSelect={handleSelect}
                        options={options}
                        displayOptions={displayOptions}
                        isDisabled={isLoading(
                            loadingEmployees,
                            loadingTeamEmployees,
                            loadingCreate,
                            loadingDelete
                        )}
                        placeholder="Choose a member"
                    />
                    {dataTeamEmployees.length > 0 && (
                        <div style={{ height: '100%', width: '100%', paddingTop: '24px' }}>
                            <CommonDataGrid
                                rows={rows}
                                columns={columnsForTeamEmployees.filter(
                                    (column) => column.field !== 'id'
                                )}
                                useCustomToolbar={false}
                            />
                        </div>
                    )}
                </StyledOutput>
            </DialogContent>
        </Dialog>
    );
};

TeamEmployeesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    teamId: PropTypes.string,
};
