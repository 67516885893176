import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { YearCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';

import { Box, Button, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { HTTP_METHODS } from 'constants';
import { CREATE_ABSENCE_QUOTAS_URL } from 'api/absenceQuota';

const TITLE = 'Create vacation absence quotas';

export const CreateAbsenceQuotasModal = ({ open, onClose }) => {
    const topElementRef = useRef();
    const { data, error, clearData, sendRequest: createAbsenceQuotas, loading } = useHttp();
    const [messageType, setMessageType] = useState();
    const [responseMessage, setResponseMessage] = useState(
        'After selecting a year and clicking the "CREATE" button, vacation quotas will be generated for all currently active employees who do not already have an absence quota for the selected year.'
    );
    const currentDate = new Date();
    const minDate = new Date(currentDate.getFullYear(), 0, 1);
    const maxDate = new Date(currentDate.getFullYear() + 1, 0, 1);
    const [formData, setFormData] = useState({
        Year: maxDate,
    });

    useEffect(() => {
        clearData();

        if (error !== null) {
            setResponseMessage(error.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (data) {
            setResponseMessage(data);
            setMessageType('success');
        }
    }, [error, data, messageType, onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const date = new Date(formData.Year);
        const year = date.getFullYear();
        await createAbsenceQuotas({
            url: `${CREATE_ABSENCE_QUOTAS_URL}/?year=${year}`,
            method: HTTP_METHODS.POST,
        });
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    {messageType !== 'success' && (
                        <Grid item xs={12}>
                            <form onSubmit={handleSubmit}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={enGB}
                                >
                                    <YearCalendar
                                        value={formData.Year}
                                        onChange={(newYear) => setFormData({ Year: newYear })}
                                        yearsPerRow={3}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                </LocalizationProvider>
                                <Box mt={2} display="flex" justifyContent="flex-end">
                                    <Button type="submit" variant="contained" disabled={loading}>
                                        CREATE
                                    </Button>
                                </Box>
                            </form>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

CreateAbsenceQuotasModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
