import React, { useEffect, useState } from 'react';
import {
    styled,
    Button,
    Box,
    Grid,
    TextField,
    InputAdornment,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useHttp from 'hooks/useHttp';
import { DEFAULT_ABSENCE_TYPE_URL } from 'api/absenceType';
import { AbsenceTypeModal } from 'components/AbsenceTypeModal';
import { DeleteAbsenceTypeModal } from 'components/DeleteAbsenceTypeModal';
import { ReactivateAbsenceTypeModal } from 'components/ReactivateAbsenceTypeModal';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { CommonDataGrid } from 'components/CommonDataGrid';
import {
    absenceTypeColumns,
    renderEditButton,
    renderDeleteReactivateButton,
} from 'utils/dataColumns/absenceTypeColumns';

const StyledCells = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const AbsenceTypes = () => {
    const {
        data: absenceTypesData,
        error: errorGetAbsenceTypes,
        sendRequest: getAbsenceTypes,
    } = useHttp();
    const [isAbsenceTypeModalOpen, setIsAbsenceTypeModalOpen] = useState(false);
    const [isDeleteAbsenceTypeModalOpen, setIsDeleteAbsenceTypeModalOpen] = useState(false);
    const [isReactivateAbsenceTypeModalOpen, setIsReactivateAbsenceTypeModalOpen] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [filter, setFilter] = useState('');
    const [absenceTypeId, setAbsenceTypeId] = useState('');
    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);
    const [snackbars, setSnackbars] = useState([]);

    useEffect(() => {
        if (isGridDataRefreshNeeded) {
            getAbsenceTypes({
                url: `${DEFAULT_ABSENCE_TYPE_URL}/?includeInactive=true`,
            });
            setIsGridDataRefreshNeeded(false);
        }
    }, [isGridDataRefreshNeeded]);

    useEffect(() => {
        if (errorGetAbsenceTypes !== null) {
            showSnackbar('Failed to load absence types.', 'error');
        }
    }, [errorGetAbsenceTypes]);

    const absenceTypeTableData = [
        ...(absenceTypesData || []).map((absenceType) => ({
            id: absenceType.id,
            name: absenceType.name,
            active: `${absenceType.active}`,
        })),
    ];

    const filteredData = absenceTypeTableData.filter((absenceType) => {
        return (showInactive ? absenceType.active === 'false' : absenceType.active === 'true') &&
            absenceType.name.toLowerCase().includes(filter.toLowerCase())
    });

    const columnsForAbsenceType = absenceTypeColumns.map((column) => {
        if (column.field === 'edit') {
            return {
                ...column,
                renderCell: (params) =>
                    renderEditButton(params, setAbsenceTypeId, setIsAbsenceTypeModalOpen),
            };
        } else if (column.field === 'delete-reactivate') {
            return {
                ...column,
                renderCell: (params) =>
                    renderDeleteReactivateButton(
                        params,
                        setAbsenceTypeId,
                        setIsDeleteAbsenceTypeModalOpen,
                        setIsReactivateAbsenceTypeModalOpen
                    ),
            };
        }
        return column;
    });

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleAddNewAbsenceTypeClick = () => {
        setAbsenceTypeId('');
        setIsAbsenceTypeModalOpen(true);
    };

    const handleShowInactiveChange = (event) => {
        setShowInactive(event.target.checked);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleAbsenceTypeModalClose = () => {
        setIsAbsenceTypeModalOpen(false);
    };

    const handleDeleteAbsenceTypeModalClose = () => {
        setIsDeleteAbsenceTypeModalOpen(false);
    };
    const handleReactivateAbsenceTypeModalClose = () => {
        setIsReactivateAbsenceTypeModalOpen(false);
    };

    const handleCreateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleDeleteSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleReactivateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        if (message) {
            showSnackbar(message, severity);
        }
    };

    const renderContent = () => {
        return (
            <>
                <Grid item xs={5} marginBottom="16px">
                    <StyledCells>
                        <h1>Absence types</h1>
                        <Button onClick={handleAddNewAbsenceTypeClick} variant="contained">
                            + Add new absence type
                        </Button>
                    </StyledCells>
                </Grid>
                <Grid item xs={5} marginBottom="16px">
                    <TextField
                        placeholder="Search"
                        className="subvariant-search"
                        size="small"
                        value={filter}
                        onChange={handleFilterChange}
                        focused
                        style={{ marginRight: '16px' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {filter ? (
                                        <IconButton
                                            onClick={() => setFilter('')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    ) : (
                                        <SearchIcon style={{ color: 'black' }} />
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={showInactive} onChange={handleShowInactiveChange} />
                        }
                        label="Show inactive absence types"
                    />
                </Grid>
                {isAbsenceTypeModalOpen && (
                    <AbsenceTypeModal
                        open={isAbsenceTypeModalOpen}
                        onClose={handleAbsenceTypeModalClose}
                        onSuccess={handleCreateSuccess}
                        absenceTypeId={absenceTypeId}
                    />
                )}
                {isDeleteAbsenceTypeModalOpen && (
                    <DeleteAbsenceTypeModal
                        open={isDeleteAbsenceTypeModalOpen}
                        onClose={handleDeleteAbsenceTypeModalClose}
                        onSuccess={handleDeleteSuccess}
                        absenceTypeId={absenceTypeId}
                    />
                )}
                {isReactivateAbsenceTypeModalOpen && (
                    <ReactivateAbsenceTypeModal
                        open={isReactivateAbsenceTypeModalOpen}
                        onClose={handleReactivateAbsenceTypeModalClose}
                        onSuccess={handleReactivateSuccess}
                        absenceTypeId={absenceTypeId}
                    />
                )}
                <div style={{ height: '100%', width: '100%' }}>
                    <CommonDataGrid
                        rows={filteredData}
                        columns={columnsForAbsenceType.filter(
                            (column) => column.field !== 'id' && column.field !== 'active'
                        )}
                    />
                </div>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {renderContent()}
        </Box>
    );
};

export default AbsenceTypes;
