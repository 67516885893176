import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { Box, Button, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { deleteEmployeeModalValidationSchema } from 'utils/validationSchemas';
import { DATE_FORMAT, DATE_FORMAT_BACKEND, HTTP_METHODS } from 'constants';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';

const TITLE = 'Deactivate employee';

export const DeleteEmployeeModal = ({ open, onClose, onSuccess, employeeId }) => {
    const topElementRef = useRef();
    const {
        data: dataDelete,
        error: errorDelete,
        clearData: clearDataDelete,
        sendRequest: deleteEmployee,
        loading: loadingDelete,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(deleteEmployeeModalValidationSchema),
        defaultValues: {
            terminationDate: null,
        },
    });

    useEffect(() => {
        clearDataDelete();

        if (errorDelete !== null) {
            setResponseMessage(errorDelete.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataDelete) {
            onSuccess(`Employee deactivated successfully.`, 'success');
            onClose();
        }
    }, [errorDelete, dataDelete, messageType]);

    const onSubmit = async (formData) => {
        await deleteEmployee({
            url: `${DEFAULT_EMPLOYEE_URL}/${employeeId}?terminationDate=${format(
                formData.terminationDate,
                DATE_FORMAT_BACKEND
            )}`,
            method: HTTP_METHODS.DELETE,
        });
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    {messageType !== 'success' && (
                        <Grid item xs={5} marginBottom="16px">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={enGB}
                                >
                                    <Controller
                                        control={control}
                                        name="terminationDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <DatePicker
                                                label="Termination Date"
                                                value={value}
                                                format={DATE_FORMAT}
                                                onChange={onChange}
                                                slotProps={{
                                                    textField: {
                                                        onBlur: onBlur,
                                                        required: true,
                                                        margin: 'dense',
                                                        fullWidth: true,
                                                        error: !!errors.terminationDate,
                                                        helperText: errors.terminationDate
                                                            ? errors.terminationDate.message
                                                            : '',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <Box mt={2} display="flex" justifyContent="flex-end">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={loadingDelete}
                                    >
                                        Deactivate
                                    </Button>
                                </Box>
                            </form>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

DeleteEmployeeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    employeeId: PropTypes.string.isRequired,
};
