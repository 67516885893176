import React from 'react';
import { Link } from '@mui/material';
import { isWrittenOff } from '../misc';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';
import { PowerSettingsNew } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { theme } from '../theme';
import Edit from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';

export const renderEditButton = (params, setInventoryItemId, setIsInventoryItemModalOpen) => {
    const handleEditClick = () => {
        setInventoryItemId(params.row.id);
        setIsInventoryItemModalOpen(true);
    };
    if (!isWrittenOff(params.row)) {
        return (
            <IconButtonWithTooltip
                onClick={handleEditClick}
                icon={<Edit />}
                tooltipText="Edit"
                color={theme.palette.primary.main}
            />
        );
    } else {
        return null;
    }
};

export const renderDeleteReactivateButton = (
    params,
    setInventoryItemId,
    setIsDeleteInventoryItemModalOpen,
    setIsReactivateInventoryItemModalOpen
) => {
    const handleDeleteClick = () => {
        setInventoryItemId(params.row.id);
        setIsDeleteInventoryItemModalOpen(true);
    };
    const handleReactivateClick = () => {
        setInventoryItemId(params.row.id);
        setIsReactivateInventoryItemModalOpen(true);
    };
    return (
        <div>
            {!isWrittenOff(params.row) ? (
                <IconButtonWithTooltip
                    onClick={handleDeleteClick}
                    icon={<PowerSettingsNew />}
                    tooltipText="Write off"
                    color={theme.palette.redColor.main}
                />
            ) : (
                <IconButtonWithTooltip
                    onClick={handleReactivateClick}
                    icon={<SyncIcon />}
                    tooltipText="Reactivate"
                    color={theme.palette.primary.main}
                />
            )}
        </div>
    );
};

export const renderActiveCell = (params, { type }) => {
    const filledLink =
        type === 'assignment'
            ? params.row.filledAssignmentFormLink
            : params.row.filledUnassignmentFormLink;
    const signedLink =
        type === 'assignment'
            ? params.row.signedAssignmentFormLink
            : params.row.signedUnassignmentFormLink;

    return (
        <div>
            {filledLink && (
                <Link href={filledLink} target="_blank" rel="noopener noreferrer">
                    <IconButtonWithTooltip
                        icon={<OpenInNewIcon />}
                        tooltipText={`Open filled ${type}`}
                        color={theme.palette.primary.main}
                    />
                </Link>
            )}
            {signedLink && (
                <Link href={signedLink} target="_blank" rel="noopener noreferrer">
                    <IconButtonWithTooltip
                        icon={<OpenInNewIcon />}
                        tooltipText={`Open signed ${type}`}
                        color={theme.palette.secondary.main}
                    />
                </Link>
            )}
        </div>
    );
};

export const inventoryItemsColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'name',
        headerName: 'Name',
        flex: 0.05,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.05,
    },
    {
        field: 'state',
        headerName: 'State',
        flex: 0.05,
    },
    {
        field: 'serialNumber',
        headerName: 'Serial number',
        flex: 0.05,
    },
    {
        field: 'inventoryNumber',
        headerName: 'Inventory number',
        flex: 0.05,
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 0.05,
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 0.05,
    },
    {
        field: 'dateOfPurchase',
        headerName: 'Date of purchase',
        flex: 0.05,
    },
    {
        field: 'specifications',
        headerName: 'Specifications',
        flex: 0.05,
    },
    {
        field: 'assignee',
        headerName: 'Assignee',
        flex: 0.05,
    },
    {
        field: 'assignment',
        headerName: 'Assignment',
        flex: 0.03,
        minWidth: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => renderActiveCell(params, { type: 'assignment' }),
    },
    {
        field: 'unassignment',
        headerName: 'Unassignment',
        flex: 0.03,
        minWidth: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => renderActiveCell(params, { type: 'unassignment' }),
    },
    {
        field: 'edit',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 0.5,
        renderCell: renderEditButton,
    },
    {
        field: 'delete-reactivate',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 0.5,
        renderCell: renderDeleteReactivateButton,
    },
];
