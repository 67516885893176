import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isAuthenticated, removeAuthToken, setAuthToken, setRefreshToken } from 'utils/auth';
import useHttp from 'hooks/useHttp';
import { LOGIN_URL } from 'api/auth';

const AuthContext = React.createContext({
    isLoggedIn: false,
    loginMessage: '',
    isLoading: false,
    onLogin: () => {},
    onLogout: () => {},
});

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated);
    const [loginMessage, setLoginMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { data, clearData, error, sendRequest: loginRequest } = useHttp();

    useEffect(() => {
        if (error !== null) {
            setIsLoading(false);
            setLoginMessage(
                error.response?.data?.message || 'An error occurred, please contact your manager.'
            );
        }

        if (
            data &&
            data.accessToken !== undefined &&
            data.refreshToken !== undefined &&
            error === null
        ) {
            setAuthToken(data.accessToken);
            setRefreshToken(data.refreshToken);
            setIsLoading(false);
            setIsLoggedIn(true);
            setLoginMessage('');
        }
    }, [data, error]);

    const loginHandler = (googleLoggedUser) => {
        const jwtToken = googleLoggedUser.credential;

        const headers = {
            Accept: 'text/plain',
            Authorization: `Bearer ${jwtToken}`,
        };

        setIsLoading(true);
        setLoginMessage('');
        loginRequest({
            url: LOGIN_URL,
            headers: headers,
        });
    };

    const logoutHandler = () => {
        setIsLoggedIn(false);
        removeAuthToken();
        clearData();
    };

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                loginMessage,
                isLoading,
                onLogin: loginHandler,
                onLogout: logoutHandler,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContext;
