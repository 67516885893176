import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
    Box,
    TextField,
    InputAdornment,
    Tabs,
    Tab,
    Grid,
    Button,
    styled,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useHttp from 'hooks/useHttp';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { AbsenceQuotaCardDetailed } from 'components/AbsenceQuotaCardDetailed';
import { AbsencesQuotaCardUsed } from 'components/AbsencesQuotaCardUsed';
import { ResendNotificationModal } from 'components/ResendNotificationModal';
import { CreateAbsenceQuotasModal } from 'components/CreateAbsenceQuotasModal';
import { CreateAbsenceReportModal } from 'components/CreateAbsenceReportModal';
import { AttendanceDetailsModal } from 'components/AttendanceDetailsModal';
import { TimeSheet } from 'components/TimeSheet';
import { AnnualLeaveDecision } from 'components/AnnualLeaveDecision';
import { GET_REPORTS_URL, GET_ATTENDANCE_RATES_URL } from 'api/report';
import { GET_ABSENCES_URL } from 'api/absence';
import { GET_EMPLOYEE_ABSENCES } from 'api/employeDashboard';
import { absenceColumns } from 'utils/dataColumns/absenceColumns';
import {
    reportColumns,
    renderResendNotificationButton,
    renderActiveCell,
} from 'utils/dataColumns/reportColumns';
import { DATE_WITH_TIME_FORMAT } from 'constants';
import {
    DATE_FORMAT,
    DATE_FORMAT_BACKEND,
    DETAILED_ABSENCE_TYPE_NAMES,
    FIRST_YEAR,
} from 'constants';

const GeneralAdministration = () => {
    const [reportId, setReportId] = useState('');
    const [filter, setFilter] = useState('');
    const [snackbars, setSnackbars] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [showInactive, setShowInactive] = useState(false);
    const [selectedCellData, setSelectedCellData] = useState(null);
    const [isResendNotificationModalOpen, setIsResendNotificationModalOpen] = useState(false);
    const [isCreateAbsenceQuotasModalOpen, setIsCreateAbsenceQuotasModalOpen] = useState(false);
    const [isCreateAbsenceReportModalOpen, setIsCreateAbsenceReportModalOpen] = useState(false);
    const [isAttendanceDetailsModalOpen, setIsAttendanceDetailsModalOpen] = useState(false);
    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);
    const { data: reportsData, error: errorGetReports, sendRequest: getReports } = useHttp();
    const {
        data: allAbsencesData,
        error: errorGetAllAbsences,
        sendRequest: getAllAbsences,
    } = useHttp();
    const {
        data: absencesAndQuotasCurrentYear,
        error: errorGetAbsencesAndQuotasCurrentYear,
        sendRequest: getAbsencesAndQuotasCurrentYear,
    } = useHttp();
    const {
        data: allAttendanceData,
        error: errorGetAttendanceData,
        sendRequest: getAllAttendances,
    } = useHttp();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const endYear = currentDate.getMonth() >= 9 ? currentYear + 1 : currentYear;
    const firstDateInTheCurrentYear = format(new Date(currentYear, 0, 1), DATE_FORMAT_BACKEND);
    const lastDateInTheNextYear = format(new Date(currentYear + 1, 11, 31), DATE_FORMAT_BACKEND);
    const StyledCells = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'space-between',
    }));

    useEffect(() => {
        const fetchData = async () => {
            switch (selectedTab) {
                case 0:
                    if (isGridDataRefreshNeeded || selectedTab == 0) {
                        await getReports({
                            url: `${GET_REPORTS_URL}`,
                        });
                        setIsGridDataRefreshNeeded(false);
                    }
                    break;

                case 2:
                    await getAllAttendances({
                        url: `${GET_ATTENDANCE_RATES_URL}?startYear=${FIRST_YEAR}&endYear=${endYear}`,
                    });
                    break;

                case 3:
                    await getAbsencesAndQuotasCurrentYear({
                        url: `${GET_EMPLOYEE_ABSENCES}?StartDate=${firstDateInTheCurrentYear}&EndDate=${lastDateInTheNextYear}`,
                    });
                    break;

                case 4:
                    await getAllAbsences({
                        url: `${GET_ABSENCES_URL}`,
                    });
                    break;
                default:
                    break;
            }
        };

        fetchData();
    }, [selectedTab, isGridDataRefreshNeeded]);

    useEffect(() => {
        const handleErrors = (error, errorMessage) => {
            if (error !== null) {
                showSnackbar(errorMessage, 'error');
            }
        };

        handleErrors(errorGetReports, 'Failed to load reports data.');
        handleErrors(errorGetAttendanceData, 'Failed to load attendance data.');
        handleErrors(
            errorGetAllAbsences || errorGetAbsencesAndQuotasCurrentYear,
            'Failed to load absences.'
        );
    }, [
        errorGetReports,
        errorGetAllAbsences,
        errorGetAbsencesAndQuotasCurrentYear,
        errorGetAttendanceData,
    ]);

    useEffect(() => {
        setFilter('');
        setShowInactive(false);
    }, [selectedTab]);

    const reportsTableData = [
        ...(reportsData || []).map((report) => ({
            id: report.id,
            year: report.year,
            month: report.month,
            version: report.version,
            link: report.link,
            generationDate: format(
                report.generationDate ? new Date(report.generationDate) : null,
                DATE_WITH_TIME_FORMAT
            ),
            generatedBy: report.generatedBy,
        })),
    ];

    const filteredReportsData = reportsTableData.filter((report) => {
        return `${report.year.toString()}${report.month.toString()}`.includes(
            filter.toString().replace(/\s/g, '')
        );
    });

    const columnsForReport = reportColumns.map((column) => {
        if (column.field === 'resendNotification') {
            return {
                ...column,
                renderCell: (params) =>
                    renderResendNotificationButton(
                        params,
                        setReportId,
                        setIsResendNotificationModalOpen
                    ),
            };
        } else if (column.field === 'link') {
            return {
                ...column,
                renderCell: renderActiveCell,
            };
        }
        return column;
    });

    const allAbsencesTableData = [
        ...(allAbsencesData || []).map((absence) => ({
            id: absence.id,
            absenceType: absence.absenceTypeName,
            startDate: format(
                absence.startDate ? new Date(absence.startDate).setHours(0, 0, 0, 0) : null,
                DATE_FORMAT
            ),
            endDate: format(
                absence.endDate ? new Date(absence.endDate).setHours(0, 0, 0, 0) : null,
                DATE_FORMAT
            ),
            notes: absence.reason,
            status: absence.status,
            employee: absence.employeeName,
        })),
    ];

    const filteredAllAbsencesData = allAbsencesTableData.filter((absence) => {
        return (
            absence.absenceType.toLowerCase().includes(filter.toLowerCase()) ||
            absence.status.toLowerCase().includes(filter.toLowerCase()) ||
            absence.employee.toLowerCase().includes(filter.toLowerCase())
        );
    });

    const columnsForAbsence = [
        ...absenceColumns.slice(0, -1),
        {
            field: 'employee',
            headerName: 'Employee',
            flex: 0.3,
            minWidth: 50,
        },
        ...absenceColumns.slice(-1),
    ];

    const absencesAndQuotasCurrentYearDataTable = [
        ...(absencesAndQuotasCurrentYear || []).flatMap((employee) =>
            employee.absenceTypes.flatMap((absenceType) =>
                absenceType.absencesSharedModels.map((absence) => ({
                    id: absence.absenceId,
                    absenceType: absenceType.name,
                    startDate: new Date(absence.startDate),
                    endDate: new Date(absence.endDate),
                    notes: absence.reason,
                    status: absence.status,
                    employee: `${employee.firstName} ${employee.lastName}`,
                }))
            )
        ),
    ];

    absencesAndQuotasCurrentYearDataTable.sort((a, b) => b.startDate - a.startDate);
    absencesAndQuotasCurrentYearDataTable.forEach((item) => {
        item.startDate = format(item.startDate, DATE_FORMAT);
        item.endDate = format(item.endDate, DATE_FORMAT);
    });

    const filteredCurrentYearAbsencesData = absencesAndQuotasCurrentYearDataTable.filter(
        (absence) => {
            return (
                absence.absenceType.toLowerCase().includes(filter.toLowerCase()) ||
                absence.status.toLowerCase().includes(filter.toLowerCase()) ||
                absence.employee.toLowerCase().includes(filter.toLowerCase())
            );
        }
    );

    const months = Array.isArray(allAttendanceData)
        ? [
              ...new Set(
                  allAttendanceData.flatMap((entry) =>
                      entry.attendanceDetails.map((detail) => detail.month)
                  )
              ),
          ]
        : [];

    const renderCellAsPointer = (params) => {
        return (
            <div
                style={{
                    cursor: 'pointer',
                }}
            >
                {params.value}
            </div>
        );
    };

    const monthsColumns = [
        { field: 'year', headerName: 'Year', flex: 1 },
        ...months.map((month) => ({
            field: `month_${month}`,
            headerName: month.toString(),
            flex: 1,
            renderCell: renderCellAsPointer,
        })),
    ];

    const allAttendanceTableData = Array.isArray(allAttendanceData)
        ? allAttendanceData.map((entry) => {
              const row = { id: entry.year, year: entry.year };
              entry.attendanceDetails.forEach((detail) => {
                  row[`month_${detail.month}`] = `${parseFloat(detail.attendanceRate).toFixed(0)}%`;
              });
              return row;
          })
        : [];

    const filteredAttendances = allAttendanceTableData.filter((attendance) => {
        return `${attendance.year}`.includes(filter);
    });

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleAddNewReportClick = () => {
        setReportId('');
        setIsCreateAbsenceReportModalOpen(true);
    };

    const handleCreateAbsenceQuotasClick = () => {
        setIsCreateAbsenceQuotasModalOpen(true);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleResendNotificationModalClose = () => {
        setIsResendNotificationModalOpen(false);
    };

    const handleCreateAbsenceQuotasModalClose = () => {
        setIsCreateAbsenceQuotasModalOpen(false);
    };

    const handleCreateAbsenceReportModalClose = () => {
        setIsCreateAbsenceReportModalOpen(false);
    };

    const handleAttendanceDetailsModalClose = () => {
        setIsAttendanceDetailsModalOpen(false);
    };

    const handleResendNotificationSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleCreateAbsenceReportModalSuccess = () => {
        setIsGridDataRefreshNeeded(true);
    };

    const handleCellClick = (params) => {
        const { row, field } = params;
        if (row) {
            const year = row.year;
            const monthMatch = field.match(/^month_(\d+)$/);
            const month = monthMatch ? parseInt(monthMatch[1], 10) : undefined;
            if (year !== undefined && month !== undefined) {
                const selectedData = allAttendanceData.find((entry) => entry.year === year);
                if (selectedData) {
                    const monthData = selectedData.attendanceDetails.find(
                        (detail) => detail.month === month
                    );
                    if (monthData) {
                        const absenceTypeDuration = monthData.absenceTypeDuration || [];
                        setSelectedCellData({
                            year,
                            monthData,
                            absenceTypeDuration,
                        });
                        setIsAttendanceDetailsModalOpen(true);
                    }
                }
            }
        }
    };

    const handleShowInactiveChange = (event) => {
        setShowInactive(event.target.checked);
    };

    const absenceQuotasData = DETAILED_ABSENCE_TYPE_NAMES.flatMap((absenceTypeName) => {
        const totalQuota = Array.isArray(absencesAndQuotasCurrentYear)
            ? absencesAndQuotasCurrentYear.reduce((accumulator, employee) => {
                  const employeeAbsenceType = employee.absenceTypes.find(
                      (absenceType) => absenceType.name === absenceTypeName
                  );
                  if (employeeAbsenceType) {
                      employeeAbsenceType.absenceQuotaForDashboardModel.forEach((quota) => {
                          const updatedName =
                              absenceTypeName === 'Vacation'
                                  ? `${absenceTypeName} ${new Date(quota.startDate).getFullYear()}`
                                  : absenceTypeName;
                          const existingQuota = accumulator.find(
                              (item) => item.title === updatedName
                          );
                          if (existingQuota) {
                              existingQuota.available += quota.available;
                              existingQuota.used += quota.used;
                              existingQuota.left += quota.left;
                              existingQuota.color = employeeAbsenceType.color;
                          } else {
                              accumulator.push({
                                  title: updatedName,
                                  available: quota.available,
                                  used: quota.used,
                                  left: quota.left,
                                  color: employeeAbsenceType.color,
                              });
                          }
                      });
                  }
                  return accumulator;
              }, [])
            : [];
        return totalQuota.map((quota) => (
            <AbsenceQuotaCardDetailed
                key={quota.title}
                title={quota.title}
                available={quota.available}
                used={quota.used}
                left={quota.left}
                style={{ flex: '1 0 25%' }}
                color={quota.color}
            />
        ));
    });

    const absenceQuotaCardsDetailed = <>{absenceQuotasData}</>;

    const keyValueData = {};

    if (Array.isArray(absencesAndQuotasCurrentYear) && absencesAndQuotasCurrentYear.length > 0) {
        absencesAndQuotasCurrentYear.forEach((employee) => {
            if (employee && Array.isArray(employee.absenceTypes)) {
                employee.absenceTypes.forEach((absenceType) => {
                    if (absenceType && !DETAILED_ABSENCE_TYPE_NAMES.includes(absenceType.name)) {
                        if (Array.isArray(absenceType.absenceQuotaForDashboardModel)) {
                            const usedDays = absenceType.absenceQuotaForDashboardModel.reduce(
                                (acc, quota) => acc + quota.used,
                                0
                            );
                            keyValueData[absenceType.name] =
                                (keyValueData[absenceType.name] || 0) + usedDays;
                        }
                    }
                });
            }
        });
    }

    const absenceQuotaCardUsed = (
        <AbsencesQuotaCardUsed title="Other leaves (used days)" data={keyValueData} />
    );

    const renderSearchTextField = (label) => (
        <Grid item xs={6} marginTop="16px">
            <TextField
                placeholder="Search"
                className="subvariant-search"
                size="small"
                value={filter}
                onChange={handleFilterChange}
                focused
                style={{ marginRight: '16px', marginBottom: '16px' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {filter ? (
                                <IconButton
                                    onClick={() => setFilter('')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ) : (
                                <SearchIcon style={{ color: 'black' }} />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {label && (
                <FormControlLabel
                    control={
                        <Checkbox checked={showInactive} onChange={handleShowInactiveChange} />
                    }
                    label={label}
                />
            )}
        </Grid>
    );

    const renderContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={1} style={{ height: '100vh' }}>
                    <Box height="100%" display="flex" alignItems="top" justifyContent="right">
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            orientation="vertical"
                            variant="scrollable"
                        >
                            <Tab label="Monthly absences reports" />
                            <Tab label="Yearly vacation quotas" />
                            <Tab label="Attendance rates overview" />
                            <Tab label="Current period absences overview" />
                            <Tab label="All absences overview" />
                            <Tab label="Time sheets" />
                            <Tab label="Annual leave decisions" />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={11} paddingRight={'24px'}>
                    <Box>
                        {selectedTab === 0 && (
                            <div>
                                <StyledCells>
                                    <h1>Absences reports</h1>
                                    <Button onClick={handleAddNewReportClick} variant="contained">
                                        + Add new report
                                    </Button>
                                </StyledCells>
                                <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                                    {renderSearchTextField()}
                                    <Box display="flex-start" width="100%">
                                        <CommonDataGrid
                                            rows={filteredReportsData}
                                            columns={columnsForReport}
                                        />
                                    </Box>
                                </div>
                            </div>
                        )}
                        {selectedTab === 1 && (
                            <div>
                                <Button
                                    onClick={handleCreateAbsenceQuotasClick}
                                    variant="contained"
                                >
                                    + Add new vacation quotas
                                </Button>
                            </div>
                        )}
                        {selectedTab === 2 && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                                {renderSearchTextField()}
                                <Box display="flex-start" width="100%">
                                    <CommonDataGrid
                                        rows={filteredAttendances}
                                        columns={monthsColumns}
                                        onCellClick={handleCellClick}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                            </div>
                        )}
                        {selectedTab === 3 && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                                <>
                                    {absenceQuotaCardsDetailed}
                                    {absenceQuotaCardUsed}
                                </>
                                <Box display="flex-start" width="100%">
                                    {renderSearchTextField()}
                                </Box>
                                <Box display="flex-start" width="100%">
                                    <CommonDataGrid
                                        rows={filteredCurrentYearAbsencesData}
                                        columns={columnsForAbsence}
                                    />
                                </Box>
                            </div>
                        )}
                        {selectedTab === 4 && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                                {renderSearchTextField()}
                                <Box display="flex-start" width="100%">
                                    <CommonDataGrid
                                        rows={filteredAllAbsencesData}
                                        columns={columnsForAbsence}
                                    />
                                </Box>
                            </div>
                        )}
                        {selectedTab === 5 && (
                            <TimeSheet
                                renderSearchTextField={renderSearchTextField}
                                filter={filter}
                            />
                        )}
                        {selectedTab === 6 && (
                            <AnnualLeaveDecision
                                renderSearchTextField={renderSearchTextField}
                                filter={filter}
                                showInactive={showInactive}
                            />
                        )}
                    </Box>
                </Grid>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </Grid>
        );
    };

    return (
        <Box display="flex-center" width="100%" paddingTop="20px">
            {renderContent()}
            {isResendNotificationModalOpen && (
                <ResendNotificationModal
                    open={isResendNotificationModalOpen}
                    onClose={handleResendNotificationModalClose}
                    onSuccess={handleResendNotificationSuccess}
                    reportId={reportId}
                />
            )}
            {isCreateAbsenceReportModalOpen && (
                <CreateAbsenceReportModal
                    open={isCreateAbsenceReportModalOpen}
                    onClose={handleCreateAbsenceReportModalClose}
                    onSuccess={handleCreateAbsenceReportModalSuccess}
                />
            )}
            {isCreateAbsenceQuotasModalOpen && (
                <CreateAbsenceQuotasModal
                    open={isCreateAbsenceQuotasModalOpen}
                    onClose={handleCreateAbsenceQuotasModalClose}
                />
            )}
            {isAttendanceDetailsModalOpen && (
                <AttendanceDetailsModal
                    open={isAttendanceDetailsModalOpen}
                    onClose={handleAttendanceDetailsModalClose}
                    selectedCellData={selectedCellData}
                />
            )}
        </Box>
    );
};

export default GeneralAdministration;
