import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { isLoading } from 'utils/misc';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { DATE_FORMAT } from 'constants';
import { unassignInventoryItemModalValidationSchema } from 'utils/validationSchemas';
import { format } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DATE_FORMAT_BACKEND } from 'constants';
import { UNASSIGN_ASSIGNEE_INVENTORY_URL } from 'api/assigneeInventory';
import { HTTP_METHODS } from 'constants';
import { scrollIntoView } from 'utils/scroll';

const TITLE = 'Unassign inventory item';

export const UnassignInventoryItemModal = ({ open, onClose, onSuccess, itemId }) => {
    const topElementRef = useRef();
    const {
        data: dataUnassign,
        error: errorUnassign,
        clearData: clearDataUnassign,
        sendRequest: assignInventoryItem,
        loading: loadingUnassign,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(unassignInventoryItemModalValidationSchema),
        defaultValues: {
            endDate: null,
        },
    });

    useEffect(() => {
        clearDataUnassign();

        if (errorUnassign !== null) {
            setResponseMessage(errorUnassign.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUnassign) {
            onSuccess('Inventory item unassigned successfully.', 'success');
            onClose();
        }
    }, [errorUnassign, dataUnassign, messageType]);

    const onSubmit = async (formData) => {
        if (itemId) {
            await assignInventoryItem({
                url: `${UNASSIGN_ASSIGNEE_INVENTORY_URL}/${itemId}`,
                method: HTTP_METHODS.PUT,
                data: {
                    ...formData,
                    endDate: format(formData.endDate, DATE_FORMAT_BACKEND),
                },
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="endDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <DatePicker
                                                label="EndDate"
                                                value={value}
                                                onChange={onChange}
                                                format={DATE_FORMAT}
                                                slotProps={{
                                                    textField: {
                                                        onBlur: onBlur,
                                                        required: true,
                                                        margin: 'dense',
                                                        fullWidth: true,
                                                        error: !!errors.endDate,
                                                        helperText: errors.endDate
                                                            ? errors.endDate.message
                                                            : '',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>

                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingUnassign)}
                            >
                                {itemId && 'Unassign item'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

UnassignInventoryItemModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    itemId: PropTypes.string.isRequired,
};
