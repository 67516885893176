import React, { useEffect, useState } from 'react';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { Box, styled } from '@mui/material';
import useHttp from 'hooks/useHttp';
import {
    myInventoryItemsColumns,
    renderEditButton,
} from 'utils/dataColumns/myInventoryItemColumns';
import { replaceNullValues } from 'utils/misc';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { AlertSnackBar } from 'components/AlertSnackBar';
import PropTypes from 'prop-types';
import { REPLACE_NULL_VALUE } from 'constants';
import { EmpoyeeInventoryEditModal } from 'components/EmpoyeeInventoryEditModal';
import { DATE_FORMAT } from 'constants';
import { format } from 'date-fns';

const MyInventory = ({ filter, showInactive, renderSearchTextField }) => {
    const [inventoryId, setInventoryItemId] = useState('');
    const [assigneeInvntoryId, setAssigneeInvntoryId] = useState('');

    const [snackbars, setSnackbars] = useState([]);
    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);

    const [isInventoryItemModalOpen, setIsInventoryItemModalOpen] = useState(false);

    const [columnsForInventoryItem, setColumnsForInventoryItem] = useState([]);
    const [inventoryItemsDataTable, setInventoryItemsDataTable] = useState([]);
    const [filteredInventoryItemsData, setFilteredInventoryItemsData] = useState([]);

    const {
        data: inventoryItemsWithAssigneeData,
        error: errorGetInventoryItemsWithAssignee,
        sendRequest: getInventoryItemsWithAssignee,
    } = useHttp();

    const StyledCells = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'space-between',
    }));

    useEffect(() => {
        const currentEmployeeId = JSON.parse(localStorage.getItem('employee')).Id;
        if (isGridDataRefreshNeeded) {
            getInventoryItemsWithAssignee({
                url: `${DEFAULT_EMPLOYEE_URL}/assign/${currentEmployeeId}?includeHistory=true`,
            });
            setIsGridDataRefreshNeeded(false);
        }
    }, [isGridDataRefreshNeeded]);

    useEffect(() => {
        const handleErrors = (error, errorMessage) => {
            if (error !== null) {
                showSnackbar(errorMessage, 'error');
            }
        };

        handleErrors(errorGetInventoryItemsWithAssignee, 'Failed to load inventory items.');
    }, [errorGetInventoryItemsWithAssignee]);

    useEffect(() => {
        setColumnsForInventoryItem([
            ...myInventoryItemsColumns.map((column) => {
                if (column.field === 'edit') {
                    return {
                        ...column,
                        renderCell: (params) =>
                            renderEditButton(
                                params,
                                setInventoryItemId,
                                setAssigneeInvntoryId,
                                setIsInventoryItemModalOpen
                            ),
                    };
                }
                return column;
            }),
        ]);
    }, []);

    useEffect(() => {
        if (inventoryItemsWithAssigneeData) {
            setInventoryItemsDataTable([
                ...inventoryItemsWithAssigneeData.map((assigneeInventory) => ({
                    id: assigneeInventory.id,
                    inventoryId: assigneeInventory.inventoryItemId,
                    assigneeName:
                        assigneeInventory.employeeId !== null ? 'You' : assigneeInventory.team.name,
                    name: assigneeInventory.inventoryItem.name,
                    state: `${assigneeInventory.inventoryItem.state}`,
                    description: replaceNullValues(assigneeInventory.inventoryItem.description),
                    type: assigneeInventory.inventoryItem.typeName,
                    specifications: replaceNullValues(
                        assigneeInventory.inventoryItem.specifications
                    ),
                    inventoryNumber: replaceNullValues(
                        assigneeInventory.inventoryItem.inventoryNumber
                    ),
                    serialNumber: replaceNullValues(assigneeInventory.inventoryItem.serialNumber),
                    startDate: format(
                        assigneeInventory.startDate
                            ? new Date(assigneeInventory.startDate).setHours(0, 0, 0, 0)
                            : null,
                        DATE_FORMAT
                    ),
                    endDate: assigneeInventory.endDate
                        ? format(
                              assigneeInventory.endDate
                                  ? new Date(assigneeInventory.endDate).setHours(0, 0, 0, 0)
                                  : null,
                              DATE_FORMAT
                          )
                        : replaceNullValues(assigneeInventory.endDate),
                    filledAssignmentFormLink:
                        assigneeInventory.inventoryAssignmentForm?.filledDocumentLink,
                    signedAssignmentFormLink:
                        assigneeInventory.inventoryAssignmentForm?.signedDocumentLink,
                    filledUnassignmentFormLink:
                        assigneeInventory.inventoryUnassignmentForm?.filledDocumentLink,
                    signedUnassignmentFormLink:
                        assigneeInventory.inventoryUnassignmentForm?.signedDocumentLink,
                })),
            ]);
        }
    }, [inventoryItemsWithAssigneeData]);

    useEffect(() => {
        setFilteredInventoryItemsData(
            inventoryItemsDataTable.filter((item) => {
                const filterToLower = filter.toLowerCase();
                return (
                    (showInactive
                        ? item.endDate !== null && item.endDate !== REPLACE_NULL_VALUE
                        : item.endDate === null || item.endDate === REPLACE_NULL_VALUE) &&
                    (item.name.toLowerCase().includes(filterToLower) ||
                        item.description.toLowerCase().includes(filterToLower) ||
                        item.specifications.toLowerCase().includes(filterToLower) ||
                        item.assigneeName.toLowerCase().includes(filterToLower) ||
                        item.state.toLowerCase().includes(filterToLower) ||
                        item.startDate.toLowerCase().includes(filterToLower) ||
                        item.endDate.toLowerCase().includes(filterToLower) ||
                        item.inventoryNumber.toLowerCase().includes(filterToLower) ||
                        item.serialNumber.toLowerCase().includes(filterToLower) ||
                        item.type.toLowerCase().includes(filterToLower))
                );
            })
        );
    }, [filter, showInactive, inventoryItemsDataTable]);

    const handleInventoryItemModalClose = () => {
        setIsInventoryItemModalOpen(false);
    };

    const handleUpdateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const renderContent = () => {
        return (
            <>
                <div>
                    <StyledCells>
                        <h1>Inventory items</h1>
                    </StyledCells>
                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                        {renderSearchTextField('Show history')}
                        <Box display="flex-start" width="100%">
                            <CommonDataGrid
                                rows={filteredInventoryItemsData}
                                columns={columnsForInventoryItem.filter(
                                    (column) =>
                                        column.field !== 'id' &&
                                        column.field !== 'inventoryId' &&
                                        (showInactive
                                            ? column.field !== 'state' &&
                                              column.field !== 'description' &&
                                              column.field !== 'edit'
                                            : true)
                                )}
                                useCustomToolbar={false}
                            />
                        </Box>
                    </div>
                </div>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return (
        <>
            {renderContent()}
            {isInventoryItemModalOpen && (
                <EmpoyeeInventoryEditModal
                    open={isInventoryItemModalOpen}
                    onClose={handleInventoryItemModalClose}
                    onSuccess={handleUpdateSuccess}
                    assigneeInventoryId={assigneeInvntoryId}
                    inventoryId={inventoryId}
                />
            )}
        </>
    );
};

MyInventory.propTypes = {
    filter: PropTypes.string.isRequired,
    showInactive: PropTypes.bool.isRequired,
    renderSearchTextField: PropTypes.func.isRequired,
};

export default MyInventory;
