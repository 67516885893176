import React, { useEffect } from 'react';
import { useState } from 'react';
import useHttp from 'hooks/useHttp';
import PropTypes from 'prop-types';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { DEFAULT_TEAM_URL } from 'api/team';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { Autocomplete, Box, TextField } from '@mui/material';

export const AssigneeSelect = ({
    selectedAssignee,
    handleAssigneeSelection,
    onError,
    handleFieldBlur,
    error,
    helperText,
}) => {
    const [selectedValue, setSelectedValue] = useState(selectedAssignee);
    const { data: employeesData, error: errorGetEmployees, sendRequest: getEmployees } = useHttp();
    const { data: teamsData, error: errorGetTeams, sendRequest: getTeams } = useHttp();
    const [snackbars, setSnackbars] = useState([]);

    useEffect(() => {
        (async () => {
            await getEmployees({
                url: DEFAULT_EMPLOYEE_URL,
            });
        })();
        (async () => {
            await getTeams({
                url: DEFAULT_TEAM_URL,
            });
        })();
    }, []);

    useEffect(() => {
        if (errorGetEmployees !== null) {
            onError('Failed to load employees.', 'error');
        }
        if (errorGetTeams !== null) {
            onError('Failed to load teams.', 'error');
        }
    }, [errorGetEmployees, errorGetTeams]);

    const handleSelect = (value) => {
        setSelectedValue(value);
        handleAssigneeSelection(value);
    };

    const displayOptions = (option) => `${option.name}`;

    const options = [
        ...(employeesData?.data || []).map((employee) => ({
            value: `${employee.id}_user`,
            name: `${employee.firstName} ${employee.lastName}`,
            type: 'EMPLOYEES',
        })),
        ...(teamsData || []).map((team) => ({
            value: `${team.id}_team`,
            name: `${team.name}`,
            type: 'TEAMS',
        })),
    ];

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    return (
        <>
            <Autocomplete
                options={options}
                autoHighlight
                autoComplete={false}
                sx={{ mt: 1 }}
                groupBy={(option) => option.type}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.name}
                    </Box>
                )}
                getOptionLabel={displayOptions}
                onChange={(event, value) => handleSelect(value)}
                isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                noOptionsText="There are no matching options"
                renderInput={(params) => (
                    <TextField
                        name="AssigneeId"
                        {...params}
                        label="Choose an assignee"
                        inputProps={{
                            ...params.inputProps,
                        }}
                        onBlur={handleFieldBlur}
                        required={!selectedValue}
                        error={error}
                        helperText={helperText}
                    />
                )}
            />
            {snackbars.map((snackbar) => (
                <AlertSnackBar
                    key={snackbar.key}
                    message={snackbar.message}
                    severity={snackbar.severity}
                    onClose={() => closeSnackbar(snackbar.key)}
                />
            ))}
        </>
    );
};

AssigneeSelect.propTypes = {
    handleAssigneeSelection: PropTypes.func,
    onError: PropTypes.func,
    handleFieldBlur: PropTypes.func,
    selectedAssignee: PropTypes.object,
    error: PropTypes.bool,
    helperText: PropTypes.string,
};
