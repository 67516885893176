import React from 'react';
import { Link } from '@mui/material';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { theme } from '../theme';

export const renderActiveCell = (params, { type }) => {
    const filledLink =
        type === 'assignment'
            ? params.row.filledAssignmentFormLink
            : params.row.filledUnassignmentFormLink;
    const signedLink =
        type === 'assignment'
            ? params.row.signedAssignmentFormLink
            : params.row.signedUnassignmentFormLink;

    return (
        <div>
            {filledLink && (
                <Link href={filledLink} target="_blank" rel="noopener noreferrer">
                    <IconButtonWithTooltip
                        icon={<OpenInNewIcon />}
                        tooltipText={`Open filled ${type}`}
                        color={theme.palette.primary.main}
                    />
                </Link>
            )}
            {signedLink && (
                <Link href={signedLink} target="_blank" rel="noopener noreferrer">
                    <IconButtonWithTooltip
                        icon={<OpenInNewIcon />}
                        tooltipText={`Open signed ${type}`}
                        color={theme.palette.secondary.main}
                    />
                </Link>
            )}
        </div>
    );
};

export const inventoryItemHistoryColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'assignee',
        headerName: 'Assignee',
        flex: 0.05,
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        flex: 0.05,
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        flex: 0.05,
    },
    {
        field: 'assignment',
        headerName: 'Assignment',
        flex: 0.03,
        minWidth: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => renderActiveCell(params, { type: 'assignment' }),
    },
    {
        field: 'unassignment',
        headerName: 'Unassignment',
        flex: 0.03,
        minWidth: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => renderActiveCell(params, { type: 'unassignment' }),
    },
];
