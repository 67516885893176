import React, { useEffect } from 'react';
import { useState } from 'react';
import { Chip } from 'components/Chip';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { SelectBar } from 'components/SelectBar';
import useHttp from 'hooks/useHttp';
import PropTypes from 'prop-types';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { DEFAULT_TEAM_URL } from 'api/team';
import { DateRangePicker } from 'components/DateRangePicker';
import { addDays } from 'date-fns';
import { Box } from '@mui/material';
import { AlertSnackBar } from 'components/AlertSnackBar';

const LinkStyle = styled(Link)(() => ({
    margin: '12px',
    fontSize: '13px',
}));

const ChipWrapper = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    position: 'absolute',
}));

export const AbsenceFilter = ({ onChipChange, onDateRangeChanged, onError, dateRangeMonth }) => {
    const [chips, setChips] = useState([]);
    const { data: employeesData, error: errorGetEmployees, sendRequest: getEmployees } = useHttp();
    const { data: teamsData, error: errorGetTeams, sendRequest: getTeams } = useHttp();
    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
    const [snackbars, setSnackbars] = useState([]);

    const handleDateRangeChange = (dateRange, validationError) => {
        if (!validationError) {
            const dateDifference = (dateRange[1] - dateRange[0]) / (1000 * 60 * 60 * 24);
            if (dateDifference < 6) {
                const daysToAdd = 6 - dateDifference;
                const newEndDate = new Date(dateRange[1]);
                newEndDate.setDate(dateRange[1].getDate() + daysToAdd);
                dateRange[1] = newEndDate;
            }
        }
        setSelectedDateRange(dateRange);
        onDateRangeChanged(dateRange, validationError);
    };

    const setDefaultDateRange = () => {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        setSelectedDateRange([firstDayOfMonth, lastDayOfMonth]);
    };

    useEffect(() => {
        setDefaultDateRange();
    }, []);

    useEffect(() => {
        (async () => {
            await getEmployees({
                url: DEFAULT_EMPLOYEE_URL,
            });
        })();
        (async () => {
            await getTeams({
                url: DEFAULT_TEAM_URL,
            });
        })();
    }, []);

    useEffect(() => {
        if (errorGetEmployees !== null) {
            onError('Failed to load employees.', 'error');
        }
        if (errorGetTeams !== null) {
            onError('Failed to load teams.', 'error');
        }
    }, [errorGetEmployees, errorGetTeams]);

    useEffect(() => {
        if (dateRangeMonth !== null) {
            const startDate = new Date(dateRangeMonth[0]);
            const endDate = new Date(dateRangeMonth[1]);
            endDate.setDate(endDate.getDate());
            setSelectedDateRange([startDate, endDate]);
        }
    }, [dateRangeMonth]);

    const shouldClearAllLinkBeVisible = () => chips.length > 2;

    const removeChip = (index) => {
        let updatedChips = chips.filter((_, i) => i !== index);
        setChips(updatedChips);
        onChipChange(updatedChips);
    };

    const clearAllChips = () => {
        setChips([]);
        onChipChange([]);
    };

    const handleSelect = (selectedOption) => {
        if (selectedOption && !chips.some((chip) => chip.value === selectedOption.value)) {
            let updatedChips = [...chips, selectedOption];
            setChips(updatedChips);
            onChipChange(updatedChips);
        }
    };

    const displayOptions = (option) =>
        `${option.name} ${chips.some((chip) => chip.value === option.value) ? ' ✔' : ''}`;

    const options = [
        ...(employeesData?.data || []).map((employee) => ({
            value: `${employee.id}_user`,
            name: `${employee.firstName} ${employee.lastName}`,
            type: 'EMPLOYEES',
        })),
        ...(teamsData || []).map((team) => ({
            value: `${team.id}_team`,
            name: `${team.name}`,
            type: 'TEAMS',
        })),
    ];

    const shouldDisableEndDate = (day) => {
        const startDate = selectedDateRange[0];
        const minSelectedEndDate = addDays(day, -6);
        if (startDate) {
            return minSelectedEndDate < startDate;
        }
        return false;
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    return (
        <>
            <Box sx={{display: 'flex'}}>
                <SelectBar
                    onSelect={handleSelect}
                    options={options}
                    displayOptions={displayOptions}
                />
                <Box component="div" sx={{ display: 'flex' }}>
                    <DateRangePicker
                        selectedDateRange={selectedDateRange}
                        onChange={handleDateRangeChange}
                        shouldDisableEndDate={shouldDisableEndDate}
                    />
                </Box>
            </Box>
            <ChipWrapper>
                {chips.map((chip, index) => (
                    <div key={chip.value}>
                        <Chip label={chip.name} index={index} onDelete={() => removeChip(index)} />
                    </div>
                ))}
                {shouldClearAllLinkBeVisible() && (
                    <LinkStyle onClick={clearAllChips}>Clear All</LinkStyle>
                )}
            </ChipWrapper>
            {snackbars.map((snackbar) => (
                <AlertSnackBar
                    key={snackbar.key}
                    message={snackbar.message}
                    severity={snackbar.severity}
                    onClose={() => closeSnackbar(snackbar.key)}
                />
            ))}
        </>
    );
};

AbsenceFilter.propTypes = {
    onChipChange: PropTypes.func,
    onDateRangeChanged: PropTypes.func,
    dateRangeMonth: PropTypes.array,
    onError: PropTypes.func,
};
