import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useHttp from 'hooks/useHttp';
import { DEFAULT_ANNUAL_LEAVE_DECISION_URL } from 'api/annualLeaveDecision';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { getAuthToken } from 'utils/auth';
import { scrollIntoView } from 'utils/scroll';
import { HTTP_METHODS } from 'constants';

const TITLE = 'Upload signed annual leave decision';

const INFO_MESSAGE =
    'Choose a signed annual leave decision document and click "UPLOAD." Choosen document will be uploaded and notification sent to employee and administrators.';

export const AnnualLeaveDecisionUploadModal = ({
    open,
    onClose,
    onSuccess,
    annualLeaveDecisionId,
}) => {
    const topElementRef = useRef();
    const {
        data,
        error,
        clearData,
        sendRequest: uploadSignedAnnualLeaveDecision,
        loading,
    } = useHttp();
    const [messageType, setMessageType] = useState();
    const [responseMessage, setResponseMessage] = useState(INFO_MESSAGE);
    const [selectedFile, setSelectedFile] = useState(null);
    const [override, setOverride] = useState(false);

    useEffect(() => {
        clearData();

        if (error !== null) {
            setResponseMessage(error.response?.data?.message || 'An error occurred');
            setMessageType('error');
            if (
                error.response?.data?.message &&
                error.response?.data?.message.includes('already exists')
            ) {
                setOverride(true);
            }
        }

        if (data) {
            if (data.includes('WARNING')) {
                onSuccess(data, 'warning');
                setMessageType('warning');
            } else {
                onSuccess(data, 'success');
                setMessageType('success');
            }

            setResponseMessage(data);
            setOverride(false);
            setSelectedFile(null);
        }
    }, [error, data, messageType, onClose]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

            if (fileExtension !== 'pdf') {
                setMessageType('error');
                setResponseMessage('Invalid file format. Please upload a pdf file.');
                return;
            }

            setSelectedFile(file);
            setOverride(false);
            setMessageType();
            setResponseMessage(INFO_MESSAGE);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestBody = new FormData();
        requestBody.append('file', selectedFile);

        const headers = {
            'Content-Type': 'multipart/form-data',
            Accept: 'multipart/form-data',
            Authorization: `Bearer ${getAuthToken()}`,
        };

        await uploadSignedAnnualLeaveDecision({
            url: `${DEFAULT_ANNUAL_LEAVE_DECISION_URL}/upload-annual-leave-decision?annualLeaveDecisionId=${annualLeaveDecisionId}&overrideIfExists=${override}`,
            method: HTTP_METHODS.POST,
            data: requestBody,
            headers: headers,
        });

        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container width={500} ref={topElementRef}>
                    <form onSubmit={handleSubmit}>
                        <Box marginBottom={3} width={500}>
                            {messageType !== 'none' && (
                                <MessageBox messageType={messageType}>
                                    {messageType === 'success' ? (
                                        <>
                                            <Typography>
                                                Successfully uploaded signed annual leave decision.
                                            </Typography>
                                            <Link
                                                href={responseMessage}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                View
                                            </Link>
                                        </>
                                    ) : (
                                        responseMessage
                                    )}
                                </MessageBox>
                            )}
                        </Box>
                        <Grid container>
                            <input
                                accept=".pdf, text/pdf"
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                marginBottom={3}
                            >
                                <label htmlFor="file-upload">
                                    <Button variant="outlined" component="span">
                                        Choose File
                                    </Button>
                                </label>
                                {selectedFile && (
                                    <Typography
                                        color="textSecondary"
                                        style={{ marginLeft: '16px' }}
                                    >
                                        {selectedFile.name}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        {override ? (
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="error"
                                    disabled={loading}
                                >
                                    OVERRIDE
                                </Button>
                            </Box>
                        ) : (
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button type="submit" variant="contained" disabled={loading}>
                                    UPLOAD
                                </Button>
                            </Box>
                        )}
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

AnnualLeaveDecisionUploadModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    annualLeaveDecisionId: PropTypes.string.isRequired,
};
