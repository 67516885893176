import React from 'react';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Link from '@mui/material/Link';
import { theme } from 'utils/theme';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';

export const renderResendNotificationButton = (
    params,
    setReportId,
    setIsResendNotificationModalOpen
) => {
    const handleResendNotificationClick = () => {
        setReportId(params.row.id);
        setIsResendNotificationModalOpen(true);
    };

    return (
        <IconButtonWithTooltip
            onClick={handleResendNotificationClick}
            icon={<ForwardToInboxIcon />}
            tooltipText="Resend notification"
            color={theme.palette.primary.main}
        />
    );
};

export const renderActiveCell = (params) => {
    return <Link href={params.value}>{params.value}</Link>;
};

export const reportColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'year',
        headerName: 'Year',
        flex: 0.05,
    },
    { field: 'month', headerName: 'Month', flex: 0.05, minWidth: 20 },
    { field: 'version', headerName: 'Version', flex: 0.05, minWidth: 20 },
    {
        field: 'link',
        headerName: 'Link',
        flex: 0.6,
        minWidth: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: renderActiveCell,
    },
    {
        field: 'generationDate',
        headerName: 'Generated At',
        flex: 0.1,
        minWidth: 50,
    },
    {
        field: 'generatedBy',
        headerName: 'Generated By',
        flex: 0.1,
        minWidth: 50,
    },
    {
        field: 'resendNotification',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        align: 'center',
        renderCell: renderResendNotificationButton,
    },
];
