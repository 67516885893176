import React from 'react';
import { Typography } from '@mui/material';

export const absenceColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'absenceType',
        headerName: 'Absence Type',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.3,
        minWidth: 50,
        renderCell: (params) => (
            <Typography
                variant={
                    params.value === 'Approved'
                        ? 'greenBackground'
                        : params.value === 'Canceled'
                        ? 'redBackground'
                        : ''
                }
            >
                {params.value}
            </Typography>
        ),
    },
];
