import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress,
    Paper,
} from '@mui/material';
import { hexToRgba } from 'utils/misc';
import { theme } from 'utils/theme';

export const AbsenceQuotaCardDetailed = ({ title, available, used, left, color }) => {
    const cardStyle = {
        minWidth: 200,
        margin: '8px',
    };

    const progressStyle = {
        marginTop: '16px',
    };

    const availableColor = theme.palette.colorGray.main;
    const usedColor = theme.palette.blackColor.main;
    const leftColor = color;
    const progressBarColor = theme.palette.colorGray.main;

    return (
        <Card style={{ ...cardStyle, opacity: used === available ? 0.5 : 1 }}>
            <CardContent sx={{ backgroundColor: hexToRgba(color, 0.2) }}>
                <Typography variant="h6">{title}</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{ paddingLeft: '6px' }}>
                                    Assigned
                                </TableCell>
                                <TableCell align="center" style={{ paddingLeft: '6px' }}>
                                    Used
                                </TableCell>
                                <TableCell align="center" style={{ paddingLeft: '6px' }}>
                                    Left
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    style={{ color: availableColor, fontSize: '16px' }}
                                >
                                    {available}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: used > 0 ? usedColor : availableColor,
                                        fontSize: '16px',
                                    }}
                                >
                                    {used}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        color: leftColor,
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {left}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <LinearProgress
                    variant="determinate"
                    value={(used / available) * 100}
                    style={{ ...progressStyle, backgroundColor: color }}
                    sx={{
                        backgroundColor: color,
                        '& .MuiLinearProgress-bar': { backgroundColor: progressBarColor },
                    }}
                />
            </CardContent>
        </Card>
    );
};

AbsenceQuotaCardDetailed.propTypes = {
    title: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
    used: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    color: PropTypes.string,
};
