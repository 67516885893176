import React, { useContext } from 'react';
import AuthContext from 'contexts/auth-context';
import { Navigate, useRoutes } from 'react-router-dom';
import LayoutRoot from 'components/shared/LayoutRoot';
import { permissionRules } from 'utils/permissionRules';
import NotFound from 'pages/NotFound';
import { RouteGuard } from 'components/RouteGuard';
import Dashboard from 'pages/Dashboard';
import Employees from 'pages/Employees';
import Teams from 'pages/Teams';
import AbsenceTypes from 'pages/AbsenceTypes';
import Holidays from 'pages/Holidays';
import GeneralAdministration from 'pages/GeneralAdministration';
import FAQ from 'pages/FAQ';
import Inventory from 'pages/Inventory';
import Login from 'pages/Login/Login';
import Profile from 'pages/Profile/Profile';

export const Routes = () => {
    const authContext = useContext(AuthContext);
    const { isLoggedIn } = authContext;

    return useRoutes([
        {
            path: '/',
            element: isLoggedIn ? <LayoutRoot /> : <Navigate to="/login" />,
            errorElement: <NotFound />,
            children: [
                {
                    index: true,
                    element: <RouteGuard component={<Dashboard />} roles={permissionRules.All} />,
                },
                {
                    path: '/employees',
                    element: <RouteGuard component={<Employees />} roles={permissionRules.Admin} />,
                },
                {
                    path: '/teams',
                    element: <RouteGuard component={<Teams />} roles={permissionRules.Admin} />,
                },
                {
                    path: '/absencetypes',
                    element: (
                        <RouteGuard component={<AbsenceTypes />} roles={permissionRules.Admin} />
                    ),
                },
                {
                    path: '/holidays',
                    element: <RouteGuard component={<Holidays />} roles={permissionRules.Admin} />,
                },
                {
                    path: '/generaladministration',
                    element: (
                        <RouteGuard
                            component={<GeneralAdministration />}
                            roles={permissionRules.Admin}
                        />
                    ),
                },
                {
                    path: '/faq',
                    element: <RouteGuard component={<FAQ />} roles={permissionRules.All} />,
                },
                {
                    path: '/profile',
                    element: <RouteGuard component={<Profile />} roles={permissionRules.All} />,
                },
                {
                    path: '/inventory',
                    element: (
                        <RouteGuard
                            component={<Inventory />}
                            roles={permissionRules.InventoryAdmin}
                        />
                    ),
                },
            ],
        },
        {
            path: '/login',
            element: isLoggedIn ? <Navigate to="/" /> : <Login />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]);
};
