import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import {
    Box,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { holidayModalValidationSchema } from 'utils/validationSchemas';
import { DATE_FORMAT, DATE_FORMAT_BACKEND, HTTP_METHODS } from 'constants';
import { DEFAULT_HOLIDAY_URL } from 'api/holiday';

export const HolidayModal = ({ open, onClose, onSuccess, holidayId }) => {
    const topElementRef = useRef();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: createHoliday,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateHoliday,
        loading: loadingUpdate,
    } = useHttp();
    const {
        data: holidayData,
        error: errorGetHoliday,
        sendRequest: getHolidayData,
        loading: loadingData,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title] = useState(holidayId ? 'Update holiday' : 'Add new holiday');
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(holidayModalValidationSchema),
        defaultValues: {
            name: '',
            recurring: false,
            startDate: null,
            endDate: null,
            type: 'Other',
        },
    });

    useEffect(() => {
        if (holidayId) {
            (async () => {
                await getHolidayData({
                    url: `${DEFAULT_HOLIDAY_URL}/${holidayId}`,
                });
            })();
        }
    }, [holidayId]);

    useEffect(() => {
        if (errorGetHoliday !== null) {
            setResponseMessage(errorGetHoliday.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
    }, [errorGetHoliday]);

    useEffect(() => {
        if (holidayData) {
            reset({
                ...holidayData,
                startDate: holidayData.startDate ? new Date(holidayData.startDate) : null,
                endDate: holidayData.endDate ? new Date(holidayData.endDate) : null,
            });
        }
    }, [holidayData, reset]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            onSuccess(`Holiday ${dataCreate.name} created successfully.`, 'success');
            onClose();
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(`Holiday ${dataUpdate.name} updated successfully.`, 'success');
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    const onSubmit = async (formData) => {
        const requestBody = {
            ...formData,
            startDate: format(formData.startDate, DATE_FORMAT_BACKEND),
            endDate: format(formData.endDate, DATE_FORMAT_BACKEND),
        };

        if (holidayId) {
            await updateHoliday({
                url: `${DEFAULT_HOLIDAY_URL}/${holidayId}`,
                method: HTTP_METHODS.PUT,
                data: requestBody,
            });
        } else {
            await createHoliday({
                url: `${DEFAULT_HOLIDAY_URL}`,
                method: HTTP_METHODS.POST,
                data: requestBody,
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        label="Name"
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        fullWidth
                                        required
                                        error={!!errors.name}
                                        helperText={errors.name ? errors.name.message : ''}
                                    />
                                )}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <DatePicker
                                                    label="Start Date"
                                                    value={value}
                                                    onChange={onChange}
                                                    format={DATE_FORMAT}
                                                    slotProps={{
                                                        textField: {
                                                            onBlur: onBlur,
                                                            required: true,
                                                            margin: 'dense',
                                                            fullWidth: true,
                                                            error: !!errors.startDate,
                                                            helperText: errors.startDate
                                                                ? errors.startDate.message
                                                                : '',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="endDate"
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <DatePicker
                                                    label="End Date"
                                                    value={value}
                                                    onChange={onChange}
                                                    format={DATE_FORMAT}
                                                    slotProps={{
                                                        textField: {
                                                            onBlur: onBlur,
                                                            required: true,
                                                            margin: 'dense',
                                                            fullWidth: true,
                                                            error: !!errors.endDate,
                                                            helperText: errors.endDate
                                                                ? errors.endDate.message
                                                                : '',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                            <FormControl fullWidth margin="dense">
                                <InputLabel>Type</InputLabel>
                                <Controller
                                    control={control}
                                    name="type"
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            label="Type"
                                            size="small"
                                            value={value}
                                            onChange={onChange}
                                        >
                                            <MenuItem value="Other">Other</MenuItem>
                                            <MenuItem value="Public">Public</MenuItem>
                                            <MenuItem value="Religious">Religious</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Controller
                            control={control}
                            name="recurring"
                            render={({ field: { onChange, value } }) => (
                                <Checkbox name="recurring" checked={value} onChange={onChange} />
                            )}
                        />
                        <span>Is this holiday recurring?</span>

                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingData, loadingCreate, loadingUpdate)}
                            >
                                {holidayId ? 'Update' : 'Add'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

HolidayModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    holidayId: PropTypes.string,
};
