import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { colorMapping, messageBoxStyles } from 'components/MessageBox/MessageBox.styles';
import { Typography } from '@mui/material';

export const MessageBox = ({ children, messageType = 'information' }) => {
    const [messageLines, setMessageLines] = useState([]);
    const isStringChildren = typeof children === 'string';

    useEffect(() => {
        if (isStringChildren) {
            setMessageLines(children.split('\n'));
        }
    }, [children]);

    const getStyle = (isString) => {
        const baseStyle = {
            borderLeft: `4px solid ${colorMapping[messageType] || colorMapping.default}`,
        };

        return {
            ...(isString ? messageBoxStyles.container : messageBoxStyles.nodeElement),
            ...baseStyle,
        };
    };

    const getIcon = () => {
        const iconStyle = {
            color: colorMapping[messageType],
        };

        const icons = {
            information: <InfoIcon style={iconStyle} />,
            success: <CheckCircle style={iconStyle} />,
            error: <ErrorIcon style={iconStyle} />,
            warning: <InfoIcon style={iconStyle} />,
        };

        return icons[messageType];
    };

    return (
        <div style={getStyle(isStringChildren)}>
            {getIcon()}
            <div style={messageBoxStyles.messageText}>
                {isStringChildren
                    ? messageLines.map((line, index) => (
                          <Typography key={index} variant="body1">
                              {line}
                          </Typography>
                      ))
                    : children}
            </div>
        </div>
    );
};

MessageBox.propTypes = {
    children: PropTypes.node.isRequired,
    messageType: PropTypes.oneOf(['information', 'success', 'error', 'warning', 'none']),
};
