import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { theme } from 'utils/theme';
import { isAdmin } from 'utils/auth';
import faqData from 'utils/faqData.json';

const FAQ = () => {
    const [expanded, setExpanded] = useState('');
    const handleChange = (index) => (event, newExpanded) => {
        setExpanded(newExpanded ? index : false);
    };

    const NoFooterPagination = () => <div style={{ display: 'none' }} />;

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <h1 style={{ marginBottom: '24px' }}>Frequently asked questions</h1>
            {faqData.map((item, index) =>
                (isAdmin() && (item.visibility === 'all' || item.visibility === 'admin')) ||
                (!isAdmin() && item.visibility === 'all') ? (
                    <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                    >
                        <AccordionSummary
                            variant="padding"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}a-content`}
                            id={`panel${index}a-header`}
                            style={{
                                color: theme.palette.primary.main,
                                paddingLeft: item.visibility === 'admin' ? 0 : '16px',
                            }}
                        >
                            {item.visibility === 'admin' && (
                                <PriorityHighIcon
                                    style={{
                                        color: theme.palette.secondary.main,
                                    }}
                                />
                            )}
                            <Typography fontWeight="bold" style={{ textTransform: 'uppercase' }}>
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {item.datagrid ? (
                                <DataGrid
                                    columns={item.datagrid.columns}
                                    rows={item.datagrid.rows}
                                    components={{
                                        Pagination: NoFooterPagination,
                                    }}
                                />
                            ) : (
                                <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                                    {item.answer}
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ) : null
            )}
        </Box>
    );
};

export default FAQ;
