import { COLORS } from 'constants';

export const messageBoxStyles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        marginBottom: '16px',
        borderRadius: '5px',
        backgroundColor: COLORS.VERY_LIGHT_GRAY,
    },
    nodeElement: {
        display: 'flex',
        padding: '8px',
        width: 'auto',
        minWidth: '350px',
        backgroundColor: COLORS.WHITE,
        borderRight: '1px solid rgba(0,0,0,.1)',
        borderTop: '1px solid rgba(0,0,0,.1)',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        borderRadius: '5px',
    },
    messageText: {
        marginLeft: 8,
    },
};

export const colorMapping = {
    information: COLORS.BLUE,
    success: COLORS.GREEN,
    error: COLORS.RED,
    warning: COLORS.ORANGE,
    default: COLORS.WHITE,
};
