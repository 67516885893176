import React from 'react';
import { isActive } from '../misc';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';
import { PowerSettingsNew } from '@mui/icons-material';
import { theme } from '../theme';
import Edit from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';

export const renderEditButton = (params, setInventoryTypeId, setIsInventoryTypeModalOpen) => {
    const handleEditClick = () => {
        setInventoryTypeId(params.row.id);
        setIsInventoryTypeModalOpen(true);
    };
    if (isActive(params.row)) {
        return (
            <IconButtonWithTooltip
                onClick={handleEditClick}
                icon={<Edit />}
                tooltipText="Edit"
                color={theme.palette.primary.main}
            />
        );
    } else {
        return null;
    }
};

export const renderDeleteReactivateButton = (
    params,
    setInventoryTypeId,
    setIsDeleteInventoryTypeModalOpen,
    setIsReactivateInventoryTypeModalOpen
) => {
    const handleDeleteClick = () => {
        setInventoryTypeId(params.row.id);
        setIsDeleteInventoryTypeModalOpen(true);
    };
    const handleReactivateClick = () => {
        setInventoryTypeId(params.row.id);
        setIsReactivateInventoryTypeModalOpen(true);
    };
    return (
        <div>
            {isActive(params.row) ? (
                <IconButtonWithTooltip
                    onClick={handleDeleteClick}
                    icon={<PowerSettingsNew />}
                    tooltipText="Deactivate"
                    color={theme.palette.redColor.main}
                />
            ) : (
                <IconButtonWithTooltip
                    onClick={handleReactivateClick}
                    icon={<SyncIcon />}
                    tooltipText="Reactivate"
                    color={theme.palette.primary.main}
                />
            )}
        </div>
    );
};

export const inventoryTypesColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'name',
        headerName: 'Name',
        flex: 0.05,
    },
    {
        field: 'active',
        type: 'boolean',
        headerName: 'Active',
    },
    {
        field: 'isSerialNumberRequired',
        headerName: 'Serial Number Required',
        flex: 0.3,
    },
    {
        field: 'edit',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderEditButton,
    },
    {
        field: 'delete-reactivate',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderDeleteReactivateButton,
    },
];
