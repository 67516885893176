import React, { useEffect, useRef } from 'react';
import { MenuItem, MenuList, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import { format, startOfMonth } from 'date-fns';
import { DATE_FORMAT_BACKEND } from 'constants';

export const AbsenceCancelMenu = ({ mouseX, mouseY, onClose, onOutsideClick, startDate }) => {
    const containerRef = useRef();

    useEffect(() => {
        const handleMouseDown = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                onOutsideClick();
            }
        };

        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, [onOutsideClick]);

    const checkIsValidDate = () => {
        return (
            format(new Date(startDate), DATE_FORMAT_BACKEND) <
            format(startOfMonth(new Date()), DATE_FORMAT_BACKEND)
        );
    };

    return (
        <Paper sx={{ width: 100, position: 'absolute', top: mouseY, left: mouseX, zIndex: 90 }}>
            <MenuList sx={{ padding: 0 }} dense>
                <MenuItem
                    sx={{ paddingLeft: '10px' }}
                    ref={containerRef}
                    onClick={onClose}
                    disabled={checkIsValidDate()}
                >
                    <CancelIcon sx={{ fontSize: 15 }} />
                    <Typography sx={{ marginLeft: '5px' }} component="p">
                        Cancel
                    </Typography>
                </MenuItem>
            </MenuList>
        </Paper>
    );
};

AbsenceCancelMenu.propTypes = {
    mouseX: PropTypes.number.isRequired,
    mouseY: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    startDate: PropTypes.string.isRequired,
    onOutsideClick: PropTypes.func,
};
