import jwtDecode from 'jwt-decode';

export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) {
        return false;
    }
    return true;
};

export const setAuthToken = (props) => {
    if (props === undefined) {
        return null;
    }
    localStorage.setItem('token', props);
    const employee = decodeAuthToken(props);
    if (employee) {
        localStorage.setItem('employee', JSON.stringify(employee));
    } else {
        removeAuthToken();
    }
};

export const setRefreshToken = (props) => {
    if (props === undefined) {
        return null;
    }
    localStorage.setItem('refreshToken', props);
};

export const removeAuthToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('employee');
    localStorage.removeItem('refreshToken');
};

export const getAuthToken = () => localStorage.getItem('token');

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const decodeAuthToken = (token) => {
    if (!token) {
        return null;
    }
    try {
        const decoded = jwtDecode(token);
        return decoded.employee;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

export const hasPermissions = () => {
    const storedEmployee = localStorage.getItem('employee');
    if (storedEmployee) {
        return JSON.parse(storedEmployee).Roles;
    }
    return false;
};

export const isAdmin = () => {
    const storedEmployee = localStorage.getItem('employee');
    if (storedEmployee) {
        const roles = JSON.parse(storedEmployee).Roles;
        if (roles.includes('Admin') || roles.includes('Superadmin')) {
            return true;
        }
    }
    return false;
};

export const getEmployeeId = () => {
    const employee = localStorage.getItem('employee');
    if (employee) {
        return JSON.parse(employee).Id;
    }
    return false;
};

export const getEmployeeFullName = () => {
    const employee = localStorage.getItem('employee');
    if (employee) {
        const { FirstName, LastName } = JSON.parse(employee);
        return `${FirstName} ${LastName}`;
    }
    return false;
};
