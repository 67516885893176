import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { theme } from 'utils/theme';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';

export const renderEditButton = (params, setTeamId, setIsTeamModalOpen) => {
    const handleEditClick = () => {
        setTeamId(params.row.id);
        setIsTeamModalOpen(true);
    };

    return (
        <IconButtonWithTooltip
            onClick={handleEditClick}
            icon={<EditIcon />}
            tooltipText="Edit"
            color={theme.palette.primary.main}
        />
    );
};

export const renderDeleteButton = (params, setTeamId, setIsDeleteTeamModalOpen) => {
    const handleDeleteClick = () => {
        setTeamId(params.row.id);
        setIsDeleteTeamModalOpen(true);
    };

    return (
        <div>
            <IconButtonWithTooltip
                onClick={handleDeleteClick}
                icon={<DeleteForeverIcon />}
                tooltipText="Delete"
                color={theme.palette.redColor.main}
            />
        </div>
    );
};

export const teamColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'name',
        headerName: 'Name',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'edit',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderEditButton,
    },
    {
        field: 'delete',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderDeleteButton,
    },
];
