import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import {
    Box,
    TextField,
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    ListSubheader,
    Checkbox,
    ListItemText,
    OutlinedInput,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { employeeModalValidationSchema } from 'utils/validationSchemas';
import {
    DATE_FORMAT,
    DATE_FORMAT_BACKEND,
    HTTP_METHODS,
    EMPLOYEE_CATEGORY,
    DEFAULT_ROLE_NAME,
} from 'constants';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { DEFAULT_ROLE_URL } from 'api/role';

export const EmployeeModal = ({ open, onClose, onSuccess, employeeId }) => {
    const topElementRef = useRef();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: createEmployee,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateEmployee,
        loading: loadingUpdate,
    } = useHttp();
    const {
        data: employeeData,
        error: errorGetEmployee,
        sendRequest: getEmployeeData,
        loading: loadingData,
    } = useHttp();
    const { data: rolesData, error: errorGetRoles, sendRequest: getRoles } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title] = useState(employeeId ? 'Update employee' : 'Add new employee');
    const {
        setValue,
        getValues,
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(employeeModalValidationSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            pxCode: '',
            hireDate: null,
            roles: [],
            active: true,
            available: 0,
            usedVacationCurrentYear: 0,
            usedVacationPreviousYear: 0,
            category: EMPLOYEE_CATEGORY.consultant,
            workingPlace: '',
        },
    });

    useEffect(() => {
        if (employeeId) {
            (async () => {
                await getEmployeeData({
                    url: `${DEFAULT_EMPLOYEE_URL}/${employeeId}`,
                });
            })();
        }
    }, [employeeId]);

    useEffect(() => {
        if (errorGetEmployee !== null || errorGetRoles != null) {
            setResponseMessage(
                errorGetEmployee.response?.data?.message ||
                    errorGetRoles.response?.data?.message ||
                    'An error occurred'
            );
            setMessageType('error');
        }
    }, [errorGetEmployee, errorGetRoles]);

    useEffect(() => {
        if (employeeData) {
            reset({
                ...employeeData,
                pxCode: employeeData.pxCode || '',
                workingPlace: employeeData.workingPlace || '',
                hireDate: employeeData.hireDate ? new Date(employeeData.hireDate) : null,
            });
        }
    }, [employeeData, reset]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            onSuccess(
                `Employee ${dataCreate.firstName} ${dataCreate.lastName} created successfully.`,
                'success'
            );
            onClose();
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(
                `Employee ${dataUpdate.firstName} ${dataUpdate.lastName} updated successfully.`,
                'success'
            );
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    useEffect(() => {
        (async () => {
            await getRoles({
                url: DEFAULT_ROLE_URL,
            });
        })();
    }, []);

    useEffect(() => {
        if (Array.isArray(rolesData) && getValues('roles').length === 0) {
            const defaultRole = rolesData.find((role) => role.name === DEFAULT_ROLE_NAME);
            setValue('roles', [defaultRole]);
        }
    }, [rolesData, getValues('roles')]);

    const onSubmit = async (formData) => {
        const requestBody = {
            ...formData,
            pxCode: formData.pxCode || null,
            workingPlace: formData.workingPlace || null,
            hireDate: format(formData.hireDate, DATE_FORMAT_BACKEND),
            roleIds: (formData.roles || []).map((role) => role.id),
        };

        if (employeeId) {
            requestBody.id = formData.id;
            await updateEmployee({
                url: `${DEFAULT_EMPLOYEE_URL}/${employeeId}`,
                method: HTTP_METHODS.PUT,
                data: requestBody,
            });
        } else {
            await createEmployee({
                url: `${DEFAULT_EMPLOYEE_URL}`,
                method: HTTP_METHODS.POST,
                data: requestBody,
            });
        }
        scrollIntoView(topElementRef);
    };

    const mainRoles = Array.isArray(rolesData) ? rolesData.filter((role) => role.mainRole) : [];
    const additionalRoles = Array.isArray(rolesData)
        ? rolesData.filter((role) => !role.mainRole)
        : [];

    const handleRoleChange = (event) => {
        const { value } = event.target;
        const selectedRoleIds = Array.isArray(value) ? value : [value];
        const selectedRoles = selectedRoleIds.map((roleId) =>
            rolesData.find((role) => role.id === roleId)
        );

        const additionalRoles = getValues('roles').filter((role) => !role.mainRole);

        setValue('roles', [...selectedRoles, ...additionalRoles]);
    };

    const handleAdditionalRoleChange = (event) => {
        const { value, checked } = event.target;
        const roleId = value;
        setValue(
            'roles',
            checked
                ? [...getValues('roles'), rolesData.find((role) => role.id === roleId)]
                : getValues('roles').filter((role) => role.id !== roleId)
        );
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="firstName"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="First Name"
                                            value={value}
                                            margin="dense"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            error={!!errors.firstName}
                                            helperText={
                                                errors.firstName ? errors.firstName.message : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="lastName"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Last Name"
                                            value={value}
                                            margin="dense"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            error={!!errors.lastName}
                                            helperText={
                                                errors.lastName ? errors.lastName.message : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="Email"
                                    type="email"
                                    value={value}
                                    margin="dense"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    fullWidth
                                    required
                                    error={!!errors.email}
                                    helperText={errors.email ? errors.email.message : ''}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="pxCode"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="PX Code"
                                    value={value}
                                    margin="dense"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    fullWidth
                                />
                            )}
                        />
                        <Grid container marginTop={'16px'}>
                            <Grid item xs={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={enGB}
                                >
                                    <Controller
                                        control={control}
                                        name="hireDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <DatePicker
                                                label="Hire Date"
                                                value={value}
                                                format={DATE_FORMAT}
                                                onChange={onChange}
                                                slotProps={{
                                                    textField: {
                                                        onBlur: onBlur,
                                                        required: true,
                                                        margin: 'dense',
                                                        fullWidth: true,
                                                        error: !!errors.hireDate,
                                                        helperText: errors.hireDate
                                                            ? errors.hireDate.message
                                                            : '',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>Category</InputLabel>
                                    <Controller
                                        control={control}
                                        name="category"
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                label="Category"
                                                value={value}
                                                size="small"
                                                onChange={onChange}
                                            >
                                                <MenuItem value={EMPLOYEE_CATEGORY.consultant}>
                                                    {EMPLOYEE_CATEGORY.consultant}
                                                </MenuItem>
                                                <MenuItem value={EMPLOYEE_CATEGORY.overhead}>
                                                    {EMPLOYEE_CATEGORY.overhead}
                                                </MenuItem>
                                                <MenuItem
                                                    value={EMPLOYEE_CATEGORY.officeAdministrator}
                                                >
                                                    {EMPLOYEE_CATEGORY.officeAdministrator}
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <Controller
                                    control={control}
                                    name="workingPlace"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Working Place"
                                            value={value}
                                            margin="dense"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                        />
                                    )}
                                />
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>Roles</InputLabel>
                                    {Array.isArray(rolesData) && rolesData.length > 0 && (
                                        <Controller
                                            control={control}
                                            name="roles"
                                            render={({ field: { value } }) => (
                                                <Select
                                                    label="Roles"
                                                    multiple
                                                    size="small"
                                                    value={value}
                                                    input={<OutlinedInput label="Roles" />}
                                                    renderValue={(selected) => {
                                                        const selectedRoles = selected.map((id) => {
                                                            const role = rolesData.find(
                                                                (role) => role.id === id.id
                                                            );
                                                            return role ? role.name : '';
                                                        });
                                                        return selectedRoles.join(', ');
                                                    }}
                                                >
                                                    <ListSubheader>
                                                        MAIN (select only one)
                                                    </ListSubheader>
                                                    {mainRoles.map((role) => (
                                                        <MenuItem key={role.id} value={role.id}>
                                                            <RadioGroup
                                                                aria-label="main-role"
                                                                name="main-role"
                                                                value={
                                                                    value.length > 0 &&
                                                                    value.some(
                                                                        (r) => r.id === role.id
                                                                    )
                                                                        ? role.id
                                                                        : ''
                                                                }
                                                                onChange={handleRoleChange}
                                                            >
                                                                <FormControlLabel
                                                                    value={role.id}
                                                                    control={<Radio />}
                                                                    label={role.name}
                                                                />
                                                            </RadioGroup>
                                                        </MenuItem>
                                                    ))}
                                                    <ListSubheader>ADDITIONAL</ListSubheader>
                                                    {additionalRoles.map((role) => (
                                                        <MenuItem key={role.id} value={role.id}>
                                                            <Checkbox
                                                                checked={value.some(
                                                                    (r) => r.id === role.id
                                                                )}
                                                                onChange={
                                                                    handleAdditionalRoleChange
                                                                }
                                                                value={role.id}
                                                            />
                                                            <ListItemText primary={role.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container marginTop={'16px'}>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="available"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Available Vacation"
                                            type="number"
                                            value={value}
                                            margin="dense"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            error={!!errors.available}
                                            helperText={
                                                errors.available ? errors.available.message : ''
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="usedVacationCurrentYear"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Used vacation current year"
                                            type="number"
                                            value={value}
                                            margin="dense"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            error={!!errors.usedVacationCurrentYear}
                                            helperText={
                                                errors.usedVacationCurrentYear
                                                    ? errors.usedVacationCurrentYear.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="usedVacationPreviousYear"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Used vacation previous year"
                                            type="number"
                                            value={value}
                                            margin="dense"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            error={!!errors.usedVacationPreviousYear}
                                            helperText={
                                                errors.usedVacationPreviousYear
                                                    ? errors.usedVacationPreviousYear.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingData, loadingCreate, loadingUpdate)}
                            >
                                {employeeId ? 'Update' : 'Add'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

EmployeeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    employeeId: PropTypes.string,
};
