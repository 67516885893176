import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Dialog, DialogTitle, DialogContent, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { HTTP_METHODS } from 'constants';
import { AssigneeSelect } from './AssigneeSelect';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { DATE_FORMAT } from 'constants';
import { assignInventoryItemModalValidationSchema } from 'utils/validationSchemas';
import { format } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DATE_FORMAT_BACKEND } from 'constants';
import { DEFAULT_ASSIGNEE_INVENTORY_URL } from 'api/assigneeInventory';

const TITLE = 'Assign inventory item';

export const AssignInventoryItemModal = ({ open, onClose, onSuccess, itemId }) => {
    const topElementRef = useRef();
    const {
        data: dataAssign,
        error: errorAssign,
        clearData: clearDataAssign,
        sendRequest: assignInventoryItem,
        loading: loadingAssign,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [selectedAssignee, setSelectedAssignee] = useState(null);
    const {
        setValue,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(assignInventoryItemModalValidationSchema),
        defaultValues: {
            employeeId: null,
            teamId: null,
            startDate: null,
            endDate: null,
        },
    });

    useEffect(() => {
        if (selectedAssignee) {
            const [id, type] = selectedAssignee.value.split('_');
            if (type === 'team') {
                setValue('teamId', id);
                setValue('employeeId', null);
            } else if (type === 'user') {
                setValue('employeeId', id);
                setValue('teamId', null);
            }
        } else {
            setValue('teamId', null);
            setValue('employeeId', null);
        }
    }, [selectedAssignee]);

    useEffect(() => {
        clearDataAssign();

        if (errorAssign !== null) {
            setResponseMessage(errorAssign.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataAssign) {
            onSuccess(
                `Inventory item ${dataAssign.inventoryItem.name} assigned successfully.`,
                'success'
            );
            onClose();
        }
    }, [errorAssign, dataAssign, messageType]);

    const handleAssigneeSelection = (assignee) => {
        setSelectedAssignee(assignee);
    };

    const onSubmit = async (formData) => {
        if (itemId) {
            await assignInventoryItem({
                url: `${DEFAULT_ASSIGNEE_INVENTORY_URL}/${itemId}`,
                method: HTTP_METHODS.POST,
                data: {
                    ...formData,
                    startDate: format(formData.startDate, DATE_FORMAT_BACKEND),
                    endDate: formData.endDate && format(formData.endDate, DATE_FORMAT_BACKEND),
                },
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="dense" size="small">
                                <Controller
                                    control={control}
                                    name="teamIdOrEmployeeIdRequired"
                                    render={({ field: { onBlur } }) => (
                                        <AssigneeSelect
                                            selectedAssignee={selectedAssignee}
                                            handleAssigneeSelection={handleAssigneeSelection}
                                            handleFieldBlur={onBlur}
                                            error={!!(errors.employeeId || errors.teamId)}
                                            helperText={
                                                errors.employeeId
                                                    ? errors.employeeId.message
                                                    : errors.teamId
                                                    ? errors.teamId.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <DatePicker
                                                label="Start Date"
                                                value={value}
                                                onChange={onChange}
                                                format={DATE_FORMAT}
                                                slotProps={{
                                                    textField: {
                                                        onBlur: onBlur,
                                                        required: true,
                                                        margin: 'dense',
                                                        fullWidth: true,
                                                        error: !!errors.startDate,
                                                        helperText: errors.startDate
                                                            ? errors.startDate.message
                                                            : '',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="endDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <DatePicker
                                                label="End Date"
                                                value={value}
                                                onChange={onChange}
                                                format={DATE_FORMAT}
                                                slotProps={{
                                                    textField: {
                                                        onBlur: onBlur,
                                                        required: false,
                                                        margin: 'dense',
                                                        fullWidth: true,
                                                        error: !!errors.endDate,
                                                        helperText: errors.endDate
                                                            ? errors.endDate.message
                                                            : '',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>

                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingAssign)}
                            >
                                {itemId && 'Assign item'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

AssignInventoryItemModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    itemId: PropTypes.string.isRequired,
};
