import React, { useEffect, useState } from 'react';
import { AbsenceFilter } from 'components/AbsenceFilter';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Calendar } from 'components/Calendar';
import { CreateAbsenceModal } from 'components/CreateAbsenceModal';
import { AlertSnackBar } from 'components/AlertSnackBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import { theme } from 'utils/theme';
import useHttp from 'hooks/useHttp';
import { getEmployeeId } from 'utils/auth';
import { EmployeeSummaryCard } from 'components/EmployeeSummaryCard';
import { GET_EMPLOYEES_SUMMARY } from 'api/employeDashboard';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { COLORS, EMPLOYEE } from 'constants';
import { UPCOMING_HOLIDAYS_URL } from 'api/holiday';
import AddIcon from '@mui/icons-material/Add';

const MonthNavigation = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '160.7px',
    marginBottom: '7px',
}));

const Circle = styled('span')(({ color }) => ({
    borderRadius: '10px',
    pointerEvents: 'auto',
    width: '10px',
    marginTop: '5px',
    height: '10px',
    position: 'absolute',
    backgroundColor: color,
}));

const ButtonContainer = styled(Box)(() => ({
    position: 'sticky',
    zIndex: '90',
    bottom: '50px',
    display: 'flex',
    justifyContent: 'end',
}));

const Dashboard = () => {
    const [isCreateAbsenceModalOpen, setIsCreateAbsenceModalOpen] = useState(false);
    const [chips, setChips] = useState([]);
    const [dateRange, setDateRange] = useState(null);
    const [dateRangeMonth, setDateRangeMonth] = useState(null);
    const [createAbsence, setCreateAbsence] = useState(null);
    const [dateValidationError, setDateValidationError] = useState(null);
    const [snackbars, setSnackbars] = useState([]);
    const [employeeSummary, setEmployeeSummary] = useState(null);
    const [cancelAbsence, setCancelAbsence] = useState(null);
    const {
        data: employeeData,
        error: errorWhenGetEmployeeData,
        sendRequest: getEmployeeData,
    } = useHttp();
    const {
        data: upcomingHolidays,
        error: errorGetUpcomingHolidays,
        sendRequest: getUpcomingHolidays,
    } = useHttp();

    const employeeId = getEmployeeId();

    useEffect(() => {
        const handleErrors = (error, errorMessage) => {
            if (error !== null) {
                showSnackbar(errorMessage, 'error');
            }
        };

        handleErrors(errorGetUpcomingHolidays, 'Failed to load upcoming holidays.');

        handleErrors(errorWhenGetEmployeeData, 'Failed to load employee.');
    }, [errorWhenGetEmployeeData, errorGetUpcomingHolidays]);

    useEffect(() => {
        (async () => {
            await getUpcomingHolidays({
                url: `${UPCOMING_HOLIDAYS_URL}`,
            });
        })();
    }, []);

    useEffect(() => {
        if (createAbsence || cancelAbsence) {
            (async () => {
                await getEmployeeData({
                    url: `${GET_EMPLOYEES_SUMMARY}?EmployeeIds=${employeeId}`,
                });
            })();
        }
    }, [createAbsence, cancelAbsence]);

    useEffect(() => {
        if (employeeData) {
            setEmployeeSummary(employeeData[0].summaryForTooltip);
        }
    }, [employeeData]);

    const handleCreateAbsenceModalOpen = () => {
        setIsCreateAbsenceModalOpen(true);
    };

    const handleCreateAbsenceModalClose = () => {
        setIsCreateAbsenceModalOpen(false);
    };

    const handleCreateAbsenceSuccess = (id) => {
        setCreateAbsence(id);
    };

    const handleChipChange = (value) => {
        setChips(value);
    };

    const handleDateRangeChange = (value, validationError) => {
        if (validationError === null) {
            setDateValidationError(null);
            value[1].setHours(0, 0, 0, 0);
            setDateRange([value[0], value[1]]);
        } else {
            setDateValidationError(validationError);
        }
    };

    const navigateMonth = (direction, startDate, endDate) => {
        let newStartDate;
        if (direction === 'next') {
            newStartDate = startDate.setMonth(endDate.getMonth() + 1);
            if (endDate.getFullYear() > startDate.getFullYear()) {
                newStartDate = startDate.setFullYear(endDate.getFullYear());
            }
        }
        if (direction === 'previous') {
            newStartDate = startDate.setMonth(startDate.getMonth() - 1);
        }
        const newEndDate = endOfMonth(newStartDate);
        newEndDate.setDate(newEndDate.getDate());
        newEndDate.setHours(0, 0, 0, 0);
        setDateRangeMonth([startOfMonth(newStartDate), newEndDate]);
        setDateRange([startOfMonth(newStartDate), newEndDate]);
    };

    const handleDateTimeMonthChange = (direction) => {
        setDateValidationError(null);
        let startDate, endDate;

        if (dateRange !== null) {
            startDate = new Date(dateRange[0]);
            endDate = new Date(dateRange[1]);
        } else {
            startDate = startOfMonth(new Date());
            endDate = endOfMonth(new Date());
        }

        navigateMonth(direction, startDate, endDate);
    };

    const handleAbsenceCreateSuccess = (message, severity) => {
        showSnackbar(message, severity);
    };

    const handleCancelAbsence = (message) => {
        setCancelAbsence(message);
    };

    const handleError = (message, severity) => {
        showSnackbar(message, severity);
    };

    const handleEmployeeSummary = (employeeData) => {
        if (!employeeSummary && Object.keys(employeeData).length > 0) {
            setEmployeeSummary(employeeData);
        }
        if (employeeData === EMPLOYEE.failedToLoad) {
            setEmployeeSummary({
                vacationLeft: null,
                vacationPreviousYearUsed: null,
                vacationPreviousYearAvailable: null,
                vacationCurrentYearUsed: null,
                vacationCurrentYearAvailable: null,
            });
        }
    };

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: Math.random(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const holidayTypeColor = (type) => {
        const colorMapping = {
            Religious: COLORS.BLUE,
        };

        return colorMapping[type] || COLORS.GRAY;
    };

    const filteredHoliday =
        upcomingHolidays &&
        upcomingHolidays.sort(
            (a, b) => new Date(a.startDate).getDate() - new Date(b.startDate).getDate()
        );

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    backgroundColor: theme.palette.calendarBackground.main,
                    minHeight: '100vh',
                }}
            >
                <Box sx={{ display: 'flex', position: 'relative', height: '84.1px' }}>
                    <Box>
                        <EmployeeSummaryCard employeeSummary={employeeSummary} />
                    </Box>
                    <Box>
                        {filteredHoliday.length > 0 && (
                            <MessageBox messageType="information">
                                <Typography>Upcoming events</Typography>
                                {filteredHoliday.map((item) => (
                                    <div key={item.id}>
                                        <Circle color={holidayTypeColor(item.type)} />
                                        <Typography
                                            sx={{
                                                marginLeft: '20px',
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {format(new Date(item.startDate), 'dd.MM')}.
                                            {item.startDate !== item.endDate &&
                                                ` - ${format(
                                                    new Date(item.endDate),
                                                    'dd.MM'
                                                )}.`}{' '}
                                            {item.name}
                                        </Typography>
                                    </div>
                                ))}
                            </MessageBox>
                        )}
                    </Box>
                </Box>
                <Box sx={{ position: 'relative', marginTop: '20px' }}>
                    <AbsenceFilter
                        onChipChange={handleChipChange}
                        onDateRangeChanged={handleDateRangeChange}
                        onError={handleError}
                        dateRangeMonth={dateRangeMonth}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <MonthNavigation>
                        <IconButton
                            variant="switchingMonth"
                            onClick={() => handleDateTimeMonthChange('previous')}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            variant="switchingMonth"
                            onClick={() => handleDateTimeMonthChange('next')}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </MonthNavigation>
                </Box>
                {isCreateAbsenceModalOpen && (
                    <CreateAbsenceModal
                        open={isCreateAbsenceModalOpen}
                        onClose={handleCreateAbsenceModalClose}
                        onSuccess={handleAbsenceCreateSuccess}
                        absenceCreated={handleCreateAbsenceSuccess}
                    />
                )}
                <Calendar
                    chipsChangeValue={chips}
                    createAbsence={createAbsence}
                    dateChangeValue={dateRange}
                    dateValidationError={dateValidationError}
                    onError={handleError}
                    employeeSummary={handleEmployeeSummary}
                    cancelAbsenceMessage={handleCancelAbsence}
                />
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </Box>
            <ButtonContainer>
                <IconButton variant="createAbsence" onClick={handleCreateAbsenceModalOpen}>
                    <AddIcon sx={{ color: COLORS.WHITE, width: '30px', height: '30px' }} />
                </IconButton>
            </ButtonContainer>
        </>
    );
};

export default Dashboard;
