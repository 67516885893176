import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Box, TextField, Button, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { teamModalValidationSchema } from 'utils/validationSchemas';
import { DEFAULT_TEAM_URL } from 'api/team';
import { HTTP_METHODS } from 'constants';

export const TeamModal = ({ open, onClose, onSuccess, teamId }) => {
    const topElementRef = useRef();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: createTeam,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateTeam,
        loading: loadingUpdate,
    } = useHttp();
    const {
        data: teamData,
        error: errorGetTeam,
        sendRequest: getTeamData,
        loading: loadingData,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title] = useState(teamId ? 'Update team' : 'Add new team');
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(teamModalValidationSchema),
        defaultValues: {
            name: '',
        },
    });

    useEffect(() => {
        if (teamId) {
            (async () => {
                await getTeamData({
                    url: `${DEFAULT_TEAM_URL}/${teamId}`,
                });
            })();
        }
    }, [teamId]);

    useEffect(() => {
        if (errorGetTeam !== null) {
            setResponseMessage(errorGetTeam.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
    }, [errorGetTeam]);

    useEffect(() => {
        if (teamData) {
            reset(teamData);
        }
    }, [teamData, reset]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            onSuccess(`Team ${dataCreate.name} created successfully.`, 'success');
            onClose();
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(`Team ${dataUpdate.name} updated successfully.`, 'success');
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    const onSubmit = async (formData) => {
        if (teamId) {
            await updateTeam({
                url: `${DEFAULT_TEAM_URL}/${teamId}`,
                method: HTTP_METHODS.PUT,
                data: formData,
            });
        } else {
            await createTeam({
                url: `${DEFAULT_TEAM_URL}`,
                method: HTTP_METHODS.POST,
                data: formData,
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <Grid item xs={5}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        label="Name"
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        fullWidth
                                        required
                                        error={!!errors.name}
                                        helperText={errors.name ? errors.name.message : ''}
                                    />
                                )}
                            />

                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isLoading(loadingData, loadingCreate, loadingUpdate)}
                                >
                                    {teamId ? 'Update' : 'Add'}
                                </Button>
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

TeamModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    teamId: PropTypes.string,
};
