import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { theme } from 'utils/theme';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';

export const renderActiveCell = (params) => {
    return params.row.recurring ? (
        <CheckCircleIcon style={{ color: theme.palette.greenColor.main }} />
    ) : (
        ''
    );
};

export const renderEditButton = (params, setHolidayId, setIsHolidayModalOpen) => {
    const handleEditClick = () => {
        setHolidayId(params.row.id);
        setIsHolidayModalOpen(true);
    };

    return (
        <IconButtonWithTooltip
            onClick={handleEditClick}
            icon={<EditIcon />}
            tooltipText="Edit"
            color={theme.palette.primary.main}
        />
    );
};

export const renderDeleteButton = (params, setHolidayId, setIsDeleteHolidayModalOpen) => {
    const handleDeleteClick = () => {
        setHolidayId(params.row.id);
        setIsDeleteHolidayModalOpen(true);
    };

    return (
        <div>
            <IconButtonWithTooltip
                onClick={handleDeleteClick}
                icon={<DeleteForeverIcon />}
                tooltipText="Delete"
                color={theme.palette.redColor.main}
            />
        </div>
    );
};

export const holidayColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'name',
        headerName: 'Name',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'recurring',
        headerName: 'Recurring',
        type: 'boolean',
        flex: 0.3,
        minWidth: 50,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: renderActiveCell,
    },
    {
        field: 'edit',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderEditButton,
    },
    {
        field: 'delete',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderDeleteButton,
    },
];
