import React, { useEffect, useState } from 'react';
import { styled, Button, Box, Grid, TextField, InputAdornment } from '@mui/material';
import { format } from 'date-fns';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useHttp from 'hooks/useHttp';
import { DEFAULT_HOLIDAY_URL } from 'api/holiday';
import { DATE_FORMAT } from 'constants';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { HolidayModal } from 'components/HolidayModal';
import { DeleteHolidayModal } from 'components/DeleteHolidayModal';
import { AlertSnackBar } from 'components/AlertSnackBar';
import {
    holidayColumns,
    renderEditButton,
    renderDeleteButton,
    renderActiveCell,
} from 'utils/dataColumns/holidayColumns';

const StyledCells = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const Holidays = () => {
    const { data: holidaysData, error: errorGetHolidays, sendRequest: getHolidays } = useHttp();
    const [isHolidayModalOpen, setIsHolidayModalOpen] = useState(false);
    const [isDeleteHolidayModalOpen, setIsDeleteHolidayModalOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [holidayId, setHolidayId] = useState('');
    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);
    const [snackbars, setSnackbars] = useState([]);

    useEffect(() => {
        if (isGridDataRefreshNeeded) {
            getHolidays({
                url: DEFAULT_HOLIDAY_URL,
            });
            setIsGridDataRefreshNeeded(false);
        }
    }, [isGridDataRefreshNeeded]);

    useEffect(() => {
        if (errorGetHolidays !== null) {
            showSnackbar('Failed to load holidays.', 'error');
        }
    }, [errorGetHolidays]);

    const holidayTableData = [
        ...(holidaysData || []).map((holiday) => ({
            id: holiday.id,
            name: holiday.name,
            startDate: format(
                holiday.startDate ? new Date(holiday.startDate).setHours(0, 0, 0, 0) : null,
                DATE_FORMAT
            ),
            endDate: format(
                holiday.endDate ? new Date(holiday.endDate).setHours(0, 0, 0, 0) : null,
                DATE_FORMAT
            ),
            recurring: holiday.recurring,
            type: holiday.type,
        })),
    ];

    const filteredData = holidayTableData.filter((holiday) => {
        return holiday.name.toLowerCase().includes(filter.toLowerCase());
    });

    const columnsForHoliday = holidayColumns.map((column) => {
        if (column.field === 'edit') {
            return {
                ...column,
                renderCell: (params) =>
                    renderEditButton(params, setHolidayId, setIsHolidayModalOpen),
            };
        } else if (column.field === 'delete') {
            return {
                ...column,
                renderCell: (params) =>
                    renderDeleteButton(params, setHolidayId, setIsDeleteHolidayModalOpen),
            };
        } else if (column.field === 'recurring') {
            return {
                ...column,
                renderCell: renderActiveCell,
            };
        }
        return column;
    });

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleAddNewHolidayClick = () => {
        setHolidayId('');
        setIsHolidayModalOpen(true);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleHolidayModalClose = () => {
        setIsHolidayModalOpen(false);
    };

    const handleDeleteHolidayModalClose = () => {
        setIsDeleteHolidayModalOpen(false);
    };

    const handleCreateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleDeleteSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const renderContent = () => {
        return (
            <>
                <Grid item xs={5} marginBottom="16px">
                    <StyledCells>
                        <h1>Holidays</h1>
                        <Button onClick={handleAddNewHolidayClick} variant="contained">
                            + Add new holiday
                        </Button>
                    </StyledCells>
                </Grid>
                <Grid item xs={5} marginBottom="16px">
                    <StyledCells>
                        <TextField
                            placeholder="Search"
                            className="subvariant-search"
                            size="small"
                            value={filter}
                            onChange={handleFilterChange}
                            focused
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {filter ? (
                                            <IconButton
                                                onClick={() => setFilter('')}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        ) : (
                                            <SearchIcon style={{ color: 'black' }} />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </StyledCells>
                </Grid>
                {isHolidayModalOpen && (
                    <HolidayModal
                        open={isHolidayModalOpen}
                        onClose={handleHolidayModalClose}
                        onSuccess={handleCreateSuccess}
                        holidayId={holidayId}
                    />
                )}
                {isDeleteHolidayModalOpen && (
                    <DeleteHolidayModal
                        open={isDeleteHolidayModalOpen}
                        onClose={handleDeleteHolidayModalClose}
                        onSuccess={handleDeleteSuccess}
                        holidayId={holidayId}
                    />
                )}
                <div style={{ height: '100%', width: '100%' }}>
                    <CommonDataGrid rows={filteredData} columns={columnsForHoliday} />
                </div>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {renderContent()}
        </Box>
    );
};

export default Holidays;
