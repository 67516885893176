import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import useHttp from 'hooks/useHttp';
import { DEFAULT_ANNUAL_LEAVE_DECISION_URL } from 'api/annualLeaveDecision';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { AnnualLeaveDecisionUploadModal } from 'components/AnnualLeaveDecisionUploadModal';
import { FIRST_YEAR } from 'constants';
import {
    generateAnnualLeaveDecisionColumns,
    renderUploadButton,
} from 'utils/annualLeaveDecisionColumns';

export const AnnualLeaveDecision = ({
    filter,
    showInactive,
    renderSearchTextField,
    passedEmployeeId,
}) => {
    const currentDate = new Date();
    const [snackbars, setSnackbars] = useState([]);
    const [year, setYear] = useState(currentDate.getFullYear());
    const [prevYearDisabled, setPrevYearDisabled] = useState(false);
    const [nextYearDisabled, setNextYearDisabled] = useState(true);
    const [annualLeaveDecisionId, setAnnualLeaveDecisionId] = useState('');
    const [isAnnualLeaveDecisionUploadModalOpen, setIsAnnualLeaveDecisionUploadModalOpen] =
        useState(false);
    const [
        isGridAnnualLeaveDecisionDataRefreshNeeded,
        setIsGridAnnualLeaveDecisionDataRefreshNeeded,
    ] = useState(true);
    const {
        data: annualLeaveDecisionData,
        error: errorGetAnnualLeaveDecisionData,
        sendRequest: getAnnualLeaveDecisionsForYear,
    } = useHttp();

    const StyledCells = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'space-between',
    }));

    useEffect(() => {
        const fetchData = async () => {
            if (isGridAnnualLeaveDecisionDataRefreshNeeded) {
                await getAnnualLeaveDecisionsForYear({
                    url: `${DEFAULT_ANNUAL_LEAVE_DECISION_URL}/employee${
                        passedEmployeeId ? `/${passedEmployeeId}` : ''
                    }?year=${year}`,
                });
                setIsGridAnnualLeaveDecisionDataRefreshNeeded(false);
            }
        };
        fetchData();
    }, [isGridAnnualLeaveDecisionDataRefreshNeeded, year]);

    useEffect(() => {
        const handleErrors = (error, errorMessage) => {
            if (error !== null) {
                showSnackbar(errorMessage, 'error');
            }
        };

        handleErrors(
            errorGetAnnualLeaveDecisionData,
            'Failed to load annual leave decisions data.'
        );
    }, [errorGetAnnualLeaveDecisionData]);

    const viewOnly = passedEmployeeId ? true : false;
    const columnsForAnnualLeaveDecision = generateAnnualLeaveDecisionColumns(viewOnly);

    const allAnnualLeaveDecisionsTableData = [
        ...(annualLeaveDecisionData || []).map((annualLeaveDecision) => ({
            id: annualLeaveDecision.id,
            employeeName: annualLeaveDecision.employeeName,
            version: annualLeaveDecision.version,
            filledDocumentLink: annualLeaveDecision.filledDocumentLink,
            signedDocumentLink: annualLeaveDecision.signedDocumentLink,
            status: `${annualLeaveDecision.status}`,
        })),
    ];

    const filteredAnnualLeaveDecisionsData = allAnnualLeaveDecisionsTableData.filter(
        (annualLeaveDecision) => {
            return (
                (showInactive
                    ? annualLeaveDecision.status === 'Canceled'
                    : annualLeaveDecision.status != 'Canceled') &&
                (annualLeaveDecision.employeeName.toLowerCase().includes(filter.toLowerCase()) ||
                    annualLeaveDecision.status.toLowerCase().includes(filter.toLowerCase()))
            );
        }
    );

    const columnsForAnnualLeaveDecision2 = columnsForAnnualLeaveDecision.map((column) => {
        if (column.field === 'upload') {
            return {
                ...column,
                renderCell: (params) =>
                    renderUploadButton(
                        params,
                        setAnnualLeaveDecisionId,
                        setIsAnnualLeaveDecisionUploadModalOpen,
                        viewOnly
                    ),
            };
        }
        return column;
    });

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleAnnualLeaveDecisionUploadModalClose = () => {
        setIsAnnualLeaveDecisionUploadModalOpen(false);
    };

    const handleAnnualLeaveDecisionUploadModalSuccess = () => {
        setIsGridAnnualLeaveDecisionDataRefreshNeeded(true);
    };

    const handleYearChange = (direction) => {
        const newYear =
            direction === 'next' ? year + 1 : direction === 'previous' ? year - 1 : year;

        const prevYearDisabled = newYear === FIRST_YEAR;
        const nextYearDisabled = newYear === currentDate.getFullYear();

        setYear(newYear);
        setPrevYearDisabled(prevYearDisabled);
        setNextYearDisabled(nextYearDisabled);

        setIsGridAnnualLeaveDecisionDataRefreshNeeded(true);
    };

    const renderContent = () => {
        return (
            <>
                <Grid item xs={5} marginBottom="16px">
                    <StyledCells>
                        <h1>Annual leave decisions</h1>
                    </StyledCells>
                </Grid>
                {renderSearchTextField('Show canceled annual leave decisions')}
                <div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                        <Box
                            style={{
                                display: 'flex',
                                marginLeft: '16px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 16,
                                }}
                            >
                                <IconButton
                                    variant="switchingMonth"
                                    onClick={() => handleYearChange('previous')}
                                    disabled={prevYearDisabled}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography
                                    color="secondary"
                                    style={{
                                        marginLeft: 16,
                                        marginRight: 16,
                                        fontSize: 24,
                                    }}
                                >
                                    {year}
                                </Typography>
                                <IconButton
                                    variant="switchingMonth"
                                    onClick={() => handleYearChange('next')}
                                    disabled={nextYearDisabled}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>
                        </Box>

                        <Box display="flex-start" width="100%">
                            <CommonDataGrid
                                rows={filteredAnnualLeaveDecisionsData}
                                columns={columnsForAnnualLeaveDecision2.filter(
                                    (column) =>
                                        column.field !== 'employeeId' && column.field !== 'year'
                                )}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
                {isAnnualLeaveDecisionUploadModalOpen && (
                    <AnnualLeaveDecisionUploadModal
                        open={isAnnualLeaveDecisionUploadModalOpen}
                        onClose={handleAnnualLeaveDecisionUploadModalClose}
                        onSuccess={handleAnnualLeaveDecisionUploadModalSuccess}
                        annualLeaveDecisionId={annualLeaveDecisionId}
                    />
                )}
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return <>{renderContent()}</>;
};

AnnualLeaveDecision.propTypes = {
    filter: PropTypes.string,
    showInactive: PropTypes.bool.isRequired,
    renderSearchTextField: PropTypes.func,
    passedEmployeeId: PropTypes.string,
};
