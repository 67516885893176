import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { EmployeeModal } from 'components/EmployeeModal';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { DEFAULT_ROLE_URL } from 'api/role';
import { HTTP_METHODS, DEFAULT_ROLE_NAME } from 'constants';

const TITLE = 'Reactivate employee';

export const ReactivateEmployeeModal = ({ open, onClose, onSuccess, employeeId }) => {
    const topElementRef = useRef();
    const {
        data: dataReactivate,
        error: errorReactivate,
        clearData: clearDataReactivate,
        sendRequest: reactivateEmployee,
        loading: loadingReactivate,
    } = useHttp();
    const {
        data: employeeData,
        error: errorGetEmployee,
        sendRequest: getEmployeeData,
        loading: loadingData,
    } = useHttp();
    const { data: rolesData, error: errorGetRoles, sendRequest: getRoles } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [employee, setEmployee] = useState(null);
    const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            await getEmployeeData({
                url: `${DEFAULT_EMPLOYEE_URL}/${employeeId}`,
            });
        })();
    }, [employeeId]);

    useEffect(() => {
        if (errorGetEmployee !== null || errorGetRoles != null) {
            setResponseMessage(
                errorGetEmployee.response?.data?.message ||
                    errorGetRoles.response?.data?.message ||
                    'An error occurred'
            );
            setMessageType('error');
        }
    }, [errorGetEmployee, errorGetRoles]);

    useEffect(() => {
        if (employeeData) {
            setEmployee(employeeData);
        }
    }, [employeeData]);

    useEffect(() => {
        clearDataReactivate();

        if (errorReactivate !== null) {
            setResponseMessage(errorReactivate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataReactivate) {
            setResponseMessage('Employee reactivated successfully.');
            setMessageType('success');
            onSuccess();
        }
    }, [errorReactivate, dataReactivate, messageType]);

    useEffect(() => {
        (async () => {
            await getRoles({
                url: DEFAULT_ROLE_URL,
            });
        })();
    }, []);

    const handleEditConfirmation = () => {
        setIsEmployeeModalOpen(true);
    };

    const handleEmployeeModalClose = () => {
        setIsEmployeeModalOpen(false);
        onClose();
    };

    const handleUpdateSuccess = () => {
        onSuccess('Employee updated successfully.', 'success');
        onClose();
    };

    const handleReactivateConfirmation = async () => {
        const requestBody = {
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            hireDate: employee.hireDate,
            terminationDate: null,
            roleIds: (rolesData || [])
                .filter((role) => role.name === DEFAULT_ROLE_NAME)
                .map((role) => role.id),
            available: employee.available,
            active: true,
            usedVacationCurrentYear: employee.usedVacationCurrentYear,
            usedVacationPreviousYear: employee.usedVacationPreviousYear,
            category: employee.category,
        };

        await reactivateEmployee({
            url: `${DEFAULT_EMPLOYEE_URL}/${employeeId}`,
            method: HTTP_METHODS.PUT,
            data: requestBody,
        });

        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    {messageType !== 'success' && (
                        <Grid item xs={5}>
                            <Typography>
                                Are you sure you want to reactivate the employee?
                            </Typography>
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                    disabled={isLoading(loadingData, loadingReactivate)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleReactivateConfirmation}
                                    variant="contained"
                                    color="error"
                                    disabled={isLoading(loadingData, loadingReactivate)}
                                >
                                    Reactivate
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    {messageType === 'success' && (
                        <Grid item xs={5}>
                            <Typography>Do you want to edit employee?</Typography>
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                >
                                    Cancel
                                </Button>
                                <Button onClick={handleEditConfirmation} variant="contained">
                                    Edit
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    {isEmployeeModalOpen && (
                        <EmployeeModal
                            open={isEmployeeModalOpen}
                            onClose={handleEmployeeModalClose}
                            onSuccess={handleUpdateSuccess}
                            employeeId={employeeId}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

ReactivateEmployeeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    employeeId: PropTypes.string.isRequired,
};
