import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MonthCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { Box, Button, Grid, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { HTTP_METHODS } from 'constants';
import { GENERATE_REPORT_URL } from 'api/report';

const TITLE = 'Generate report and send notification';

export const CreateAbsenceReportModal = ({ open, onClose, onSuccess }) => {
    const topElementRef = useRef();
    const {
        data,
        error,
        clearData,
        sendRequest: createReportAndSendNotification,
        loading,
    } = useHttp();
    const [messageType, setMessageType] = useState();
    const [responseMessage, setResponseMessage] = useState(
        'After selecting a month and clicking the "Add" button, absence report will be generated and notification will be sent.'
    );
    const currentDate = new Date();
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    const [formData, setFormData] = useState({
        Period: date,
    });

    useEffect(() => {
        clearData();

        if (error !== null) {
            setResponseMessage(error.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (data) {
            setResponseMessage(data);
            setMessageType('success');
            onSuccess();
        }
    }, [error, data, messageType, onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const month = formData.Period.getMonth() + 1;
        const year = formData.Period.getFullYear();

        await createReportAndSendNotification({
            url: `${GENERATE_REPORT_URL}/?year=${year}&month=${month}`,
            method: HTTP_METHODS.POST,
        });
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>
                            {messageType === 'success' ? (
                                <>
                                    <Typography variant="body1">
                                        You can download the report from the following link:
                                    </Typography>
                                    <Link href={responseMessage}>{responseMessage}</Link>
                                </>
                            ) : (
                                responseMessage
                            )}
                        </MessageBox>
                    )}
                    {messageType !== 'success' && (
                        <Grid item xs={12}>
                            <form onSubmit={handleSubmit}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={enGB}
                                >
                                    <MonthCalendar
                                        value={formData.Period}
                                        onChange={(newPeriod) => setFormData({ Period: newPeriod })}
                                        monthsPerRow={4}
                                        minDate={minDate}
                                        maxDate={date}
                                    />
                                </LocalizationProvider>
                                <Box mt={2} display="flex" justifyContent="flex-end">
                                    <Button type="submit" variant="contained" disabled={loading}>
                                        ADD
                                    </Button>
                                </Box>
                            </form>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

CreateAbsenceReportModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};
