import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { theme } from 'utils/theme';
import { isLoading } from 'utils/misc';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';

export const renderRemoveButton = (
    params,
    removeEmployeeFromTeam,
    loadingEmployeeTeamsData,
    loadingTeamsData,
    loadingCreate,
    loadingDelete
) => {
    const handleRemoveClick = () => {
        removeEmployeeFromTeam(params.row.id);
    };
    return (
        !isLoading(loadingEmployeeTeamsData, loadingTeamsData, loadingCreate, loadingDelete) && (
            <IconButtonWithTooltip
                onClick={handleRemoveClick}
                icon={<ClearIcon />}
                tooltipText="Remove from team"
                color={theme.palette.redColor.main}
            />
        )
    );
};

export const employeeTeamsColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'name',
        headerName: 'Teams',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'remove',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderRemoveButton,
    },
];
