import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { inventoryTypeModalValidationSchema } from 'utils/validationSchemas';
import { HTTP_METHODS } from 'constants';
import { DEFAULT_INVENTORY_TYPE_URL } from 'api/inventoryType';

export const InventoryTypeModal = ({ open, onClose, onSuccess, typeId }) => {
    const topElementRef = useRef();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: createInventoryType,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateInventoryType,
        loading: loadingUpdate,
    } = useHttp();
    const {
        data: inventoryType,
        error: errorGetInventoryType,
        sendRequest: getInventoryTypeData,
        loading: loadingData,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title] = useState(typeId ? 'Update inventory type' : 'Add new inventory type');
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(inventoryTypeModalValidationSchema),
        defaultValues: {
            name: '',
            active: true,
            isSerialNumberRequired: true,
        },
    });

    useEffect(() => {
        if (typeId) {
            (async () => {
                await getInventoryTypeData({
                    url: `${DEFAULT_INVENTORY_TYPE_URL}/${typeId}`,
                });
            })();
        }
    }, [typeId]);

    useEffect(() => {
        if (errorGetInventoryType !== null) {
            setResponseMessage(
                errorGetInventoryType.response?.data?.message || 'An error occurred'
            );
            setMessageType('error');
        }
    }, [errorGetInventoryType]);

    useEffect(() => {
        if (inventoryType) {
            reset({
                ...inventoryType,
                active: true,
            });
        }
    }, [inventoryType, reset]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            onSuccess(`Inventory type ${dataCreate.name} created successfully.`, 'success');
            onClose();
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(`Inventory type ${dataUpdate.name} updated successfully.`, 'success');
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    const onSubmit = async (formData) => {
        const requestBody = {
            ...formData,
            active: true,
        };

        if (typeId) {
            await updateInventoryType({
                url: `${DEFAULT_INVENTORY_TYPE_URL}/${typeId}`,
                method: HTTP_METHODS.PUT,
                data: requestBody,
            });
        } else {
            await createInventoryType({
                url: `${DEFAULT_INVENTORY_TYPE_URL}`,
                method: HTTP_METHODS.POST,
                data: requestBody,
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        label="Name"
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        fullWidth
                                        required
                                        error={!!errors.name}
                                        helperText={errors.name ? errors.name.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Controller
                            control={control}
                            name="isSerialNumberRequired"
                            render={({ field: { onChange, value } }) => (
                                <Checkbox checked={value} onChange={onChange} />
                            )}
                        />
                        <span>Is serial number required for this inventory type?</span>
                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingData, loadingCreate, loadingUpdate)}
                            >
                                {typeId ? 'Update' : 'Add'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

InventoryTypeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    typeId: PropTypes.string,
};
