import React, { useCallback, useEffect, useState } from 'react';
import { Box, TextField, InputAdornment, Tabs, Tab, Grid, FormControlLabel, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InventoryType from './InventoryType';
import InventoryItem from './InventoryItem';

const Inventory = () => {
    const [filter, setFilter] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [showInactive, setShowInactive] = useState(false);

    useEffect(() => {
        setFilter('');
        setShowInactive(false)
    }, [selectedTab]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleShowInactiveChange = (event) => {
        setShowInactive(event.target.checked);
    };

    const renderSearchTextField = (label) => (
        <Grid item xs={5} marginBottom="16px" marginTop="16px">
            <TextField
                placeholder="Search"
                className="subvariant-search"
                size="small"
                value={filter}
                onChange={handleFilterChange}
                focused
                style={{ marginRight: '16px' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {filter ? (
                                <IconButton onClick={() => setFilter('')} style={{ cursor: 'pointer' }}>
                                    <ClearIcon />
                                </IconButton>
                            ) : (
                                <SearchIcon style={{ color: 'black' }} />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <FormControlLabel
                control={
                    <Checkbox checked={showInactive} onChange={handleShowInactiveChange} />
                }
                label={label}
            />
        </Grid>
    );

    const renderContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={1} >
                    <Box height="100%" display="flex" alignItems="top" justifyContent="right">
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            orientation="vertical"
                            variant="scrollable"
                        >
                            <Tab label="Inventory items" />
                            <Tab label="Inventory types" />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={11} paddingRight={'24px'}>
                    <Box>
                        {selectedTab === 0 && (
                            <InventoryItem
                                renderSearchTextField={renderSearchTextField}
                                showInactive={showInactive}
                                filter={filter}
                            />
                        )}
                        {selectedTab === 1 && (
                            <InventoryType
                                renderSearchTextField={renderSearchTextField}
                                showInactive={showInactive}
                                filter={filter}
                            />
                        )}

                    </Box>
                </Grid>
            </Grid>
        );
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {renderContent()}
        </Box>
    );
};

export default Inventory;
