import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
    Box,
    TextField,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Tabs,
    Tab,
    Grid,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useHttp from 'hooks/useHttp';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { AbsenceQuotaCardDetailed } from 'components/AbsenceQuotaCardDetailed';
import { AbsencesQuotaCardUsed } from 'components/AbsencesQuotaCardUsed';
import { TimeSheet } from 'components/TimeSheet';
import { AnnualLeaveDecision } from 'components/AnnualLeaveDecision';
import { DATE_FORMAT, DATE_FORMAT_BACKEND, DETAILED_ABSENCE_TYPE_NAMES } from 'constants';
import { GET_ABSENCES_URL } from 'api/absence';
import { GET_EMPLOYEE_ABSENCES } from 'api/employeDashboard';
import { absenceColumns } from 'utils/dataColumns/absenceColumns';
import GeneralData from 'pages/Profile/GeneralData';

export const AbsencesSummaryModal = ({ open, onClose, employeeId, employeeName }) => {
    const {
        data: allAbsencesData,
        error: errorGetAllAbsences,
        sendRequest: getAllAbsences,
    } = useHttp();
    const {
        data: absencesAndQuotasCurrentYear,
        error: errorGetAbsencesAndQuotasCurrentYear,
        sendRequest: getAbsencesAndQuotasCurrentYear,
    } = useHttp();
    const [filter, setFilter] = useState('');
    const [snackbars, setSnackbars] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [showInactive, setShowInactive] = useState(false);
    const currentDate = new Date();
    const firstDateInTheCurrentYear = format(
        new Date(currentDate.getFullYear(), 0, 1),
        DATE_FORMAT_BACKEND
    );
    const lastDateInTheNextYear = format(
        new Date(currentDate.getFullYear() + 1, 11, 31),
        DATE_FORMAT_BACKEND
    );

    useEffect(() => {
        if (employeeId && selectedTab == 0) {
            (async () => {
                await getAbsencesAndQuotasCurrentYear({
                    url: `${GET_EMPLOYEE_ABSENCES}?StartDate=${firstDateInTheCurrentYear}&EndDate=${lastDateInTheNextYear}&EmployeeIds=${employeeId}`,
                });
            })();
        }
    }, [employeeId, selectedTab]);

    useEffect(() => {
        if (employeeId && selectedTab == 1) {
            (async () => {
                await getAllAbsences({
                    url: `${GET_ABSENCES_URL}/${employeeId}`,
                });
            })();
        }
    }, [employeeId, selectedTab]);

    useEffect(() => {
        if (errorGetAllAbsences !== null || errorGetAbsencesAndQuotasCurrentYear != null) {
            showSnackbar('Failed to load absences.', 'error');
        }
    }, [errorGetAllAbsences, errorGetAbsencesAndQuotasCurrentYear]);

    useEffect(() => {
        setFilter('');
        setShowInactive(false);
    }, [selectedTab]);

    const allAbsencesTableData = [
        ...(allAbsencesData || []).map((absence) => ({
            id: absence.id,
            absenceType: absence.absenceTypeName,
            startDate: format(
                absence.startDate ? new Date(absence.startDate).setHours(0, 0, 0, 0) : null,
                DATE_FORMAT
            ),
            endDate: format(
                absence.endDate ? new Date(absence.endDate).setHours(0, 0, 0, 0) : null,
                DATE_FORMAT
            ),
            notes: absence.reason,
            status: absence.status,
        })),
    ];

    const absencesAndQuotasCurrentYearDataTable = [
        ...(absencesAndQuotasCurrentYear || []).flatMap((employee) =>
            employee.absenceTypes.flatMap((absenceType) =>
                absenceType.absencesSharedModels.map((absence) => ({
                    id: absence.absenceId,
                    absenceType: absenceType.name,
                    startDate: new Date(absence.startDate),
                    endDate: new Date(absence.endDate),
                    notes: absence.reason,
                    status: absence.status,
                }))
            )
        ),
    ];

    absencesAndQuotasCurrentYearDataTable.sort((a, b) => b.startDate - a.startDate);

    absencesAndQuotasCurrentYearDataTable.forEach((item) => {
        item.startDate = format(item.startDate, DATE_FORMAT);
        item.endDate = format(item.endDate, DATE_FORMAT);
    });

    const filteredAllAbsencesData = allAbsencesTableData.filter((absence) => {
        return (
            absence.absenceType.toLowerCase().includes(filter.toLowerCase()) ||
            absence.status.toLowerCase().includes(filter.toLowerCase())
        );
    });

    const filteredCurrentYearAbsencesData = absencesAndQuotasCurrentYearDataTable.filter(
        (absence) => {
            return (
                absence.absenceType.toLowerCase().includes(filter.toLowerCase()) ||
                absence.status.toLowerCase().includes(filter.toLowerCase())
            );
        }
    );

    const columns = absenceColumns;

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleShowInactiveChange = (event) => {
        setShowInactive(event.target.checked);
    };

    const absenceQuotasData = [
        ...(absencesAndQuotasCurrentYear || []).flatMap((employee) =>
            employee.absenceTypes
                .filter((absenceType) => DETAILED_ABSENCE_TYPE_NAMES.includes(absenceType.name))
                .flatMap((absenceType) =>
                    absenceType.absenceQuotaForDashboardModel.map((quota) => {
                        const updatedName =
                            absenceType.name === 'Vacation'
                                ? `${absenceType.name} ${new Date(quota.startDate).getFullYear()}`
                                : absenceType.name;

                        return (
                            <AbsenceQuotaCardDetailed
                                key={quota.absenceQuotaId}
                                title={updatedName}
                                available={quota.available}
                                used={quota.used}
                                left={quota.left}
                                style={{ flex: '1 0 25%' }}
                                color={absenceType.color}
                            />
                        );
                    })
                )
        ),
    ];

    const absenceQuotaCardsDetailed = <>{absenceQuotasData}</>;

    const keyValueData = {};

    if (Array.isArray(absencesAndQuotasCurrentYear) && absencesAndQuotasCurrentYear.length > 0) {
        const employee = absencesAndQuotasCurrentYear[0];
        if (employee && Array.isArray(employee.absenceTypes)) {
            employee.absenceTypes.forEach((absenceType) => {
                if (absenceType && !DETAILED_ABSENCE_TYPE_NAMES.includes(absenceType.name)) {
                    if (Array.isArray(absenceType.absenceQuotaForDashboardModel)) {
                        keyValueData[absenceType.name] =
                            absenceType.absenceQuotaForDashboardModel.reduce(
                                (acc, quota) => acc + quota.used,
                                0
                            );
                    }
                }
            });
        }
    }

    const absenceQuotaCardUsed = (
        <AbsencesQuotaCardUsed title="Other leaves (used days)" data={keyValueData} />
    );

    const renderSearchTextField = (label) => (
        <Grid item xs={6} marginTop="16px" marginBottom="16px">
            <TextField
                placeholder="Search"
                className="subvariant-search"
                size="small"
                value={filter}
                onChange={handleFilterChange}
                focused
                style={{ marginRight: '16px' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {filter ? (
                                <IconButton
                                    onClick={() => setFilter('')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ) : (
                                <SearchIcon style={{ color: 'black' }} />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {label && (
                <FormControlLabel
                    control={
                        <Checkbox checked={showInactive} onChange={handleShowInactiveChange} />
                    }
                    label={label}
                />
            )}
        </Grid>
    );

    const renderContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={1} style={{ height: '100vh' }}>
                    <Box height="100%" display="flex" alignItems="top" justifyContent="right">
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            orientation="vertical"
                            variant="scrollable"
                        >
                            <Tab label="Current" />
                            <Tab label="History" />
                            <Tab label="Flex hours" />
                            <Tab label="Employee information" />
                            <Tab label="Annual leave decisions" />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={11} paddingRight={'24px'}>
                    <Box>
                        {selectedTab === 0 && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                                <>
                                    {absenceQuotaCardsDetailed}
                                    {absenceQuotaCardUsed}
                                </>
                                <Box display="flex-start" width="100%">
                                    {renderSearchTextField()}
                                </Box>
                                <Box display="flex-start" width="100%">
                                    <CommonDataGrid
                                        rows={filteredCurrentYearAbsencesData}
                                        columns={columns}
                                    />
                                </Box>
                            </div>
                        )}
                        {selectedTab === 1 && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                                {renderSearchTextField()}
                                <Box display="flex-start" width="100%">
                                    <CommonDataGrid
                                        rows={filteredAllAbsencesData}
                                        columns={columns}
                                    />
                                </Box>
                            </div>
                        )}
                        {selectedTab === 2 && <TimeSheet passedEmployeeId={employeeId} />}
                        {selectedTab === 3 && (
                            <GeneralData selectedEmployeeId={employeeId} key={employeeId} />
                        )}
                        {selectedTab === 4 && (
                            <AnnualLeaveDecision
                                passedEmployeeId={employeeId}
                                renderSearchTextField={renderSearchTextField}
                                filter={filter}
                                showInactive={showInactive}
                            />
                        )}
                    </Box>
                </Grid>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </Grid>
        );
    };

    return (
        <Box display="flex-center" width="100%">
            <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
                <DialogTitle style={{ textTransform: 'none' }}>
                    {employeeName} - Absences overview
                    <Button onClick={onClose} variant="CloseButton">
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <DialogContent>{renderContent()}</DialogContent>
            </Dialog>
        </Box>
    );
};

AbsencesSummaryModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    employeeId: PropTypes.string,
    employeeName: PropTypes.string,
    items: PropTypes.array,
};
