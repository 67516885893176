import React from 'react';
import { Box } from '@mui/material';

const NotFound = () => {
    return (
        <Box>
            <main>
                <h1>Could not find this page!</h1>
            </main>
        </Box>
    );
};

export default NotFound;
