import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'contexts/auth-context';
import { Routes } from "components/Routes";

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes />
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
