import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { HTTP_METHODS } from 'constants';
import { INVENTORY_ITEM_STATE } from 'constants';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { DEFAULT_INVENTORY_ITEM_URL } from 'api/inventoryItem';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { employeeInventoryTypeModalValidationSchema } from 'utils/validationSchemas';

export const EmpoyeeInventoryEditModal = ({ open, onClose, onSuccess, assigneeInventoryId, inventoryId }) => {
    const topElementRef = useRef();
    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateInventoryItem,
        loading: loadingUpdate,
    } = useHttp();

    const {
        data: inventoryItem,
        error: errorGetInventoryItem,
        sendRequest: getInventoryItem,
        loading: loadingData
    } = useHttp();

    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title, setTitle] = useState(assigneeInventoryId ? 'Update inventory item' : 'Add new inventory item');

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(employeeInventoryTypeModalValidationSchema),
        defaultValues: {
            state: INVENTORY_ITEM_STATE.good,
            description: '',
        },
    });

    useEffect(() => {
        (async () => {
            await getInventoryItem({
                url: `${DEFAULT_INVENTORY_ITEM_URL}/${inventoryId}`,
            })
        })();
    }, [inventoryId]);

    useEffect(() => {
        if (errorGetInventoryItem != null) {
            setResponseMessage(
                errorGetInventoryItem.response?.data?.message ||
                'An error occurred'
            );
            setMessageType('error');
        }
    }, [errorGetInventoryItem]);

    useEffect(() => {
        if (inventoryItem) {
            reset(inventoryItem);
        }
    }, [inventoryItem, reset]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(`Inventory item updated successfully.`, 'success');
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    const onSubmit = async (formData) => {
        if (assigneeInventoryId) {
            await updateInventoryItem({
                url: `${DEFAULT_EMPLOYEE_URL}/assign/${assigneeInventoryId}`,
                method: HTTP_METHODS.PUT,
                data: formData,
            });
        }

        scrollIntoView(topElementRef);

    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>State</InputLabel>
                                    <Controller
                                        control={control}
                                        name="state"
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                label="State"
                                                size="small"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                <MenuItem value={INVENTORY_ITEM_STATE.good}>
                                                    {INVENTORY_ITEM_STATE.good}
                                                </MenuItem>
                                                <MenuItem value={INVENTORY_ITEM_STATE.average}>
                                                    {INVENTORY_ITEM_STATE.average}
                                                </MenuItem>
                                                <MenuItem value={INVENTORY_ITEM_STATE.bad}>
                                                    {INVENTORY_ITEM_STATE.bad}
                                                </MenuItem>
                                                <MenuItem value={INVENTORY_ITEM_STATE.critical}>
                                                    {INVENTORY_ITEM_STATE.critical}
                                                </MenuItem>
                                                <MenuItem value={INVENTORY_ITEM_STATE.unknown}>
                                                    {INVENTORY_ITEM_STATE.unknown}
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} >
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Description"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            error={!!errors.description}
                                            helperText={errors.description ? errors.description.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingData, loadingUpdate)}
                            >
                                {assigneeInventoryId ? 'Update' : 'Add'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

EmpoyeeInventoryEditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    assigneeInventoryId: PropTypes.string,
    inventoryId: PropTypes.string
};
