import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AppBar, Avatar, Menu, MenuItem, Toolbar, Typography, Box } from '@mui/material';
import { googleLogout } from '@react-oauth/google';
import AuthContext from 'contexts/auth-context';
import { COLORS } from 'constants';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Groups from '@mui/icons-material/Groups';
import Event from '@mui/icons-material/Event';
import ListAlt from '@mui/icons-material/ListAlt';
import softhouseLogo from 'assets/images/softhouseLogo.png';
import Image from 'components/Image';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import { AuthGuard } from 'components/AuthGuard';
import { Person } from '@mui/icons-material';

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
});

const UserBox = styled('div')(() => ({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    cursor: 'pointer',
}));

const StyledAppBar = styled(AppBar)(() => ({
    backgroundColor: COLORS.WHITE,
    position: 'relative',
    boxShadow: '0px -15px 22px 4px rgba(17, 17, 17, 0.5)',
}));

const NavBox = styled('div')(() => ({
    display: 'flex',
    gap: '30px',
    alignItems: 'center',
}));

const TopBar = () => {
    const authContext = useContext(AuthContext);
    const [currentEmployee, setCurrentEmployee] = useState({});
    const navigate = useNavigate();

    const logout = () => {
        authContext.onLogout();
        googleLogout();
    };

    useEffect(() => {
        const storedEmployee = localStorage.getItem('employee');
        if (storedEmployee) {
            setCurrentEmployee(JSON.parse(storedEmployee));
        }
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEmployeesManagementClick = () => {
        handleClose();
        navigate('/employees');
    };

    const handleTeamsManagementClick = () => {
        handleClose();
        navigate('/teams');
    };

    const handleAbsenceTypesManagementClick = () => {
        handleClose();
        navigate('/absencetypes');
    };

    const handleHolidaysManagementClick = () => {
        handleClose();
        navigate('/holidays');
    };

    const handleGeneralAdministrationClick = () => {
        handleClose();
        navigate('/generaladministration');
    };

    const handleProfileClick = () => {
        handleClose();
        navigate('/profile');
    }

    const handleFAQClick = () => {
        handleClose();
        navigate('/faq');
    };

    const handleInventoryManagementClick = () => {
        handleClose();
        navigate('/inventory');
    };

    return (
        <StyledAppBar position="static">
            <StyledToolbar>
                <Link
                    to="/"
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Image
                        src={softhouseLogo}
                        alt="Softhouse login page image"
                        sx={{ width: '20%', height: '20%' }}
                    />
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography variant="h6" color="primary">
                            SOFTHOUSE
                        </Typography>
                        <Typography color="secondary" style={{ marginLeft: 4, marginBottom: 2 }}>
                            a d m i n i s t r a t i o n
                        </Typography>
                    </div>
                </Link>
                <NavBox>
                    <UserBox onClick={handleClick}>
                        <Avatar
                            sx={{ width: 40, height: 40 }}
                            src={currentEmployee ? currentEmployee.PictureUrl : ''}
                        />
                        {currentEmployee ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography variant="span" color={COLORS.BLACK}>
                                    {currentEmployee.FirstName} {currentEmployee.LastName}
                                </Typography>
                                <Typography
                                    variant="span"
                                    sx={{
                                        color: COLORS.DARK_GRAY,
                                        fontSize: '13px',
                                    }}
                                >
                                    {currentEmployee.Email}
                                </Typography>
                            </Box>
                        ) : (
                            <Typography variant="span" color={COLORS.BLACK}>
                                Guest User
                            </Typography>
                        )}
                    </UserBox>
                </NavBox>
            </StyledToolbar>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <AuthGuard permissionRule="All">
                    <MenuItem onClick={handleFAQClick}>
                        <ListItemIcon>
                            <HelpOutlineOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        FAQ
                    </MenuItem>
                </AuthGuard>
                <AuthGuard permissionRule="All">
                    <MenuItem onClick={handleProfileClick}>
                        <ListItemIcon>
                            <Person fontSize="small" />
                        </ListItemIcon>
                        Profile
                    </MenuItem>
                </AuthGuard>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
                <AuthGuard permissionRule="Admin">
                    <Divider />
                    <MenuItem onClick={handleEmployeesManagementClick}>
                        <ListItemIcon>
                            <PersonAdd fontSize="small" />
                        </ListItemIcon>
                        Employees management
                    </MenuItem>
                </AuthGuard>
                <AuthGuard permissionRule="Admin">
                    <MenuItem onClick={handleTeamsManagementClick}>
                        <ListItemIcon>
                            <Groups fontSize="small" />
                        </ListItemIcon>
                        Teams management
                    </MenuItem>
                </AuthGuard>
                <AuthGuard permissionRule="Admin">
                    <MenuItem onClick={handleAbsenceTypesManagementClick}>
                        <ListItemIcon>
                            <ListAlt fontSize="small" />
                        </ListItemIcon>
                        Absence types management
                    </MenuItem>
                </AuthGuard>
                <AuthGuard permissionRule="Admin">
                    <MenuItem onClick={handleHolidaysManagementClick}>
                        <ListItemIcon>
                            <Event fontSize="small" />
                        </ListItemIcon>
                        Holidays management
                    </MenuItem>
                </AuthGuard>
                <AuthGuard permissionRule="Admin">
                    <MenuItem onClick={handleGeneralAdministrationClick}>
                        <ListItemIcon>
                            <Event fontSize="small" />
                        </ListItemIcon>
                        General administration
                    </MenuItem>
                </AuthGuard>
                <AuthGuard permissionRule="InventoryAdmin">
                    <MenuItem onClick={handleInventoryManagementClick}>
                        <ListItemIcon>
                            <InventoryIcon fontSize="small" />
                        </ListItemIcon>
                        Inventory management
                    </MenuItem>
                </AuthGuard>
            </Menu>
        </StyledAppBar>
    );
};

export default TopBar;
