import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SNACKBAR_CLOSE_TIMEOUT } from 'constants';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertSnackBar = ({ message, severity, onClose, key }) => {
    const [open, setOpen] = useState(true);
    const [openKey, setOpenKey] = useState(null);

    const autoHideDuration = severity === 'error' ? null : SNACKBAR_CLOSE_TIMEOUT;

    useEffect(() => {
        setOpenKey(key);
        setOpen(true);
    }, [key]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        onClose();
    };

    return (
        <Snackbar
            open={open && key === openKey}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: '100%', borderRadius: '30px' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

AlertSnackBar.propTypes = {
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
    onClose: PropTypes.func.isRequired,
    key: PropTypes.string,
};
