import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import TopBar from 'components/shared/TopBar';

const LayoutRoot = () => {
    return (
        <Box>
            <Box>
                <TopBar />
            </Box>
            <main>
                <Outlet />
            </main>
        </Box>
    );
};

export default LayoutRoot;
