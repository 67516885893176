import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { inventoryItemModalValidationSchema } from 'utils/validationSchemas';
import { HTTP_METHODS } from 'constants';
import { DEFAULT_INVENTORY_ITEM_URL } from 'api/inventoryItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DATE_FORMAT } from 'constants';
import { format } from 'date-fns';
import { DATE_FORMAT_BACKEND } from 'constants';
import { INVENTORY_ITEM_STATUS } from 'constants';
import { INVENTORY_ITEM_STATE } from 'constants';
import { DEFAULT_INVENTORY_TYPE_URL } from 'api/inventoryType';

export const InventoryItemModal = ({ open, onClose, onSuccess, itemId }) => {
    const topElementRef = useRef();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: createInventoryItem,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateInventoryItem,
        loading: loadingUpdate,
    } = useHttp();
    const {
        data: inventoryItem,
        error: errorGetInventoryItem,
        sendRequest: getInventoryItemData,
        loading: loadingData,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title] = useState(itemId ? 'Update inventory item' : 'Add new inventory item');
    const [serialNumberRequired, setSerialNumberRequired] = useState(false);
    const {
        trigger,
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(inventoryItemModalValidationSchema(serialNumberRequired)),
        defaultValues: {
            name: '',
            status: INVENTORY_ITEM_STATUS.available,
            state: 'Good',
            serialNumber: '',
            inventoryNumber: '',
            description: '',
            typeId: '',
            dateOfPurchase: null,
            specifications: '',
        },
    });

    const {
        data: types,
        error: errorGetInventoryTypes,
        sendRequest: getInventoryTypes,
    } = useHttp();

    useEffect(() => {
        (async () => {
            await getInventoryTypes({
                url: `${DEFAULT_INVENTORY_TYPE_URL}?includeInactive=true`,
            });
        })();
    }, []);

    useEffect(() => {
        if (errorGetInventoryItem !== null || errorGetInventoryTypes != null) {
            setResponseMessage(
                errorGetInventoryItem?.response?.data?.message ||
                    errorGetInventoryTypes?.response?.data?.message ||
                    'An error occurred'
            );
            setMessageType('error');
        }
    }, [errorGetInventoryItem, errorGetInventoryTypes]);

    useEffect(() => {
        if (itemId) {
            (async () => {
                await getInventoryItemData({
                    url: `${DEFAULT_INVENTORY_ITEM_URL}/${itemId}`,
                });
            })();
        }
    }, [itemId]);

    useEffect(() => {
        if (inventoryItem) {
            reset({
                ...inventoryItem,
                serialNumber: inventoryItem.serialNumber || '',
                inventoryNumber: inventoryItem.inventoryNumber || '',
                description: inventoryItem.description || '',
                dateOfPurchase: inventoryItem.dateOfPurchase
                    ? new Date(inventoryItem.dateOfPurchase)
                    : null,
                specifications: inventoryItem.specifications || '',
            });
        }
    }, [inventoryItem, reset]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            onSuccess(`Inventory item ${dataCreate.name} created successfully.`, 'success');
            onClose();
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(`Inventory item ${dataUpdate.name} updated successfully.`, 'success');
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    useEffect(() => {
        const isRequired = isSerialNumberRequired(inventoryItem.typeId);
        setSerialNumberRequired(isRequired);
    }, [inventoryItem]);

    useEffect(() => {
        trigger('SerialNumber');
    }, [serialNumberRequired]);

    const isSerialNumberRequired = (typeId) => {
        return Array.isArray(types)
            ? (types.find((type) => type.id === typeId) || {}).isSerialNumberRequired || false
            : false;
    };

    const onSubmit = async (formData) => {
        const requestBody = {
            ...formData,
            serialNumber: formData.serialNumber !== '' ? formData.serialNumber : null,
            inventoryNumber: formData.inventoryNumber !== '' ? formData.inventoryNumber : null,
            dateOfPurchase: format(formData.dateOfPurchase, DATE_FORMAT_BACKEND),
        };

        if (itemId) {
            await updateInventoryItem({
                url: `${DEFAULT_INVENTORY_ITEM_URL}/${itemId}`,
                method: HTTP_METHODS.PUT,
                data: requestBody,
            });
        } else {
            await createInventoryItem({
                url: `${DEFAULT_INVENTORY_ITEM_URL}`,
                method: HTTP_METHODS.POST,
                data: requestBody,
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Name"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            error={!!errors.name}
                                            helperText={errors.name ? errors.name.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>State</InputLabel>
                                    <Controller
                                        control={control}
                                        name="state"
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                label="State"
                                                size="small"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                <MenuItem value={INVENTORY_ITEM_STATE.good}>
                                                    {INVENTORY_ITEM_STATE.good}
                                                </MenuItem>
                                                <MenuItem value={INVENTORY_ITEM_STATE.average}>
                                                    {INVENTORY_ITEM_STATE.average}
                                                </MenuItem>
                                                <MenuItem value={INVENTORY_ITEM_STATE.bad}>
                                                    {INVENTORY_ITEM_STATE.bad}
                                                </MenuItem>
                                                <MenuItem value={INVENTORY_ITEM_STATE.critical}>
                                                    {INVENTORY_ITEM_STATE.critical}
                                                </MenuItem>
                                                <MenuItem value={INVENTORY_ITEM_STATE.unknown}>
                                                    {INVENTORY_ITEM_STATE.unknown}
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dateOfPurchase"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <DatePicker
                                                label="Date of purchase"
                                                value={value}
                                                onChange={onChange}
                                                format={DATE_FORMAT}
                                                slotProps={{
                                                    textField: {
                                                        onBlur: onBlur,
                                                        required: true,
                                                        margin: 'dense',
                                                        fullWidth: true,
                                                        error: !!errors.dateOfPurchase,
                                                        helperText: errors.dateOfPurchase
                                                            ? errors.dateOfPurchase.message
                                                            : '',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        error={!!errors.typeId}
                                    >
                                        <InputLabel>Type</InputLabel>
                                        <Controller
                                            control={control}
                                            name="typeId"
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <Select
                                                    label="Type"
                                                    size="small"
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        const typeId = e.target.value;
                                                        const isRequired =
                                                            isSerialNumberRequired(typeId);
                                                        setSerialNumberRequired(isRequired);
                                                    }}
                                                    required
                                                    onBlur={onBlur}
                                                >
                                                    {(types || []).map((type) => {
                                                        return (
                                                            (type.active === true ||
                                                                inventoryItem.typeId ===
                                                                    type.id) && (
                                                                <MenuItem
                                                                    sx={{
                                                                        textDecorationLine:
                                                                            type.active === false &&
                                                                            'line-through',
                                                                    }}
                                                                    value={type.id}
                                                                    key={type.id}
                                                                    selected={value === type.id}
                                                                >
                                                                    {type.name}
                                                                </MenuItem>
                                                            )
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        />
                                        {errors.typeId && (
                                            <FormHelperText>{errors.typeId.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="inventoryNumber"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Inventory number"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            error={!!errors.inventoryNumber}
                                            helperText={
                                                errors.inventoryNumber
                                                    ? errors.inventoryNumber.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="serialNumber"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Serial number"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required={serialNumberRequired}
                                            error={!!errors.serialNumber}
                                            helperText={
                                                errors.serialNumber
                                                    ? errors.serialNumber.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="specifications"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Specifications"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            multiline
                                            rows={5}
                                            error={!!errors.specifications}
                                            helperText={
                                                errors.specifications
                                                    ? errors.specifications.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Description"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            multiline
                                            rows={5}
                                            error={!!errors.description}
                                            helperText={
                                                errors.description ? errors.description.message : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingData, loadingCreate, loadingUpdate)}
                            >
                                {itemId ? 'Update' : 'Add'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

InventoryItemModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    itemId: PropTypes.string,
};
