import { useState, useContext } from 'react';
import axios from 'axios';
import { getAuthToken, getRefreshToken, setAuthToken, setRefreshToken } from 'utils/auth';
import { HTTP_METHODS } from 'constants';
import { REFRESH_TOKEN_URL } from 'api/auth';
import AuthContext from 'contexts/auth-context';

const useHttp = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState('');
    const jwtToken = getAuthToken();
    const authContext = useContext(AuthContext);

    const axiosInstance = axios.create();

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (
                error.response &&
                error.response.status === 401 &&
                error.response.headers['is-token-expired'] === 'true'
            ) {
                try {
                    const tokensResponse = await refreshAccessToken();
                    const updatedResponse = await retryRequestWithNewToken(
                        error.config,
                        tokensResponse.data.accessToken
                    );
                    return updatedResponse;
                } catch (refreshError) {
                    handleTokenRefreshError(refreshError);
                }
            }
            return Promise.reject(error);
        }
    );

    const refreshAccessToken = async () => {
        const refreshTokensConfig = {
            url: REFRESH_TOKEN_URL,
            method: HTTP_METHODS.POST,
            data: {
                accessToken: getAuthToken(),
                refreshToken: getRefreshToken(),
            },
        };
        const tokensResponse = await axiosInstance(refreshTokensConfig);
        setAuthToken(tokensResponse.data.accessToken);
        setRefreshToken(tokensResponse.data.refreshToken);
        return tokensResponse;
    };

    const retryRequestWithNewToken = async (config, accessToken) => {
        const retryRequestConfig = {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
            },
        };
        return await axiosInstance(retryRequestConfig);
    };

    const handleTokenRefreshError = (refreshError) => {
        if (refreshError.response.status !== 200) {
            authContext.onLogout();
        }
    };

    const sendRequest = async (configRequest) => {
        setLoading(true);
        setError(null);
        const requestConfig = {
            url: configRequest.url,
            method: configRequest.method || HTTP_METHODS.GET,
            headers: configRequest.headers || {
                Accept: 'text/plain',
                Authorization: `Bearer ${jwtToken}`,
            },
            data: configRequest.data,
        };

        try {
            const response = await axiosInstance(requestConfig);
            setData(response.data);
        } catch (requestError) {
            setError(requestError);
        } finally {
            setLoading(false);
        }
    };

    const clearData = () => {
        setData('');
        setError(null);
    };

    return {
        data,
        clearData,
        loading,
        error,
        sendRequest,
    };
};

export default useHttp;
