import { createTheme } from '@mui/material';

import { COLORS } from 'constants';

export const theme = createTheme({
    palette: {
        primary: {
            main: COLORS.DARK_BLUE,
        },
        secondary: {
            main: COLORS.ORANGE,
            contrastText: COLORS.WHITE,
        },
        error: {
            main: COLORS.RED,
        },
        blackColor: {
            main: COLORS.BLACK,
        },
        selectBarColor: {
            main: COLORS.LIGHT_GRAY,
        },
        tooltiplColor: {
            main: COLORS.SHADE_OF_GRAY,
        },
        colorGray: {
            main: COLORS.GRAY,
        },
        calendarDaysColor: {
            main: COLORS.GRAY_VARIANT,
        },
        whiteColor: {
            main: COLORS.WHITE,
        },
        greenColor: {
            main: COLORS.GREEN,
        },
        redColor: {
            main: COLORS.RED,
        },
        blueColor: {
            main: COLORS.BLUE,
        },
        calendarBackground: {
            main: COLORS.VERY_LIGHT_GRAY,
        },
    },
    components: {
        MuiGrid: {
            variants: [
                {
                    props: { variant: 'loginWrapper' },
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh',
                        width: '100%',
                        backgroundColor: COLORS.ORANGE,
                    },
                },
            ],
        },
        MuiIconButton: {
            variants: [
                {
                    props: { variant: 'switchingMonth' },
                    style: {
                        backgroundColor: COLORS.WHITE,
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.20)',
                    },
                },
                {
                    props: { variant: 'createAbsence' },
                    style: {
                        backgroundColor: COLORS.DARK_BLUE,
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.20)',
                        right: '25px',
                        bottom: '0px',
                        position: 'absolute',
                        width: '50px',
                        height: '50px',
                        '&:hover': {
                            backgroundColor: 'rgb(35, 55, 72)',
                        },
                    },
                },
            ],
        },
        MuiCard: {
            variants: [
                {
                    props: { variant: 'signInCard' },
                    style: {
                        width: '300px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        padding: '50px',
                    },
                },
                {
                    props: { variant: 'employeeSummaryCard' },
                    style: {
                        padding: '10px',
                        marginBottom: '1px',
                        alignSelf: 'flex-start',
                        marginRight: '30px',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                    },
                },
            ],
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    boldText: 'p',
                    nameText: 'p',
                    grayText: 'p',
                    infoBody: 'p',
                    employeeSummary: 'p',
                    grayInfoBody: 'p',
                    avatarNamePosition: 'p',
                    weekend: 'p',
                    notWeekend: 'p',
                    blueBackground: 'p',
                    calendarName: 'span',
                    padding: 'div',
                    childElement: 'div',
                    SmallWrapper: 'div',
                    AbsenceWrapper: 'div',
                    BigWrapper: 'div',
                    BigWrapperHeader: 'div',
                    tableBoxWrapper: 'div',
                    tooltiplColor: {
                        main: COLORS.SHADE_OF_GRAY,
                    },
                },
                variants: [
                    {
                        props: { variant: 'signIn' },
                        style: {
                            paddingBottom: '50px',
                            fontSize: '3rem',
                        },
                    },
                ],
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: COLORS.GRAY,
                    fontSize: '14px',
                    margin: '-5px',
                },
                avatarName: {
                    width: '30px',
                    height: '30px',
                    marginLeft: '13px',
                    marginRight: '10px',
                    fontSize: '12px',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'collapse',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderTop: 'none',
                    borderColor: COLORS.SHADE_OF_GRAY,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    border: 'none',
                    textAlign: 'center',
                    maxWidth: '4px',
                    padding: '0 0 8px 0',
                    fontSize: '13px',
                    color: COLORS.GRAY,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                head: {
                    borderBottom: `0.5px solid ${COLORS.SHADE_OF_GRAY}`,
                },
            },
        },
        MuiAccordionSummary: {
            variants: [
                {
                    props: { variant: 'gray' },
                    style: {
                        backgroundColor: COLORS.LIGHT_GRAY,
                    },
                },
                {
                    props: { variant: 'padding' },
                    style: {
                        backgroundColor: COLORS.LIGHT_GRAY,
                        marginTop: '16px',
                    },
                },
            ],
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'CloseButton' },
                    style: {
                        position: 'absolute',
                        right: '5px',
                        top: '5px',
                    },
                },
            ],
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    '&.subvariant-search': {
                        '& fieldset': {
                            border: 'none',
                        },
                        backgroundColor: COLORS.LIGHT_GRAY,
                        borderRadius: '5vw',
                        '& .MuiInputBase-input.MuiOutlinedInput-input': {
                            color: COLORS.GRAY_PLACEHOLDER,
                        },
                        paddingLeft: 8,
                    },
                },
            },
        },
    },
    typography: {
        padding: {
            paddingLeft: 45,
        },
        grayText: {
            color: COLORS.GRAY,
            fontSize: 11,
        },
        nameText: {
            color: COLORS.BLACK,
            fontSize: 9,
        },
        boldText: {
            fontWeight: 'bold',
            color: COLORS.BLACK,
            fontSize: 12,
        },
        grayInfoBody: {
            color: COLORS.GRAY,
            fontSize: 12,
            fontWeight: 'bold',
        },
        employeeSummary: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            textAlign: 'center',
            alignItems: 'center',
            height: '62px',
            width: '109px',
            margin: '0',
            fontWeight: '400',
            fontSize: '1rem',
            lineHeight: '1.5',
        },
        infoBody: {
            color: COLORS.BLACK,
            fontSize: 12,
            fontWeight: 'bold',
        },
        infoBody2: {
            color: COLORS.BLACK,
            fontSize: 12,
            fontWeight: 'bold',
            paddingLeft: 19,
        },
        blueBackground: {
            fontSize: 11,
            backgroundColor: COLORS.BLUE,
            display: 'inline-block',
            padding: '1px 10px 2px 10px',
            color: COLORS.WHITE,
            borderRadius: '10px',
        },
        greenBackground: {
            fontSize: 12,
            backgroundColor: COLORS.GREEN,
            display: 'inline-block',
            padding: '2px 10px 2px 10px',
            color: COLORS.WHITE,
            borderRadius: '10px',
            fontWeight: '600',
        },
        primaryColorBackground: {
            fontSize: 12,
            backgroundColor: COLORS.DARK_BLUE,
            display: 'inline-block',
            padding: '2px 10px 2px 10px',
            color: COLORS.WHITE,
            borderRadius: '10px',
            fontWeight: '600',
        },
        redBackground: {
            fontSize: 12,
            backgroundColor: COLORS.RED,
            display: 'inline-block',
            padding: '2px 10px 2px 10px',
            color: COLORS.WHITE,
            borderRadius: '10px',
            fontWeight: '600',
        },
        calendarName: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        childElement: {
            width: 'auto',
            cursor: 'pointer',
        },
        SmallWrapper: {
            backgroundColor: COLORS.SHADE_OF_GRAY,
            fontSize: '12px',
            color: COLORS.SHADE_OF_GRAY,
            textAlign: 'center',
            borderRadius: '6px',
            padding: '5px',
        },
        BigWrapper: {
            backgroundColor: COLORS.SHADE_OF_GRAY,
            display: 'grid',
            rowGap: '10px',
            width: '120px',
            fontSize: '12px',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            padding: '15px 10px 15px 10px',
        },
        BigWrapperHeader: {
            backgroundColor: COLORS.WHITE,
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            padding: '10px',
        },
        AbsenceWrapper: {
            padding: '7px',
        },
        StandardWrapper: {
            backgroundColor: COLORS.SHADE_OF_GRAY,
            width: '80px',
            height: '90px',
            fontSize: '12px',
            display: 'grid',
            borderRadius: '6px',
            paddingTop: '3px',
        },
        tableBoxWrapper: {
            display: 'flex',
            height: '30px',
        },
        avatarNamePosition: {
            marginLeft: '13px',
            marginTop: '5px',
        },
        weekend: {
            color: COLORS.GRAY_VARIANT,
        },
    },
});
