import React from 'react';
import { Link } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';
import { theme } from '../theme';

export const renderActiveCell = (params, { type }) => {
    const filledLink =
        type === 'assignment'
            ? params.row.filledAssignmentFormLink
            : params.row.filledUnassignmentFormLink;
    const signedLink =
        type === 'assignment'
            ? params.row.signedAssignmentFormLink
            : params.row.signedUnassignmentFormLink;

    return (
        <div>
            {filledLink && (
                <Link href={filledLink} target="_blank" rel="noopener noreferrer">
                    <IconButtonWithTooltip
                        icon={<OpenInNewIcon />}
                        tooltipText={`Open filled ${type}`}
                        color={theme.palette.primary.main}
                    />
                </Link>
            )}
            {signedLink && (
                <Link href={signedLink} target="_blank" rel="noopener noreferrer">
                    <IconButtonWithTooltip
                        icon={<OpenInNewIcon />}
                        tooltipText={`Open signed ${type}`}
                        color={theme.palette.secondary.main}
                    />
                </Link>
            )}
        </div>
    );
};

export const renderEditButton = (
    params,
    setInventoryItemId,
    setAssigneeInventoryItemId,
    setIsInventoryItemModalOpen
) => {
    const handleEditClick = () => {
        setInventoryItemId(params.row.inventoryId);
        setAssigneeInventoryItemId(params.row.id);
        setIsInventoryItemModalOpen(true);
    };
    if (params.row.endDate !== null) {
        return (
            <IconButtonWithTooltip
                onClick={handleEditClick}
                icon={<Edit />}
                tooltipText="Edit"
                color={theme.palette.primary.main}
            />
        );
    } else {
        return null;
    }
};

export const myInventoryItemsColumns = [
    { field: 'id', headerName: 'ID' },
    { field: 'inventoryId', headerName: 'InventoryId' },
    {
        field: 'assigneeName',
        headerName: 'Asignee name',
        flex: 0.05,
    },
    {
        field: 'name',
        headerName: 'Item name',
        flex: 0.06,
    },
    {
        field: 'inventoryNumber',
        headerName: 'Inventory number',
        flex: 0.04,
    },
    {
        field: 'serialNumber',
        headerName: 'Serial number',
        flex: 0.05,
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 0.03,
    },
    {
        field: 'specifications',
        headerName: 'Specifications',
        flex: 0.05,
    },
    {
        field: 'startDate',
        headerName: 'Start date',
        flex: 0.05,
    },
    {
        field: 'endDate',
        headerName: 'End date',
        flex: 0.05,
    },
    {
        field: 'state',
        headerName: 'State',
        flex: 0.05,
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 0.05,
    },
    {
        field: 'assignment',
        headerName: 'Assignment',
        flex: 0.03,
        minWidth: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => renderActiveCell(params, { type: 'assignment' }),
    },
    {
        field: 'unassignment',
        headerName: 'Unassignment',
        flex: 0.03,
        minWidth: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => renderActiveCell(params, { type: 'unassignment' }),
    },
    {
        field: 'edit',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 0.5,
        renderCell: renderEditButton,
    },
];
