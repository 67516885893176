import React, { useEffect, useRef, useState } from 'react';
import {
    styled,
    Button,
    Box,
    Grid,
    TextField,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import Groups from '@mui/icons-material/Groups';
import Event from '@mui/icons-material/Event';
import { GridActionsCellItem } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useHttp from 'hooks/useHttp';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { EmployeeModal } from 'components/EmployeeModal';
import { DeleteEmployeeModal } from 'components/DeleteEmployeeModal';
import { ReactivateEmployeeModal } from 'components/ReactivateEmployeeModal';
import { EmployeeTeamsModal } from 'components/EmployeeTeamsModal';
import { CreateAbsenceModal } from 'components/CreateAbsenceModal';
import { AlertSnackBar } from 'components/AlertSnackBar';
import {
    employeeColumns,
    renderEditButton,
    renderDeleteReactivateButton,
} from 'utils/dataColumns/employeeColumns';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import GeneralData from './Profile/GeneralData';

const StyledCells = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const Employees = () => {
    const [employeeId, setEmployeeId] = useState('');
    const { data: employeesData, error: errorGetEmployees, sendRequest: getEmployees } = useHttp();
    const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
    const [isDeleteEmployeeModalOpen, setIsDeleteEmployeeModalOpen] = useState(false);
    const [isReactivateEmployeeModalOpen, setIsReactivateEmployeeModalOpen] = useState(false);
    const [isEmployeeTeamsModalOpen, setIsEmployeeTeamsModalOpen] = useState(false);
    const [isCreateAbsenceModalOpen, setIsCreateAbsenceModalOpen] = useState(false);
    const [isEmployeeInfoModalOpen, setIsEmployeeInfoModalOpen] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [filter, setFilter] = useState('');
    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);
    const [snackbars, setSnackbars] = useState([]);
    const topElementRef = useRef();

    useEffect(() => {
        if (isGridDataRefreshNeeded) {
            getEmployees({
                url: `${DEFAULT_EMPLOYEE_URL}?includeInactive=true`,
            });
            setIsGridDataRefreshNeeded(false);
        }
    }, [isGridDataRefreshNeeded]);

    useEffect(() => {
        if (errorGetEmployees !== null) {
            showSnackbar('Failed to load employees.', 'error');
        }
    }, [errorGetEmployees]);

    const employeeTableData = [
        ...(employeesData.data || []).map((employee) => ({
            id: employee.id,
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            active: `${employee.active}`,
            pxCode: employee.pxCode,
            roles: employee.roles.map((role) => role.name).join(', '),
            category: employee.category,
            workingPlace: employee.workingPlace,
        })),
    ];

    const filteredData = employeeTableData.filter((employee) => {
        return (
            (showInactive ? employee.active === 'false' : employee.active === 'true') &&
            (employee.firstName.toLowerCase().includes(filter.toLowerCase()) ||
                employee.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                employee.email.toLowerCase().includes(filter.toLowerCase()) ||
                employee.roles.toLowerCase().includes(filter.toLowerCase()) ||
                employee.category.toLowerCase().includes(filter.toLowerCase()) ||
                employee.pxCode?.toLowerCase().includes(filter.toLowerCase()) ||
                employee.workingPlace?.toLowerCase().includes(filter.toLowerCase()))
        );
    });

    const handleEmployeeTeamsClick = (id) => () => {
        setEmployeeId(id);
        setIsEmployeeTeamsModalOpen(true);
    };

    const handleCreateAbsenceClick = (id) => () => {
        setEmployeeId(id);
        setIsCreateAbsenceModalOpen(true);
    };

    const handleEmployeeInformationClick = (id) => () => {
        setEmployeeId(id);
        setIsEmployeeInfoModalOpen(true);
    };

    const columnsForEmployee = employeeColumns.map((column) => {
        if (column.field === 'edit') {
            return {
                ...column,
                renderCell: (params) =>
                    renderEditButton(params, setEmployeeId, setIsEmployeeModalOpen),
            };
        } else if (column.field === 'delete-reactivate') {
            return {
                ...column,
                renderCell: (params) =>
                    renderDeleteReactivateButton(
                        params,
                        setEmployeeId,
                        setIsDeleteEmployeeModalOpen,
                        setIsReactivateEmployeeModalOpen
                    ),
            };
        }
        return column;
    });

    columnsForEmployee.push({
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
            <GridActionsCellItem
                key="add-to-team"
                icon={<Groups />}
                label="Teams"
                onClick={handleEmployeeTeamsClick(params.row.id)}
                showInMenu
            />,
            <GridActionsCellItem
                key="create-absence"
                icon={<Event />}
                label="Create absence"
                onClick={handleCreateAbsenceClick(params.row.id)}
                showInMenu
            />,
            <GridActionsCellItem
                key="employee-information"
                icon={<PersonIcon />}
                label="Employee information"
                onClick={handleEmployeeInformationClick(params.row.id)}
                showInMenu
            />
        ],
    });

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleAddNewEmployeeClick = () => {
        setEmployeeId('');
        setIsEmployeeModalOpen(true);
    };

    const handleShowInactiveChange = (event) => {
        setShowInactive(event.target.checked);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleEmployeeModalClose = () => {
        setIsEmployeeModalOpen(false);
    };

    const handleDeleteEmployeeModalClose = () => {
        setIsDeleteEmployeeModalOpen(false);
    };

    const handleReactivateEmployeeModalClose = () => {
        setIsReactivateEmployeeModalOpen(false);
    };

    const handleEmployeeTeamsModalClose = () => {
        setIsEmployeeTeamsModalOpen(false);
    };

    const handleCreateAbsenceModalClose = () => {
        setIsCreateAbsenceModalOpen(false);
    };
    const handleEmployeeInfoModalClose = () => {
        setIsEmployeeInfoModalOpen(false);
    };
    const handleCreateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleDeleteSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleReactivateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        if (message) {
            showSnackbar(message, severity);
        }
    };

    const handleAbsenceCreateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const renderContent = () => {
        return (
            <>
                <Grid item xs={5} marginBottom="16px">
                    <StyledCells>
                        <h1>Employees</h1>
                        <Button onClick={handleAddNewEmployeeClick} variant="contained">
                            + Add new employee
                        </Button>
                    </StyledCells>
                </Grid>
                <Grid item xs={5} marginBottom="16px">
                    <TextField
                        placeholder="Search"
                        className="subvariant-search"
                        size="small"
                        value={filter}
                        onChange={handleFilterChange}
                        focused
                        style={{ marginRight: '16px' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {filter ? (
                                        <IconButton
                                            onClick={() => setFilter('')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    ) : (
                                        <SearchIcon style={{ color: 'black' }} />
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={showInactive} onChange={handleShowInactiveChange} />
                        }
                        label="Show inactive employees"
                    />
                </Grid>
                {isEmployeeModalOpen && (
                    <EmployeeModal
                        open={isEmployeeModalOpen}
                        onClose={handleEmployeeModalClose}
                        onSuccess={handleCreateSuccess}
                        employeeId={employeeId}
                    />
                )}
                {isDeleteEmployeeModalOpen && (
                    <DeleteEmployeeModal
                        open={isDeleteEmployeeModalOpen}
                        onClose={handleDeleteEmployeeModalClose}
                        onSuccess={handleDeleteSuccess}
                        employeeId={employeeId}
                    />
                )}
                {isReactivateEmployeeModalOpen && (
                    <ReactivateEmployeeModal
                        open={isReactivateEmployeeModalOpen}
                        onClose={handleReactivateEmployeeModalClose}
                        onSuccess={handleReactivateSuccess}
                        employeeId={employeeId}
                    />
                )}
                {isEmployeeTeamsModalOpen && (
                    <EmployeeTeamsModal
                        open={isEmployeeTeamsModalOpen}
                        onClose={handleEmployeeTeamsModalClose}
                        employeeId={employeeId}
                    />
                )}
                {isCreateAbsenceModalOpen && (
                    <CreateAbsenceModal
                        open={isCreateAbsenceModalOpen}
                        onClose={handleCreateAbsenceModalClose}
                        onSuccess={handleAbsenceCreateSuccess}
                        employeeId={employeeId}
                    />
                )}

                {isEmployeeInfoModalOpen && (
                    <Box display="flex-center" width="100%">
                        <Dialog open={isEmployeeInfoModalOpen} onClose={handleEmployeeInfoModalClose} maxWidth="xl" fullWidth>
                            <DialogTitle style={{ textTransform: 'none' }}>
                                <Button onClick={handleEmployeeInfoModalClose} variant="CloseButton">
                                    <CloseIcon />
                                </Button>
                            </DialogTitle>
                            <DialogContent>
                                <GeneralData selectedEmployeeId={employeeId} modalClose={handleEmployeeInfoModalClose} />
                            </DialogContent>
                        </Dialog>
                    </Box>
                )}

                <div style={{ height: '100%', width: '100%' }}>
                    <CommonDataGrid
                        rows={filteredData}
                        columns={columnsForEmployee.filter((column) => column.field !== 'active')}
                    />
                </div>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {renderContent()}
        </Box>
    );
};

export default Employees;
