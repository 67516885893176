import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { InventoryTypeModal } from 'components/InventoryTypeModal';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { DEFAULT_INVENTORY_TYPE_URL } from 'api/inventoryType';
import { HTTP_METHODS } from 'constants';

const TITLE = 'Reactivate inventory type';

export const ReactivateInventoryTypeModal = ({ open, onClose, onSuccess, typeId }) => {
    const topElementRef = useRef();
    const {
        data: dataReactivate,
        error: errorReactivate,
        clearData: clearDataReactivate,
        sendRequest: reactivateInventoryType,
        loading: loadingReactivate,
    } = useHttp();
    const {
        data: inventoryTypeData,
        error: errorGetInventoryType,
        sendRequest: getInventoryTypeData,
        loading: loadingData,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [inventoryType, setInventoryType] = useState(null);
    const [isInventoryTypeModalOpen, setIsInventoryTypeModalOpen] = useState(false);

    useEffect(() => {
        if (typeId) {
            (async () => {
                await getInventoryTypeData({
                    url: `${DEFAULT_INVENTORY_TYPE_URL}/${typeId}`,
                });
            })();
        }
    }, [typeId]);

    useEffect(() => {
        if (errorGetInventoryType !== null) {
            setResponseMessage(errorGetInventoryType.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
    }, [errorGetInventoryType]);

    useEffect(() => {
        if (inventoryTypeData) {
            setInventoryType(inventoryTypeData);
        }
    }, [inventoryTypeData]);

    useEffect(() => {
        clearDataReactivate();

        if (errorReactivate !== null) {
            setResponseMessage(errorReactivate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataReactivate) {
            setResponseMessage('Inventory type reactivated successfully.');
            setMessageType('success');
            onSuccess();
        }
    }, [errorReactivate, dataReactivate, messageType]);

    const handleEditConfirmation = () => {
        setIsInventoryTypeModalOpen(true);
    };

    const handleInventoryTypeModalClose = () => {
        setIsInventoryTypeModalOpen(false);
        onClose();
    };

    const handleReactivateConfirmation = async () => {
        const requestBody = {
            name: inventoryType.name,
            active: true,
            isSerialNumberRequired: inventoryType.isSerialNumberRequired
        };

        if (typeId) {
            await reactivateInventoryType({
                url: `${DEFAULT_INVENTORY_TYPE_URL}/${typeId}`,
                method: HTTP_METHODS.PUT,
                data: requestBody,
            });
        }
        scrollIntoView(topElementRef);
    };

    const handleUpdateSuccess = () => {
        onSuccess('Inventory type updated successfully.', 'success');
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>

                    {isInventoryTypeModalOpen ? (
                        <InventoryTypeModal
                            open={isInventoryTypeModalOpen}
                            onClose={handleInventoryTypeModalClose}
                            onSuccess={handleUpdateSuccess}
                            typeId={typeId}
                        />
                    ) : (
                        <>
                            {messageType !== 'none' && (
                                <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                            )}
                            {messageType !== 'success' && (
                                <Grid item xs={12}>
                                    <Typography>
                                        Are you sure you want to reactivate the inventory type?
                                    </Typography>
                                    <Box mt={2} display="flex" justifyContent="flex-end">
                                        <Button
                                            onClick={onClose}
                                            variant="outlined"
                                            sx={{ marginRight: '8px' }}
                                            disabled={isLoading(loadingData, loadingReactivate)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleReactivateConfirmation}
                                            variant="contained"
                                            color="error"
                                            disabled={isLoading(loadingData, loadingReactivate)}
                                        >
                                            Reactivate
                                        </Button>
                                    </Box>
                                </Grid>
                            )}
                            {messageType === 'success' && (
                                <Grid item xs={12}>
                                    <Typography>Do you want to edit inventory type?</Typography>
                                    <Box mt={2} display="flex" justifyContent="flex-end">
                                        <Button
                                            onClick={onClose}
                                            variant="outlined"
                                            sx={{ marginRight: '8px' }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button onClick={handleEditConfirmation} variant="contained">
                                            Edit
                                        </Button>
                                    </Box>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

ReactivateInventoryTypeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    typeId: PropTypes.string.isRequired,
};
