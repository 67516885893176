import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import { theme } from 'utils/theme';
import { isActive } from 'utils/misc';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';

export const renderEditButton = (params, setAbsenceTypeId, setIsAbsenceTypeModalOpen) => {
    const handleEditClick = () => {
        setAbsenceTypeId(params.row.id);
        setIsAbsenceTypeModalOpen(true);
    };

    if (isActive(params.row)) {
        return (
            <IconButtonWithTooltip
                onClick={handleEditClick}
                icon={<EditIcon />}
                tooltipText="Edit"
                color={theme.palette.primary.main}
            />
        );
    } else {
        return null;
    }
};

export const renderDeleteReactivateButton = (
    params,
    setAbsenceTypeId,
    setIsDeleteAbsenceTypeModalOpen,
    setIsReactivateAbsenceTypeModalOpen
) => {
    const handleDeleteClick = () => {
        setAbsenceTypeId(params.row.id);
        setIsDeleteAbsenceTypeModalOpen(true);
    };

    const handleReactivateClick = () => {
        setAbsenceTypeId(params.row.id);
        setIsReactivateAbsenceTypeModalOpen(true);
    };

    return (
        <div>
            {isActive(params.row) ? (
                <IconButtonWithTooltip
                    onClick={handleDeleteClick}
                    icon={<PowerSettingsNew />}
                    tooltipText="Deactivate"
                    color={theme.palette.redColor.main}
                />
            ) : (
                <IconButtonWithTooltip
                    onClick={handleReactivateClick}
                    icon={<SyncIcon />}
                    tooltipText="Reactivate"
                    color={theme.palette.primary.main}
                />
            )}
        </div>
    );
};

export const absenceTypeColumns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'name',
        headerName: 'Name',
        flex: 0.3,
        minWidth: 50,
    },
    {
        field: 'active',
        type: 'boolean',
        headerName: 'Active',
    },
    {
        field: 'edit',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderEditButton,
    },
    {
        field: 'delete-reactivate',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        renderCell: renderDeleteReactivateButton,
    },
];
