import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

export const IconButtonWithTooltip = ({ onClick, icon, tooltipText, color }) => {
    return (
        <Tooltip TransitionProps={{ timeout: 900 }} followCursor={true} title={tooltipText}>
            <IconButton style={{ color: color }} onClick={onClick}>
                {icon}
            </IconButton>
        </Tooltip>
    );
};

IconButtonWithTooltip.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    tooltipText: PropTypes.string.isRequired,
    color: PropTypes.string,
};
