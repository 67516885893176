import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useHttp from 'hooks/useHttp';
import { DEFAULT_TIME_SHEET_URL } from 'api/timeSheet';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { getAuthToken } from 'utils/auth';
import { scrollIntoView } from 'utils/scroll';
import { HTTP_METHODS } from 'constants';
import { FIRST_YEAR } from 'constants';

const TITLE = 'Upload time sheet';

const INFO_MESSAGE =
    'When you pick a month and year, choose a file, and click "UPLOAD," the time sheets data will be imported.';

export const TimeSheetUploadModal = ({ open, onClose, onSuccess }) => {
    const topElementRef = useRef();
    const { data, error, clearData, sendRequest: uploadTimeSheet, loading } = useHttp();
    const [messageType, setMessageType] = useState();
    const [responseMessage, setResponseMessage] = useState(INFO_MESSAGE);
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear(), 12, 0);
    const minDate = new Date(FIRST_YEAR, 1, 0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [override, setOverride] = useState(false);
    const [formData, setFormData] = useState({
        Period: new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
    });

    useEffect(() => {
        clearData();

        if (error !== null) {
            setResponseMessage(error.response?.data?.message || 'An error occurred');
            setMessageType('error');
            if (
                error.response?.data?.message &&
                error.response?.data?.message.includes('already exists')
            ) {
                setOverride(true);
            }
        }

        if (data) {
            if (data.includes('WARNING')) {
                onSuccess(data, 'warning');
                setMessageType('warning');
            } else {
                onSuccess(data, 'success');
                setMessageType('success');
            }

            setResponseMessage(data);
            setOverride(false);
        }
    }, [error, data, messageType, onClose]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

            if (fileExtension !== 'csv') {
                setMessageType('error');
                setResponseMessage('Invalid file format. Please upload a CSV file.');
                return;
            }

            setSelectedFile(file);
            setOverride(false);
            setMessageType();
            setResponseMessage(INFO_MESSAGE);
        }
    };

    const handlePeriodChange = (selectedPeriod) => {
        setFormData({ Period: selectedPeriod });
        setOverride(false);
        setMessageType();
        setResponseMessage(INFO_MESSAGE);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const month = formData.Period.getMonth() + 1;
        const year = formData.Period.getFullYear();

        const requestBody = new FormData();
        requestBody.append('file', selectedFile);

        const headers = {
            'Content-Type': 'multipart/form-data',
            Accept: 'multipart/form-data',
            Authorization: `Bearer ${getAuthToken()}`,
        };

        await uploadTimeSheet({
            url: `${DEFAULT_TIME_SHEET_URL}/upload-time-sheets?year=${year}&month=${month}&overrideIfExists=${override}`,
            method: HTTP_METHODS.POST,
            data: requestBody,
            headers: headers,
        });

        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container width={500} ref={topElementRef}>
                    <form onSubmit={handleSubmit}>
                        <Box marginBottom={3} width={500}>
                            {messageType !== 'none' && (
                                <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                            )}
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                            <DatePicker
                                views={['year', 'month']}
                                label="Month and year"
                                minDate={minDate}
                                maxDate={maxDate}
                                value={formData.Period}
                                onChange={handlePeriodChange}
                                renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                )}
                            />
                        </LocalizationProvider>
                        <Grid container>
                            <input
                                accept=".csv, text/csv"
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                marginBottom={3}
                            >
                                <label htmlFor="file-upload">
                                    <Button variant="outlined" component="span">
                                        Choose File
                                    </Button>
                                </label>
                                {selectedFile && (
                                    <Typography
                                        color="textSecondary"
                                        style={{ marginLeft: '16px' }}
                                    >
                                        {selectedFile.name}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        {override ? (
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="error"
                                    disabled={loading}
                                >
                                    OVERRIDE
                                </Button>
                            </Box>
                        ) : (
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button type="submit" variant="contained" disabled={loading}>
                                    UPLOAD
                                </Button>
                            </Box>
                        )}
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

TimeSheetUploadModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};
