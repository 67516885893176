import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Link,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useHttp from 'hooks/useHttp';
import { DEFAULT_INVENTORY_ITEM_URL } from 'api/inventoryItem';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { getAuthToken } from 'utils/auth';
import { scrollIntoView } from 'utils/scroll';
import { HTTP_METHODS } from 'constants';

export const InventoryAssignmentFormUploadModal = ({
    open,
    onClose,
    onSuccess,
    selectedIds,
    type,
}) => {
    const topElementRef = useRef();
    const {
        data,
        error,
        clearData,
        sendRequest: uploadSignedInventoryAssignmentForm,
        loading,
    } = useHttp();
    const title = `Upload signed ${type} form`;
    const info_message = `Choose a signed ${type} form and click "UPLOAD." Choosen document will be uploaded and notification sent to employee and inventory administrators.`;

    const [messageType, setMessageType] = useState();
    const [responseMessage, setResponseMessage] = useState(info_message);
    const [selectedFile, setSelectedFile] = useState(null);
    const [override, setOverride] = useState(false);

    useEffect(() => {
        clearData();

        if (error !== null) {
            setResponseMessage(error.response?.data?.message || 'An error occurred');
            setMessageType('error');
            if (
                error.response?.data?.message &&
                error.response?.data?.message.includes('already exists')
            ) {
                setOverride(true);
            }
        }

        if (data) {
            const messageType = data.includes('WARNING') ? 'warning' : 'success';
            onSuccess(data, messageType);
            setMessageType(messageType);
            setResponseMessage(data);
            setOverride(false);
            setSelectedFile(null);
        }
    }, [error, data, messageType, onClose]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

            if (fileExtension !== 'pdf') {
                setMessageType('error');
                setResponseMessage('Invalid file format. Please upload a pdf file.');
                return;
            }

            setSelectedFile(file);
            setOverride(false);
            setMessageType();
            setResponseMessage(info_message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestBody = {
            file: selectedFile,
            inventoryItemsIds: selectedIds,
        };

        const headers = {
            'Content-Type': 'multipart/form-data',
            Accept: 'multipart/form-data',
            Authorization: `Bearer ${getAuthToken()}`,
        };

        await uploadSignedInventoryAssignmentForm({
            url: `${DEFAULT_INVENTORY_ITEM_URL}/upload-inventory-${type}-form?overrideIfExists=${override}`,
            method: HTTP_METHODS.POST,
            data: requestBody,
            headers: headers,
        });

        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container width={500} ref={topElementRef}>
                    <form onSubmit={handleSubmit}>
                        <Box marginBottom={3} width={500}>
                            {messageType !== 'none' && (
                                <MessageBox messageType={messageType}>
                                    {messageType === 'success' ? (
                                        <>
                                            <Typography>
                                                Successfully uploaded signed annual leave decision.
                                            </Typography>
                                            <Link
                                                href={responseMessage}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                View
                                            </Link>
                                        </>
                                    ) : (
                                        responseMessage
                                    )}
                                </MessageBox>
                            )}
                        </Box>
                        <Grid container width={500}>
                            <input
                                accept=".pdf, text/pdf"
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Box mt={2} marginBottom={3} width={500}>
                                <label
                                    htmlFor="file-upload"
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <Button variant="outlined" component="span">
                                        Choose File
                                    </Button>
                                    {selectedFile && (
                                        <Tooltip title={selectedFile.name}>
                                            <Typography
                                                color="textSecondary"
                                                style={{
                                                    marginLeft: '16px',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    maxWidth: '67%',
                                                }}
                                            >
                                                {selectedFile.name}
                                            </Typography>
                                        </Tooltip>
                                    )}
                                </label>
                            </Box>
                        </Grid>
                        {override ? (
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="error"
                                    disabled={loading}
                                >
                                    OVERRIDE
                                </Button>
                            </Box>
                        ) : (
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button type="submit" variant="contained" disabled={loading}>
                                    UPLOAD
                                </Button>
                            </Box>
                        )}
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

InventoryAssignmentFormUploadModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    selectedIds: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};
