import React from 'react';
import PropTypes from 'prop-types';
import { hasPermissions } from 'utils/auth';
import { permissionRules } from 'utils/permissionRules';

export const AuthGuard = ({ permissionRule, children }) => {
    const permissions = hasPermissions();
    const isPermission = () => {
        if (!Array.isArray(permissions) || !permissionRules[permissionRule]) {
            return false;
        }

        return permissionRules[permissionRule].some((role) => permissions.includes(role));
    };

    if (isPermission()) {
        return <>{children}</>;
    }
};

AuthGuard.propTypes = {
    permissionRule: PropTypes.string,
    children: PropTypes.node,
};
