import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ChipInfo from '@mui/material/Chip';

const StyledChip = styled('div')(() => ({
    marginTop: '5px',
    marginRight: '10px'
}));

export const Chip = ({ label, onDelete, disabled }) => {
    return (
        <StyledChip>
            <ChipInfo label={label} onDelete={onDelete} disabled={disabled} />
        </StyledChip>
    );
};

Chip.propTypes = {
    label: PropTypes.string,
    onDelete: PropTypes.func,
    disabled: PropTypes.bool,
};
