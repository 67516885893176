import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Box, Grid, Button, Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ColorfulSelectDropdown } from 'components/ColorfulSelectDropdown';
import { DateRangePicker } from 'components/DateRangePicker';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { isLoading } from 'utils/misc';
import { generateAbsenceCreateURL } from 'api/absence';
import { DEFAULT_ABSENCE_TYPE_URL } from 'api/absenceType';
import { DATE_FORMAT_BACKEND, HTTP_METHODS } from 'constants';

export const CreateAbsenceModal = ({ open, onClose, onSuccess, employeeId, absenceCreated }) => {
    const [selectedAbsenceType, setSelectedAbsenceType] = useState('');
    const [responseMessage, setResponseMessage] = useState(
        'Information about your new absence will be automatically sent to the admin.'
    );
    const [selectedDateRange, setSelectedDateRange] = useState([new Date(), new Date()]);
    const [messageType, setMessageType] = useState();
    const {
        data: absenceTypesData,
        sendRequest: getAbsenceTypes,
        loading: loadingData,
    } = useHttp();
    const {
        data,
        error,
        clearData,
        sendRequest: createAbsence,
        loading: loadingCreate,
    } = useHttp();
    const currentEmployeeId = JSON.parse(localStorage.getItem('employee')).Id;
    const absenceCreateUrl = generateAbsenceCreateURL(employeeId ? employeeId : currentEmployeeId);
    const [formData, setFormData] = useState({
        Reason: '',
    });
    const [dateValidationError, setDateValidationError] = useState(null);

    useEffect(() => {
        (async () => {
            await getAbsenceTypes({
                url: DEFAULT_ABSENCE_TYPE_URL,
            });
        })();
    }, []);

    useEffect(() => {
        clearData();

        if (error !== null) {
            setResponseMessage(error.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (data) {
            if (!data.notificationError) {
                onSuccess('Absence created successfully.', 'success', data.absencesSharedModel.id);
                onClose();
                if (absenceCreated) {
                    absenceCreated(data.absencesSharedModel.id);
                }
            } else {
                setResponseMessage(data.notificationError);
                setMessageType('warning');
                absenceCreated(data.absencesSharedModel.id);
            }
        }
    }, [error, data, messageType]);

    const handleAbsenceTypeChange = (newValue) => {
        setSelectedAbsenceType(newValue);
    };

    const handleDateRangeChange = (dateRange, validationError) => {
        if (!validationError) {
            setDateValidationError(null);
            if (dateRange[0] > dateRange[1]) {
                dateRange[1] = dateRange[0];
            }
            setSelectedDateRange(dateRange);
        } else {
            setDateValidationError(validationError);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const shouldDisableStartDate = (day) => {
        const endDate = selectedDateRange[1];
        const currentDate = new Date();

        let firstDayOfPreviousMonth;
        if (currentDate.getMonth() === 0) {
            firstDayOfPreviousMonth = new Date(currentDate.getFullYear() - 1, 11, 1).setHours(
                0,
                0,
                0,
                0
            );
        } else {
            firstDayOfPreviousMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - 1,
                1
            ).setHours(0, 0, 0, 0);
        }

        if (endDate) {
            return day < firstDayOfPreviousMonth;
        }
        return false;
    };

    const shouldDisableEndDate = (day) => {
        const startDate = selectedDateRange[0].setHours(0, 0, 0, 0);
        if (startDate) {
            return day < startDate;
        }
        return false;
    };

    const handleCreateAbsenceButtonClick = async (e) => {
        e.preventDefault();
        if (validateAbcenceCreate()) {
            const requestBody = {
                startDate: format(selectedDateRange[0], DATE_FORMAT_BACKEND),
                endDate: format(selectedDateRange[1], DATE_FORMAT_BACKEND),
                reason: formData.Reason,
            };

            await createAbsence({
                url: `${absenceCreateUrl}/${selectedAbsenceType}`,
                method: HTTP_METHODS.POST,
                data: requestBody,
            });
        }
    };

    const isDateValid = () => {
        return dateValidationError === null;
    };

    const validateAbcenceCreate = () => {
        let responseMessage = '';
        let messageType = 'none';
        const isAbsenceTypeValid = selectedAbsenceType;

        if (!isAbsenceTypeValid || !isDateValid()) {
            if (!isDateValid()) {
                responseMessage += 'Please select a complete date range.';
            }
            if (!isAbsenceTypeValid) {
                if (responseMessage) {
                    responseMessage += ' ';
                }
                responseMessage += 'Please select an absence type.';
            }
            messageType = 'warning';
        }
        setResponseMessage(responseMessage);
        setMessageType(messageType);

        return isAbsenceTypeValid && isDateValid();
    };

    const options = [
        ...(absenceTypesData || []).map((absenceType) => ({
            label: `${absenceType.name}`,
            value: `${absenceType.id}`,
            color: `${absenceType.color}`,
        })),
    ];
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                Add new absence
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500}>
                    <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    <form onSubmit={handleCreateAbsenceButtonClick}>
                        <Box mt={2}>
                            <ColorfulSelectDropdown
                                label="Absence type"
                                options={options}
                                value={selectedAbsenceType}
                                onChange={handleAbsenceTypeChange}
                            />
                        </Box>
                        <Box mt={2}>
                            <DateRangePicker
                                selectedDateRange={selectedDateRange}
                                onChange={handleDateRangeChange}
                                shouldDisableStartDate={shouldDisableStartDate}
                                shouldDisableEndDate={shouldDisableEndDate}
                            />
                        </Box>
                        <TextField
                            label="Notes"
                            name="Reason"
                            margin="dense"
                            value={formData.Reason}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(loadingData, loadingCreate)}
                            >
                                Add
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

CreateAbsenceModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    employeeId: PropTypes.string,
    absenceCreated: PropTypes.func,
};
