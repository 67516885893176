import React from 'react';
import { Link, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';
import { theme } from 'utils/theme';

export const renderUploadButton = (
    params,
    setAnnualLeaveDecisionId,
    setIsAnnualLeaveDecisionUploadModalOpen,
    viewOnly
) => {
    const handleUploadClick = () => {
        setAnnualLeaveDecisionId(params.row.id);
        setIsAnnualLeaveDecisionUploadModalOpen(true);
    };

    return (
        !viewOnly && (
            <IconButtonWithTooltip
                onClick={handleUploadClick}
                icon={<UploadFileIcon />}
                tooltipText="Upload signed annual leave decision"
                color={theme.palette.primary.main}
            />
        )
    );
};

export const renderActiveCell = (params) => {
    return (
        <Link href={params.value ? params.value : '#'} target="_blank" rel="noopener noreferrer">
            {params.value ? (
                <IconButtonWithTooltip
                    icon={<OpenInNewIcon />}
                    tooltipText="Open"
                    color={theme.palette.primary.main}
                />
            ) : null}
        </Link>
    );
};

export const generateAnnualLeaveDecisionColumns = (viewOnly) => {
    return [
        { field: 'employeeId', headerName: 'Employee ID' },
        { field: 'employeeName', headerName: 'Employee', flex: 0.3, minWidth: 150 },
        { field: 'id', headerName: 'ID' },
        { field: 'version', headerName: 'Version', flex: 0.3, minWidth: 60 },
        {
            field: 'filledDocumentLink',
            headerName: 'Filled Document',
            flex: 0.3,
            minWidth: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: renderActiveCell,
        },
        {
            field: 'signedDocumentLink',
            headerName: 'Signed Document',
            flex: 0.3,
            minWidth: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: renderActiveCell,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.3,
            minWidth: 100,
            renderCell: (params) => (
                <Typography
                    variant={
                        params.value === 'Approved'
                            ? 'greenBackground'
                            : params.value === 'Canceled'
                            ? 'redBackground'
                            : 'primaryColorBackground'
                    }
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'upload',
            headerName: '',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 120,
            align: 'center',
            renderCell: (params) => renderUploadButton(params, viewOnly),
        },
    ];
};
