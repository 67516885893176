import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import useHttp from 'hooks/useHttp';
import { DEFAULT_TIME_SHEET_URL } from 'api/timeSheet';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { TimeSheetModal } from 'components/TimeSheetModal';
import { TimeSheetUploadModal } from 'components/TimeSheetUploadModal';
import { FIRST_YEAR } from 'constants';
import { generateTimeSheetColumns } from 'utils/dataColumns/timeSheetColumns';

export const TimeSheet = ({ filter, renderSearchTextField, passedEmployeeId }) => {
    const currentDate = new Date();
    const [snackbars, setSnackbars] = useState([]);
    const [timeSheetId, setTimeSheetId] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [year, setYear] = useState(currentDate.getFullYear());
    const [prevYearDisabled, setPrevYearDisabled] = useState(false);
    const [nextYearDisabled, setNextYearDisabled] = useState(true);
    const [month, setMonth] = useState();
    const [isTimeSheetModalOpen, setIsTimeSheetModalOpen] = useState(false);
    const [isTimeSheetUploadModalOpen, setIsTimeSheetUploadModalOpen] = useState(false);
    const [isGridTimeSheetDataRefreshNeeded, setIsGridTimeSheetDataRefreshNeeded] = useState(true);
    const {
        data: timeSheetData,
        error: errorGetTimeSheetData,
        sendRequest: getTimeSheetsForYear,
    } = useHttp();
    const {
        data: totalFlexAndApprovedOvertimeHoursData,
        error: errorGetTotalFlexAndApprovedOvertimeHoursData,
        sendRequest: getTotalFlexAndApprovedOvertimeHours,
    } = useHttp();

    const StyledCells = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'space-between',
    }));

    useEffect(() => {
        const fetchData = async () => {
            if (isGridTimeSheetDataRefreshNeeded) {
                await getTimeSheetsForYear({
                    url: `${DEFAULT_TIME_SHEET_URL}/employee${passedEmployeeId ? `/${passedEmployeeId}` : ''
                        }?year=${year}`,
                });
                await getTotalFlexAndApprovedOvertimeHours({
                    url: `${DEFAULT_TIME_SHEET_URL}/total-flex-overtime-hours/employee${passedEmployeeId ? `/${passedEmployeeId}` : ''
                        }?year=${year}`,
                });
                setIsGridTimeSheetDataRefreshNeeded(false);
            }
        };
        fetchData();
    }, [isGridTimeSheetDataRefreshNeeded, year]);

    useEffect(() => {
        const handleErrors = (error, errorMessage) => {
            if (error !== null) {
                showSnackbar(errorMessage, 'error');
            }
        };

        handleErrors(errorGetTimeSheetData, 'Failed to load time sheet data.');
        handleErrors(
            errorGetTotalFlexAndApprovedOvertimeHoursData,
            'Failed to load total flex and approved overtime hours.'
        );
    }, [errorGetTimeSheetData, errorGetTotalFlexAndApprovedOvertimeHoursData]);

    const isTotalFlexAndApprovedOvertimeHoursDataArray = Array.isArray(
        totalFlexAndApprovedOvertimeHoursData
    );

    const viewOnly = passedEmployeeId ? true : false;
    const columnsForTimeSheet = generateTimeSheetColumns(viewOnly);
    const employeeMap = new Map();

    if (isTotalFlexAndApprovedOvertimeHoursDataArray) {
        timeSheetData.forEach((item) => {
            const { employeeId, id, employeeName, month, flexHours, year } = item;

            let employee = employeeMap.get(employeeId);

            if (!employee) {
                employee = {
                    id,
                    employeeName,
                    employeeId,
                    year,
                };
                employeeMap.set(employeeId, employee);
            }

            const selectedData = totalFlexAndApprovedOvertimeHoursData.find(
                (entry) => entry.employeeId === employeeId
            );

            employee[month] = flexHours || 0;
            employee.totalFlexHours = selectedData?.totalFlexHours ?? 0;
            employee.totalApprovedOvertimeHours = selectedData?.totalApprovedOvertimeHours ?? 0;
        });
    }

    const allTimeSheetsTableData = Array.from(employeeMap.values());

    const filteredTimeSheetsData = filter
        ? allTimeSheetsTableData.filter((timeSheet) => {
            return timeSheet.employeeName.toLowerCase().includes(filter.toLowerCase());
        })
        : allTimeSheetsTableData;

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleImportTimeSheetClick = () => {
        setIsTimeSheetUploadModalOpen(true);
    };

    const handleTimeSheetModalClose = () => {
        setIsTimeSheetModalOpen(false);
    };

    const handleTimeSheetUploadModalClose = () => {
        setIsTimeSheetUploadModalOpen(false);
    };

    const handleTimeSheetUploadModalSuccess = () => {
        setIsGridTimeSheetDataRefreshNeeded(true);
    };

    const handleTimeSheetModalSuccess = (message, severity) => {
        setIsGridTimeSheetDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleTimeSheetCellClick = (params) => {
        const { row, colDef } = params;
        if (row && colDef) {
            const month = parseInt(colDef.field) ?? undefined;
            if (month) {
                const selectedData = timeSheetData.find(
                    (entry) => entry.employeeId === row.employeeId && entry.month === month
                );

                if (selectedData) {
                    setTimeSheetId(selectedData.id);
                    setIsTimeSheetModalOpen(true);
                } else {
                    if (!passedEmployeeId) {
                        setTimeSheetId(null);
                        setEmployeeId(row.employeeId);
                        setYear(row.year);
                        setMonth(parseInt(colDef.field));
                        setIsTimeSheetModalOpen(true);
                    }
                }
            }
        }
    };

    const handleYearChange = (direction) => {
        const newYear =
            direction === 'next' ? year + 1 : direction === 'previous' ? year - 1 : year;

        const prevYearDisabled = newYear === FIRST_YEAR;
        const nextYearDisabled = newYear === currentDate.getFullYear();

        setYear(newYear);
        setPrevYearDisabled(prevYearDisabled);
        setNextYearDisabled(nextYearDisabled);

        setIsGridTimeSheetDataRefreshNeeded(true);
    };

    const renderContent = () => {
        const showImportButton = !passedEmployeeId;
        return (
            <>
                <div>
                    <StyledCells>
                        <h1>Time sheets</h1>
                        {showImportButton && (
                            <Button onClick={handleImportTimeSheetClick} variant="contained">
                                + Import time sheets
                            </Button>
                        )}
                    </StyledCells>
                    {renderSearchTextField && renderSearchTextField()}
                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                        <Box
                            style={{
                                display: 'flex',
                                marginLeft: '16px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 16,
                                    marginTop: renderSearchTextField ? 0 : 16,
                                }}
                            >
                                <IconButton
                                    variant="switchingMonth"
                                    onClick={() => handleYearChange('previous')}
                                    disabled={prevYearDisabled}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography
                                    color="secondary"
                                    style={{
                                        marginLeft: 16,
                                        marginRight: 16,
                                        fontSize: 24,
                                    }}
                                >
                                    {year}
                                </Typography>
                                <IconButton
                                    variant="switchingMonth"
                                    onClick={() => handleYearChange('next')}
                                    disabled={nextYearDisabled}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box display="flex-start" width="100%">
                            <CommonDataGrid
                                rows={filteredTimeSheetsData}
                                columns={columnsForTimeSheet.filter(
                                    (column) =>
                                        column.field !== 'employeeId' && column.field !== 'year'
                                )}
                                onCellClick={handleTimeSheetCellClick}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return (
        <>
            {renderContent()}
            {isTimeSheetModalOpen && (
                <TimeSheetModal
                    open={isTimeSheetModalOpen}
                    onClose={handleTimeSheetModalClose}
                    onSuccess={handleTimeSheetModalSuccess}
                    timeSheetId={timeSheetId}
                    employeeId={employeeId}
                    year={year}
                    month={month}
                    viewOnly={!!passedEmployeeId}
                />
            )}
            {isTimeSheetUploadModalOpen && (
                <TimeSheetUploadModal
                    open={isTimeSheetUploadModalOpen}
                    onClose={handleTimeSheetUploadModalClose}
                    onSuccess={handleTimeSheetUploadModalSuccess}
                />
            )}
        </>
    );
};

TimeSheet.propTypes = {
    filter: PropTypes.string,
    renderSearchTextField: PropTypes.func,
    passedEmployeeId: PropTypes.string,
};
