import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Box, TextField, Button, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useHttp from 'hooks/useHttp';
import { DEFAULT_TIME_SHEET_URL } from 'api/timeSheet';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { timeSheetModalValidationSchema } from 'utils/validationSchemas';
import { HTTP_METHODS } from 'constants';

export const TimeSheetModal = ({
    open,
    onClose,
    onSuccess,
    timeSheetId,
    employeeId,
    year,
    month,
    viewOnly,
}) => {
    const topElementRef = useRef();
    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: createTimeSheetRecord,
        loading: loadingCreate,
    } = useHttp();
    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateTimeSheetRecord,
        loading: loadingUpdate,
    } = useHttp();
    const {
        data: timeSheetData,
        error: errorGetTimeSheet,
        sendRequest: getTimeSheetData,
        loading: loadingData,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title] = useState(() => {
        return viewOnly
            ? ''
            : timeSheetId
            ? 'Update time sheet record'
            : 'Add new time sheet record';
    });
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(timeSheetModalValidationSchema),
        defaultValues: {
            employeeId: employeeId ? employeeId : '',
            year: year ? year : '',
            month: month ? month : '',
            totalWorkingHours: 0,
            pxRegisteredHours: 0,
            flexHours: 0,
            approvedOvertime: 0,
        },
    });

    useEffect(() => {
        if (timeSheetId) {
            (async () => {
                await getTimeSheetData({
                    url: `${DEFAULT_TIME_SHEET_URL}/${timeSheetId}`,
                });
            })();
        }
    }, [timeSheetId]);

    useEffect(() => {
        if (errorGetTimeSheet !== null) {
            setResponseMessage(errorGetTimeSheet.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
    }, [errorGetTimeSheet]);

    useEffect(() => {
        if (timeSheetData) {
            reset(timeSheetData);
        }
    }, [timeSheetData, reset]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            onSuccess(`Time sheet record created successfully.`, 'success');
            onClose();
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(`Time sheet record updated successfully.`, 'success');
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    const onSubmit = async (formData) => {
        if (viewOnly) {
            return;
        }

        if (timeSheetId) {
            await updateTimeSheetRecord({
                url: `${DEFAULT_TIME_SHEET_URL}/${timeSheetId}`,
                method: HTTP_METHODS.PUT,
                data: formData,
            });
        } else {
            await createTimeSheetRecord({
                url: `${DEFAULT_TIME_SHEET_URL}`,
                method: HTTP_METHODS.POST,
                data: formData,
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container width={400} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="year"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Year"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            disabled={true}
                                            error={!!errors.year}
                                            helperText={errors.year ? errors.year.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="month"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Month"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            disabled={true}
                                            error={!!errors.month}
                                            helperText={errors.month ? errors.month.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Controller
                            control={control}
                            name="totalWorkingHours"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="Total Working Hours"
                                    type="number"
                                    margin="dense"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    fullWidth
                                    required
                                    disabled={viewOnly}
                                    error={!!errors.totalWorkingHours}
                                    helperText={
                                        errors.totalWorkingHours
                                            ? errors.totalWorkingHours.message
                                            : ''
                                    }
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="pxRegisteredHours"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextField
                                    label="PX Resgitered hours"
                                    type="number"
                                    margin="dense"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    fullWidth
                                    required
                                    disabled={viewOnly}
                                    error={!!errors.pxRegisteredHours}
                                    helperText={
                                        errors.pxRegisteredHours
                                            ? errors.pxRegisteredHours.message
                                            : ''
                                    }
                                />
                            )}
                        />

                        <Grid container marginTop={'16px'}>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="flexHours"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Flex Hours"
                                            type="number"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            disabled={viewOnly}
                                            error={!!errors.flexHours}
                                            helperText={
                                                errors.flexHours ? errors.flexHours.message : ''
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="approvedOvertime"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <TextField
                                            label="Approved overtime"
                                            type="number"
                                            margin="dense"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            fullWidth
                                            required
                                            disabled={viewOnly}
                                            error={!!errors.approvedOvertime}
                                            helperText={
                                                errors.approvedOvertime
                                                    ? errors.approvedOvertime.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {!viewOnly && (
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isLoading(loadingData, loadingCreate, loadingUpdate)}
                                >
                                    {timeSheetId ? 'Update' : 'Add'}
                                </Button>
                            </Box>
                        )}
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

TimeSheetModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    timeSheetId: PropTypes.string,
    employeeId: PropTypes.string,
    year: PropTypes.number,
    month: PropTypes.number,
    viewOnly: PropTypes.bool,
};
