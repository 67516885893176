import React, { useEffect, useState } from 'react';
import { Box, Button, styled, Checkbox } from '@mui/material';
import useHttp from 'hooks/useHttp';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { DEFAULT_INVENTORY_ITEM_URL } from 'api/inventoryItem';
import {
    inventoryItemsColumns,
    renderDeleteReactivateButton,
    renderEditButton,
    renderActiveCell,
} from 'utils/dataColumns/inventoryItemColumns';
import PropTypes from 'prop-types';
import { InventoryItemModal } from 'components/InventoryItemModal';
import { DeleteInventoryItemModal } from 'components/DeleteInventoryItemModal';
import { ReactivateInventoryItemModal } from 'components/ReactivateInventoryItemModal';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { format } from 'date-fns';
import { DATE_FORMAT, HTTP_METHODS } from 'constants';
import { INVENTORY_ITEM_STATUS } from 'constants';
import { GridActionsCellItem } from '@mui/x-data-grid';
import GroupIcon from '@mui/icons-material/Group';
import HistoryIcon from '@mui/icons-material/History';
import { AssignInventoryItemModal } from 'components/AssignInventoryItemModal';
import { UnassignInventoryItemModal } from 'components/UnassignInventoryItemModal';
import InventoryItemHistory from 'components/InventoryItemHistoryModal';
import { InventoryAssignmentFormGenerateModal } from 'components/InventoryAssignmentFormGenerateModal';
import { InventoryAssignmentFormUploadModal } from 'components/InventoryAssignmentFormUploadModal';
import { replaceNullValues } from 'utils/misc';

const InventoryItem = ({ filter, showInactive, renderSearchTextField }) => {
    const [inventoryItemId, setInventoryItemId] = useState('');
    const [snackbars, setSnackbars] = useState([]);
    const [isInventoryItemModalOpen, setIsInventoryItemModalOpen] = useState(false);
    const [isDeleteInventoryItemModalOpen, setIsDeleteInventoryItemModalOpen] = useState(false);
    const [isReactivateInventoryItemModalOpen, setIsReactivateInventoryItemModalOpen] =
        useState(false);
    const [isAssignInventoryItemModalOpen, setIsAssignInventoryItemModalOpen] = useState(false);
    const [isUnassignInventoryItemModalOpen, setIsUnassignInventoryItemModalOpen] = useState(false);
    const [isInventoryItemHistoryModalOpen, setIsInventoryItemHistoryModalOpen] = useState(false);
    const [
        isInventoryAssignmentFormGenerateModalOpen,
        setIsInventoryAssignmentFormGenerateModalOpen,
    ] = useState(false);
    const [isInventoryAssignmentFormUploadModalOpen, setIsInventoryAssignmentFormUploadModalOpen] =
        useState(false);
    const [columnsForInventoryItem, setColumnsForInventoryItem] = useState([]);
    const [inventoryItemsDataTable, setInventoryItemsDataTable] = useState([]);
    const [filteredInventoryItemsData, setFilteredInventoryItemsData] = useState([]);
    const [type, setType] = useState([]);
    const [menuItems, setMenuItems] = useState([]);

    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);
    const {
        data: allInventoryItemsData,
        error: errorGetInventoryItems,
        sendRequest: getInventoryItems,
    } = useHttp();
    const {
        data: dataGenerateInventoryAssignmentForm,
        error: errorGenerateInventoryAssignmentForm,
        clearData: clearDataGenerateInventoryAssignmentForm,
        sendRequest: generateInventoryAssignmentForm,
        loading: loadingGenerateInventoryAssignmentForm,
    } = useHttp();
    const [override, setOverride] = useState(false);

    const StyledCells = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'space-between',
    }));

    useEffect(() => {
        const items = [
            {
                label: 'Generate assignment form',
                action: () => handleGenerateAssignmentForm(),
            },
            {
                label: 'Upload signed assignment form',
                action: () => handleInventoryAssignmentFormUpload(),
            },
            {
                label: 'Generate unassignment form',
                action: () => handleGenerateUnassignmentForm(),
            },
            {
                label: 'Upload signed unassignment form',
                action: () => handleInventoryUnassignmentFormUpload(),
            },
        ];

        const selectedIds = inventoryItemsDataTable
            .filter((item) => item.selected)
            .map((item) => item.id);

        if (selectedIds.length > 0) {
            setMenuItems(items);
        } else {
            setMenuItems([]);
        }
    }, [inventoryItemsDataTable]);

    useEffect(() => {
        if (isGridDataRefreshNeeded) {
            getInventoryItems({
                url: `${DEFAULT_INVENTORY_ITEM_URL}?includeWrittenOff=true`,
            });
            setIsGridDataRefreshNeeded(false);
        }
    }, [isGridDataRefreshNeeded]);

    useEffect(() => {
        const handleErrors = (error, errorMessage) => {
            if (error !== null) {
                showSnackbar(errorMessage, 'error');
            }
        };

        handleErrors(errorGetInventoryItems, 'Failed to load inventory items.');
    }, [errorGetInventoryItems]);

    useEffect(() => {
        setColumnsForInventoryItem([
            {
                field: 'selected',
                headerName: 'Select',
                type: 'checkbox',
                width: 100,
                renderCell: (params) => (
                    <Checkbox
                        checked={params.value}
                        onChange={(event) =>
                            handleCheckboxChange(params.row.id, event.target.checked)
                        }
                    />
                ),
            },
            ...inventoryItemsColumns.map((column) => {
                if (column.field === 'edit') {
                    return {
                        ...column,
                        renderCell: (params) =>
                            renderEditButton(
                                params,
                                setInventoryItemId,
                                setIsInventoryItemModalOpen
                            ),
                    };
                } else if (column.field === 'delete-reactivate') {
                    return {
                        ...column,
                        renderCell: (params) =>
                            renderDeleteReactivateButton(
                                params,
                                setInventoryItemId,
                                setIsDeleteInventoryItemModalOpen,
                                setIsReactivateInventoryItemModalOpen
                            ),
                    };
                }
                return column;
            }),
            {
                field: 'actions',
                type: 'actions',
                width: 0.5,
                getActions: (params) => [
                    params.row.status !== INVENTORY_ITEM_STATUS.writtenOff ? (
                        <GridActionsCellItem
                            key="assign-item"
                            icon={<GroupIcon />}
                            label={
                                params.row.status === INVENTORY_ITEM_STATUS.inUse
                                    ? 'Unassign item'
                                    : 'Assign item'
                            }
                            onClick={
                                params.row.status === INVENTORY_ITEM_STATUS.inUse
                                    ? handleUnassignInventoyItem(params.row.id)
                                    : handleAssignInventoyItem(params.row.id)
                            }
                            showInMenu={params.row.status !== INVENTORY_ITEM_STATUS.writtenOff}
                        />
                    ) : (
                        <></>
                    ),
                    <GridActionsCellItem
                        key="item-history"
                        icon={<HistoryIcon />}
                        label="View history"
                        onClick={handleShowInventoyItemHistoryModal(params.row.id)}
                        showInMenu
                    />,
                ],
            },
        ]);
    }, []);

    useEffect(() => {
        if (allInventoryItemsData) {
            setInventoryItemsDataTable([
                ...allInventoryItemsData.map((item) => ({
                    id: item.id,
                    name: item.name,
                    status: `${item.status}`,
                    state: `${item.state}`,
                    serialNumber: replaceNullValues(item.serialNumber),
                    inventoryNumber: replaceNullValues(item.inventoryNumber),
                    description: replaceNullValues(item.description),
                    assignee: replaceNullValues(item.assigneeName),
                    type: item.typeName,
                    dateOfPurchase: format(
                        item.dateOfPurchase
                            ? new Date(item.dateOfPurchase).setHours(0, 0, 0, 0)
                            : null,
                        DATE_FORMAT
                    ),
                    specifications: replaceNullValues(item.specifications),
                    filledAssignmentFormLink: item.filledAssignmentFormLink,
                    signedAssignmentFormLink: item.signedAssignmentFormLink,
                    filledUnassignmentFormLink: item.filledUnassignmentFormLink,
                    signedUnassignmentFormLink: item.signedUnassignmentFormLink,

                    selected: false,
                })),
            ]);
        }
    }, [allInventoryItemsData]);

    useEffect(() => {
        setFilteredInventoryItemsData(
            inventoryItemsDataTable.filter((item) => {
                const filterToLower = filter.toLowerCase();
                return (
                    (showInactive
                        ? item.status === INVENTORY_ITEM_STATUS.writtenOff
                        : item.status !== INVENTORY_ITEM_STATUS.writtenOff) &&
                    (item.name.toLowerCase().includes(filterToLower) ||
                        item.serialNumber.toLowerCase().includes(filterToLower) ||
                        item.inventoryNumber.toLowerCase().includes(filterToLower) ||
                        item.description.toLowerCase().includes(filterToLower) ||
                        item.specifications.toLowerCase().includes(filterToLower) ||
                        item.status.toLowerCase().includes(filterToLower) ||
                        item.state.toLowerCase().includes(filterToLower) ||
                        item.dateOfPurchase.toLowerCase().includes(filterToLower) ||
                        item.type.toLowerCase().includes(filterToLower) ||
                        item.assignee.toLowerCase().includes(filterToLower))
                );
            })
        );
    }, [filter, showInactive, inventoryItemsDataTable]);

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleAddNewInventoyItem = () => {
        setInventoryItemId('');
        setIsInventoryItemModalOpen(true);
    };

    const handleCreateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleDeleteSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleReactivateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        if (message) {
            showSnackbar(message, severity);
        }
    };

    const handleInventoryAssignmentFormGenerateSuccess = () => {
        setIsGridDataRefreshNeeded(true);
    };

    const handleInventoryAssignmentFormUploadModalSuccess = () => {
        setIsGridDataRefreshNeeded(true);
    };

    const handleInventoryItemModalClose = () => {
        setIsInventoryItemModalOpen(false);
    };

    const handleDeleteInventoryItemModalClose = () => {
        setIsDeleteInventoryItemModalOpen(false);
    };

    const handleReactivateInventoryItemModalClose = () => {
        setIsReactivateInventoryItemModalOpen(false);
    };

    const handleAssignInventoyItem = (id) => () => {
        setInventoryItemId(id);
        setIsAssignInventoryItemModalOpen(true);
    };

    const handleAssignInventoryItemModalClose = () => {
        setIsAssignInventoryItemModalOpen(false);
    };

    const handleAssignSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        if (message) {
            showSnackbar(message, severity);
        }
    };

    const handleUnassignInventoyItem = (id) => () => {
        setInventoryItemId(id);
        setIsUnassignInventoryItemModalOpen(true);
    };

    const handleUnassignInventoryItemModalClose = () => {
        setIsUnassignInventoryItemModalOpen(false);
    };

    const handleShowInventoyItemHistoryModal = (id) => () => {
        setInventoryItemId(id);
        setIsInventoryItemHistoryModalOpen(true);
    };

    const handleShowInventoyItemHistoryModalClose = () => {
        setIsInventoryItemHistoryModalOpen(false);
    };

    const handleInventoryAssignmentFormGenerateModalClose = () => {
        setIsInventoryAssignmentFormGenerateModalOpen(false);
    };

    const handleInventoryAssignmentFormUploadModalClose = () => {
        setIsInventoryAssignmentFormUploadModalOpen(false);
    };

    const handleUnassignSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        if (message) {
            showSnackbar(message, severity);
        }
    };

    const handleCheckboxChange = (id, checked) => {
        setInventoryItemsDataTable((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, selected: checked } : item))
        );
    };

    const handleGenerateAssignmentForm = () => {
        setIsInventoryAssignmentFormGenerateModalOpen(true);
        setType('assignment');
    };

    const handleGenerateUnassignmentForm = () => {
        setIsInventoryAssignmentFormGenerateModalOpen(true);
        setType('unassignment');
    };

    const handleInventoryAssignmentFormUpload = () => {
        setIsInventoryAssignmentFormUploadModalOpen(true);
        setType('assignment');
    };

    const handleInventoryUnassignmentFormUpload = () => {
        setIsInventoryAssignmentFormUploadModalOpen(true);
        setType('unassignment');
    };

    const renderContent = () => {
        return (
            <>
                <div>
                    <StyledCells>
                        <h1>Inventory items</h1>
                        <Button onClick={handleAddNewInventoyItem} variant="contained">
                            + Add new inventory item
                        </Button>
                    </StyledCells>
                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                        {renderSearchTextField('Show written off inventory items')}
                        <Box display="flex-start" width="100%">
                            <CommonDataGrid
                                rows={filteredInventoryItemsData}
                                columns={columnsForInventoryItem.filter(
                                    (column) => column.field !== 'id'
                                )}
                                menuItems={menuItems}
                            />
                        </Box>
                    </div>
                </div>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return (
        <>
            {renderContent()}
            {isInventoryItemModalOpen && (
                <InventoryItemModal
                    open={isInventoryItemModalOpen}
                    onClose={handleInventoryItemModalClose}
                    onSuccess={handleCreateSuccess}
                    itemId={inventoryItemId}
                />
            )}
            {isDeleteInventoryItemModalOpen && (
                <DeleteInventoryItemModal
                    open={isDeleteInventoryItemModalOpen}
                    onClose={handleDeleteInventoryItemModalClose}
                    onSuccess={handleDeleteSuccess}
                    itemId={inventoryItemId}
                />
            )}
            {isReactivateInventoryItemModalOpen && (
                <ReactivateInventoryItemModal
                    open={isReactivateInventoryItemModalOpen}
                    onClose={handleReactivateInventoryItemModalClose}
                    onSuccess={handleReactivateSuccess}
                    itemId={inventoryItemId}
                />
            )}
            {isAssignInventoryItemModalOpen && (
                <AssignInventoryItemModal
                    open={isAssignInventoryItemModalOpen}
                    onClose={handleAssignInventoryItemModalClose}
                    onSuccess={handleAssignSuccess}
                    itemId={inventoryItemId}
                />
            )}
            {isUnassignInventoryItemModalOpen && (
                <UnassignInventoryItemModal
                    open={isUnassignInventoryItemModalOpen}
                    onClose={handleUnassignInventoryItemModalClose}
                    onSuccess={handleUnassignSuccess}
                    itemId={inventoryItemId}
                />
            )}
            {isInventoryItemHistoryModalOpen && (
                <InventoryItemHistory
                    open={isInventoryItemHistoryModalOpen}
                    onClose={handleShowInventoyItemHistoryModalClose}
                    onSuccess={handleShowInventoyItemHistoryModal}
                    itemId={inventoryItemId}
                />
            )}
            {isInventoryAssignmentFormGenerateModalOpen && (
                <InventoryAssignmentFormGenerateModal
                    open={isInventoryAssignmentFormGenerateModalOpen}
                    onClose={handleInventoryAssignmentFormGenerateModalClose}
                    onSuccess={handleInventoryAssignmentFormGenerateSuccess}
                    selectedIds={inventoryItemsDataTable
                        .filter((item) => item.selected)
                        .map((item) => item.id)}
                    type={type}
                />
            )}
            {isInventoryAssignmentFormUploadModalOpen && (
                <InventoryAssignmentFormUploadModal
                    open={isInventoryAssignmentFormUploadModalOpen}
                    onClose={handleInventoryAssignmentFormUploadModalClose}
                    onSuccess={handleInventoryAssignmentFormUploadModalSuccess}
                    selectedIds={inventoryItemsDataTable
                        .filter((item) => item.selected)
                        .map((item) => item.id)}
                    type={type}
                />
            )}
        </>
    );
};

export default InventoryItem;

InventoryItem.propTypes = {
    filter: PropTypes.string.isRequired,
    showInactive: PropTypes.bool.isRequired,
    renderSearchTextField: PropTypes.func.isRequired,
};
