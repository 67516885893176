import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import useHttp from 'hooks/useHttp';
import PropTypes from 'prop-types';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'constants';
import { DEFAULT_ASSIGNEE_INVENTORY_URL } from 'api/assigneeInventory';
import { MessageBox } from './MessageBox/MessageBox';
import CloseIcon from '@mui/icons-material/Close';
import { inventoryItemHistoryColumns } from 'utils/dataColumns/inventoryItemHistoryColumns';
import { REPLACE_NULL_VALUE } from 'constants';

const TITLE = 'Inventory item history';

const InventoryItemHistory = ({ open, onClose, itemId }) => {
    const topElementRef = useRef();

    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');

    const [inventoryItemsDataTable, setInventoryItemsDataTable] = useState([]);

    const {
        data: inventoryItemHistory,
        error: errorGetInventoryItem,
        sendRequest: getInventoryItemHistory,
    } = useHttp();

    useEffect(() => {
        (async () => {
            getInventoryItemHistory({
                url: `${DEFAULT_ASSIGNEE_INVENTORY_URL}/${itemId}?includeHistory=true`,
            });
        })();
    }, []);

    useEffect(() => {
        if (errorGetInventoryItem !== null) {
            setResponseMessage(
                errorGetInventoryItem.response?.data?.message || 'An error occurred'
            );
            setMessageType('error');
        }
    }, [errorGetInventoryItem]);

    useEffect(() => {
        if (inventoryItemHistory) {
            setInventoryItemsDataTable([
                ...inventoryItemHistory.map((assigneeInventory) => ({
                    id: assigneeInventory.id,
                    assignee: assigneeInventory.employee
                        ? `${assigneeInventory.employee.firstName} ${assigneeInventory.employee.lastName}`
                        : assigneeInventory.team.name,
                    startDate: format(
                        assigneeInventory.startDate
                            ? new Date(assigneeInventory.startDate).setHours(0, 0, 0, 0)
                            : null,
                        DATE_FORMAT
                    ),
                    endDate: assigneeInventory.endDate
                        ? format(
                              assigneeInventory.endDate
                                  ? new Date(assigneeInventory.endDate).setHours(0, 0, 0, 0)
                                  : null,
                              DATE_FORMAT
                          )
                        : REPLACE_NULL_VALUE,
                    filledAssignmentFormLink:
                        assigneeInventory?.inventoryAssignmentForm?.filledDocumentLink,
                    signedAssignmentFormLink:
                        assigneeInventory?.inventoryAssignmentForm?.signedDocumentLink,
                    filledUnassignmentFormLink:
                        assigneeInventory?.inventoryUnassignmentForm?.filledDocumentLink,
                    signedUnassignmentFormLink:
                        assigneeInventory?.inventoryUnassignmentForm?.signedDocumentLink,
                })),
            ]);
        }
    }, [inventoryItemHistory]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    <>
                        {messageType !== 'none' && (
                            <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                        )}
                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                            <Box display="flex-start" width="100%">
                                <CommonDataGrid
                                    rows={inventoryItemsDataTable}
                                    columns={inventoryItemHistoryColumns.filter(
                                        (column) => column.field !== 'id'
                                    )}
                                />
                            </Box>
                        </div>
                    </>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default InventoryItemHistory;

InventoryItemHistory.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    itemId: PropTypes.string,
};
