import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { employeeInformationModalValidationSchema } from 'utils/validationSchemas';
import { HTTP_METHODS } from 'constants';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import { format } from 'date-fns';
import { DATE_FORMAT_BACKEND } from 'constants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DATE_FORMAT } from 'constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';

export const EmployeeInformationModal = ({
    open,
    onClose,
    onSuccess,
    employeeId,
    employeeInformationId,
}) => {
    const topElementRef = useRef();
    const {
        data: employeeInfoData,
        loading: loadingEmployeeInfoData,
        error: errorGetEmployeeInfo,
        sendRequest: getEmployeeInfo,
    } = useHttp();

    const {
        data: dataUpdate,
        error: errorUpdate,
        clearData: clearDataUpdate,
        sendRequest: updateEmployeeInfo,
        loading: loadingUpdate,
    } = useHttp();

    const {
        data: dataCreate,
        error: errorCreate,
        clearData: clearDataCreate,
        sendRequest: createEmployeeInformation,
        loading: loadingCreate,
    } = useHttp();

    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [title] = useState(
        employeeInformationId ? 'Update Employee information' : 'Add new Employee information'
    );

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(employeeInformationModalValidationSchema),
        defaultValues: {
            softhousePhoneNumber: '',
            privatePhoneNumber: '',
            cipsAddress: '',
            residenceAddress: '',
            familyMembers: 0,
            firstEmergencyContactName: '',
            firstEmergencyContactRelation: '',
            firstEmergencyContactPhoneNumber: '',
            secondEmergencyContactName: '',
            secondEmergencyContactRelation: '',
            secondEmergencyContactPhoneNumber: '',
            dateOfBirth: null,
            jmbg: '',
            passportNumber: '',
            passportIssueDate: null,
            passportExpirationDate: null,
            passportIssuedIn: '',
            allergies: '',
        },
    });

    useEffect(() => {
        if (employeeInformationId) {
            getEmployeeInfo({
                url: `${DEFAULT_EMPLOYEE_URL}/employeeinformation/${employeeId}`,
            });
        }
    }, []);

    useEffect(() => {
        if (errorGetEmployeeInfo !== null) {
            setResponseMessage(errorGetEmployeeInfo.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
    }, [errorGetEmployeeInfo]);

    useEffect(() => {
        if (employeeInfoData) {
            reset({
                ...employeeInfoData,
                dateOfBirth: employeeInfoData.dateOfBirth
                    ? new Date(employeeInfoData.dateOfBirth)
                    : null,
                passportIssueDate: employeeInfoData.passportIssueDate
                    ? new Date(employeeInfoData.passportIssueDate)
                    : null,
                passportExpirationDate: employeeInfoData.passportExpirationDate
                    ? new Date(employeeInfoData.passportExpirationDate)
                    : null,
            });
        }
    }, [employeeInfoData, reset]);

    useEffect(() => {
        clearDataCreate();

        if (errorCreate !== null) {
            setResponseMessage(errorCreate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataCreate) {
            onSuccess(`Employee information created successfully.`, 'success');
            onClose();
        }
    }, [errorCreate, dataCreate, messageType]);

    useEffect(() => {
        clearDataUpdate();

        if (errorUpdate !== null) {
            setResponseMessage(errorUpdate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataUpdate) {
            onSuccess(`Employee information updated successfully.`, 'success');
            onClose();
        }
    }, [errorUpdate, dataUpdate, messageType]);

    const onSubmit = async (formData) => {
        const requestBody = {
            ...formData,
            dateOfBirth: format(formData.dateOfBirth, DATE_FORMAT_BACKEND),
            passportIssueDate: format(formData.passportIssueDate, DATE_FORMAT_BACKEND),
            passportExpirationDate: format(formData.passportExpirationDate, DATE_FORMAT_BACKEND),
        };

        if (employeeInformationId) {
            await updateEmployeeInfo({
                url: `${DEFAULT_EMPLOYEE_URL}/employeeinformation/${employeeInformationId}`,
                method: HTTP_METHODS.PUT,
                data: requestBody,
            });
        } else {
            await createEmployeeInformation({
                url: `${DEFAULT_EMPLOYEE_URL}/employeeinformation/${employeeId}`,
                method: HTTP_METHODS.POST,
                data: requestBody,
            });
        }
        scrollIntoView(topElementRef);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {title}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {loadingEmployeeInfoData ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Box>
                                    <Typography
                                        sx={{ fontSize: '18px', margin: '16px 0px' }}
                                        variant="boldText"
                                    >{`Personal information`}</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                                adapterLocale={enGB}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="dateOfBirth"
                                                    render={({
                                                        field: { onChange, onBlur, value },
                                                    }) => (
                                                        <DatePicker
                                                            label="Date Of Birth"
                                                            value={value}
                                                            onChange={onChange}
                                                            format={DATE_FORMAT}
                                                            slotProps={{
                                                                textField: {
                                                                    onBlur: onBlur,
                                                                    required: true,
                                                                    margin: 'dense',
                                                                    fullWidth: true,
                                                                    error: !!errors.dateOfBirth,
                                                                    helperText: errors.dateOfBirth
                                                                        ? errors.dateOfBirth.message
                                                                        : '',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="jmbg"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="JMBG"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.jmbg}
                                                        helperText={
                                                            errors.jmbg ? errors.jmbg.message : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="allergies"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Allergies"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={
                                                            !!errors.allergies
                                                        }
                                                        helperText={
                                                            errors.allergies
                                                                ? errors
                                                                    .allergies
                                                                    .message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{ fontSize: '18px', margin: '16px 0px' }}
                                        variant="boldText"
                                    >{`Phone numbers`}</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="privatePhoneNumber"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Private phone number"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.privatePhoneNumber}
                                                        helperText={
                                                            errors.privatePhoneNumber
                                                                ? errors.privatePhoneNumber.message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="softhousePhoneNumber"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Softhouse phone number"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.softhousePhoneNumber}
                                                        helperText={
                                                            errors.softhousePhoneNumber
                                                                ? errors.softhousePhoneNumber
                                                                    .message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box>
                                    <Typography
                                        sx={{ fontSize: '18px', margin: '16px 0px' }}
                                        variant="boldText"
                                    >{`Adresses`}</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="cipsAddress"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="CIPS Address"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.cipsAddress}
                                                        helperText={
                                                            errors.cipsAddress
                                                                ? errors.cipsAddress.message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="residenceAddress"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Residence Address"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.residenceAddress}
                                                        helperText={
                                                            errors.residenceAddress
                                                                ? errors.residenceAddress.message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box>
                                    <Typography
                                        sx={{ fontSize: '18px', margin: '16px 0px' }}
                                        variant="boldText"
                                    >{`Passport`}</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="passportNumber"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Passport Number"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.passportNumber}
                                                        helperText={
                                                            errors.passportNumber
                                                                ? errors.passportNumber.message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="passportIssuedIn"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Passport Issued In"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.passportIssuedIn}
                                                        helperText={
                                                            errors.passportIssuedIn
                                                                ? errors.passportIssuedIn.message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={enGB}
                                        >
                                            <Grid item xs={6}>
                                                <Controller
                                                    control={control}
                                                    name="passportIssueDate"
                                                    render={({
                                                        field: { onChange, onBlur, value },
                                                    }) => (
                                                        <DatePicker
                                                            label="Issue Date"
                                                            value={value}
                                                            onChange={onChange}
                                                            format={DATE_FORMAT}
                                                            slotProps={{
                                                                textField: {
                                                                    onBlur: onBlur,
                                                                    required: true,
                                                                    fullWidth: true,
                                                                    error: !!errors.passportIssueDate,
                                                                    helperText:
                                                                        errors.passportIssueDate
                                                                            ? errors
                                                                                .passportIssueDate
                                                                                .message
                                                                            : '',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Controller
                                                    control={control}
                                                    name="passportExpirationDate"
                                                    render={({
                                                        field: { onChange, onBlur, value },
                                                    }) => (
                                                        <DatePicker
                                                            label="Expiration Date"
                                                            value={value}
                                                            onChange={onChange}
                                                            format={DATE_FORMAT}
                                                            slotProps={{
                                                                textField: {
                                                                    onBlur: onBlur,
                                                                    required: true,
                                                                    fullWidth: true,
                                                                    error: !!errors.passportExpirationDate,
                                                                    helperText:
                                                                        errors.passportExpirationDate
                                                                            ? errors
                                                                                .passportExpirationDate
                                                                                .message
                                                                            : '',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                </Box>

                                <Box>
                                    <Typography
                                        sx={{ fontSize: '18px', margin: '16px 0px' }}
                                        variant="boldText"
                                    >{`First Emergency Contact`}</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="firstEmergencyContactName"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Name"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.firstEmergencyContactName}
                                                        helperText={
                                                            errors.firstEmergencyContactName
                                                                ? errors.firstEmergencyContactName
                                                                    .message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="firstEmergencyContactRelation"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Relation"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={
                                                            !!errors.firstEmergencyContactRelation
                                                        }
                                                        helperText={
                                                            errors.firstEmergencyContactRelation
                                                                ? errors
                                                                    .firstEmergencyContactRelation
                                                                    .message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="firstEmergencyContactPhoneNumber"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Phone Number"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={
                                                            !!errors.firstEmergencyContactPhoneNumber
                                                        }
                                                        helperText={
                                                            errors.firstEmergencyContactPhoneNumber
                                                                ? errors
                                                                    .firstEmergencyContactPhoneNumber
                                                                    .message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box>
                                    <Typography
                                        sx={{ fontSize: '18px', margin: '16px 0px' }}
                                        variant="boldText"
                                    >{`Second Emergency Contact`}</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="secondEmergencyContactName"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Name"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={!!errors.secondEmergencyContactName}
                                                        helperText={
                                                            errors.secondEmergencyContactName
                                                                ? errors.secondEmergencyContactName
                                                                    .message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="secondEmergencyContactRelation"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Relation"
                                                        margin="dense"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={
                                                            !!errors.secondEmergencyContactRelation
                                                        }
                                                        helperText={
                                                            errors.secondEmergencyContactRelation
                                                                ? errors
                                                                    .secondEmergencyContactRelation
                                                                    .message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="secondEmergencyContactPhoneNumber"
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                }) => (
                                                    <TextField
                                                        label="Phone Number"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        fullWidth
                                                        required
                                                        error={
                                                            !!errors.secondEmergencyContactPhoneNumber
                                                        }
                                                        helperText={
                                                            errors.secondEmergencyContactPhoneNumber
                                                                ? errors
                                                                    .secondEmergencyContactPhoneNumber
                                                                    .message
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )}

                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading(
                                    loadingEmployeeInfoData,
                                    loadingUpdate,
                                    loadingCreate
                                )}
                            >
                                {employeeInfoData ? 'Update' : 'Add'}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

EmployeeInformationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    employeeId: PropTypes.string.isRequired,
    employeeInformationId: PropTypes.string,
};
