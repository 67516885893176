import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageBox } from 'components/MessageBox/MessageBox';
import { AbsenceTypeModal } from 'components/AbsenceTypeModal';
import useHttp from 'hooks/useHttp';
import { scrollIntoView } from 'utils/scroll';
import { isLoading } from 'utils/misc';
import { DEFAULT_ABSENCE_TYPE_URL } from 'api/absenceType';
import { HTTP_METHODS } from 'constants';

const TITLE = 'Reactivate absence type';

export const ReactivateAbsenceTypeModal = ({ open, onClose, onSuccess, absenceTypeId }) => {
    const topElementRef = useRef();
    const {
        data: dataReactivate,
        error: errorReactivate,
        clearData: clearDataReactivate,
        sendRequest: reactivateAbsenceType,
        loading: loadingReactivate,
    } = useHttp();
    const {
        data: absenceTypeData,
        error: errorGetAbsenceType,
        sendRequest: getAnsenceTypeData,
        loading: loadingData,
    } = useHttp();
    const [messageType, setMessageType] = useState('none');
    const [responseMessage, setResponseMessage] = useState('');
    const [absenceType, setAbsenceType] = useState(null);
    const [isAbsenceTypeModalOpen, setIsAbsenceTypeModalOpen] = useState(false);

    useEffect(() => {
        if (absenceTypeId) {
            (async () => {
                await getAnsenceTypeData({
                    url: `${DEFAULT_ABSENCE_TYPE_URL}/${absenceTypeId}`,
                });
            })();
        }
    }, [absenceTypeId]);

    useEffect(() => {
        if (errorGetAbsenceType !== null) {
            setResponseMessage(errorGetAbsenceType.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }
    }, [errorGetAbsenceType]);

    useEffect(() => {
        if (absenceTypeData) {
            setAbsenceType(absenceTypeData);
        }
    }, [absenceTypeData]);

    useEffect(() => {
        clearDataReactivate();

        if (errorReactivate !== null) {
            setResponseMessage(errorReactivate.response?.data?.message || 'An error occurred');
            setMessageType('error');
        }

        if (dataReactivate) {
            setResponseMessage('Absence type reactivated successfully.');
            setMessageType('success');
            onSuccess();
        }
    }, [errorReactivate, dataReactivate, messageType]);

    const handleEditConfirmation = () => {
        setIsAbsenceTypeModalOpen(true);
    };

    const handleAbsenceTypeModalClose = () => {
        setIsAbsenceTypeModalOpen(false);
        onClose();
    };

    const handleReactivateConfirmation = async () => {
        const requestBody = {
            name: absenceType.name,
            active: true,
            available: absenceType.available,
            employeeSpecific: absenceType.employeeSpecific,
            color: absenceType.color,
        };

        if (absenceTypeId) {
            await reactivateAbsenceType({
                url: `${DEFAULT_ABSENCE_TYPE_URL}/${absenceTypeId}`,
                method: HTTP_METHODS.PUT,
                data: requestBody,
            });
        }
        scrollIntoView(topElementRef);
    };

    const handleUpdateSuccess = () => {
        onSuccess('Absence type updated successfully.', 'success');
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textTransform: 'none' }}>
                {TITLE}
                <Button onClick={onClose} variant="CloseButton">
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid width={500} ref={topElementRef}>
                    {messageType !== 'none' && (
                        <MessageBox messageType={messageType}>{responseMessage}</MessageBox>
                    )}
                    {messageType !== 'success' && (
                        <Grid item xs={5}>
                            <Typography>
                                Are you sure you want to reactivate absence type?
                            </Typography>
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                    disabled={isLoading(loadingData, loadingReactivate)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleReactivateConfirmation}
                                    variant="contained"
                                    color="error"
                                    disabled={isLoading(loadingData, loadingReactivate)}
                                >
                                    Reactivate
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    {messageType === 'success' && (
                        <Grid item xs={5}>
                            <Typography>Do you want to edit absence type?</Typography>
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{ marginRight: '8px' }}
                                >
                                    Cancel
                                </Button>
                                <Button onClick={handleEditConfirmation} variant="contained">
                                    Edit
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    {isAbsenceTypeModalOpen && (
                        <AbsenceTypeModal
                            open={isAbsenceTypeModalOpen}
                            onClose={handleAbsenceTypeModalClose}
                            onSuccess={handleUpdateSuccess}
                            absenceTypeId={absenceTypeId}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

ReactivateAbsenceTypeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    absenceTypeId: PropTypes.string.isRequired,
};
