import {
    Avatar,
    Box,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    Typography,
    styled,
} from '@mui/material';
import { DEFAULT_EMPLOYEE_URL } from 'api/employee';
import useHttp from 'hooks/useHttp';
import React, { useEffect, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { IconButtonWithTooltip } from 'components/IconButtonWithTooltip';
import Edit from '@mui/icons-material/Edit';
import { theme } from 'utils/theme';
import { EmployeeInformationModal } from 'components/EmployeeInformationModal';
import PersonIcon from '@mui/icons-material/Person';
import { Phone } from '@mui/icons-material';
import FlightIcon from '@mui/icons-material/Flight';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import PropTypes from 'prop-types';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { isAdmin, getEmployeeId } from 'utils/auth';
import { replaceNullValues } from 'utils/misc';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'constants';

const GeneralData = ({ selectedEmployeeId, modalClose }) => {
    const UserBox = styled('div')(() => ({
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        cursor: 'pointer',
    }));
    const {
        data: employeeData,
        loading: loadingEmployeeData,
        error: errorGetEmployee,
        sendRequest: getEmployee,
    } = useHttp();
    const {
        data: employeeInfoData,
        loading: loadingEmployeeInfoData,
        error: errorGetEmployeeInfo,
        sendRequest: getEmployeeInfo,
    } = useHttp();

    const currentEmployeeId = getEmployeeId();
    const [showProfileEditModal, setShowProfileEditModal] = useState(false);
    const [employeeInformationId, setEmployeeInformationId] = useState('');
    const [snackbars, setSnackbars] = useState([]);
    const [employeeId, setEmployeeId] = useState(selectedEmployeeId || currentEmployeeId);
    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);

    useEffect(() => {
        if (selectedEmployeeId) {
            setEmployeeId(selectedEmployeeId);
        } else {
            setEmployeeId(currentEmployeeId);
        }
    }, [employeeId, selectedEmployeeId]);

    useEffect(() => {
        if (isGridDataRefreshNeeded) {
            getEmployee({
                url: `${DEFAULT_EMPLOYEE_URL}/${employeeId}`,
            });
            getEmployeeInfo({
                url: `${DEFAULT_EMPLOYEE_URL}/employeeinformation/${employeeId}`,
            });
            setIsGridDataRefreshNeeded(false);
        }
    }, [isGridDataRefreshNeeded]);

    const handleEmployeeInformation = () => {
        setEmployeeInformationId(employeeInfoData ? employeeInfoData.id : '');
        setShowProfileEditModal(true);
    };

    const handleCreateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleEmployeeInformationModalClose = () => {
        setShowProfileEditModal(false);
    };

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };
        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const hasViewPermission = () => isAdmin() || employeeId === currentEmployeeId;

    return (
        <>
            <Grid item>
                <Box>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {loadingEmployeeData || loadingEmployeeInfoData ? (
                            <CircularProgress />
                        ) : (
                            <Card>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingInline: 8,
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <UserBox sx={{ margin: '16px' }}>
                                            <Avatar
                                                sx={{
                                                    width: 120,
                                                    height: 120,
                                                    bgcolor: theme.palette.primary.main,
                                                }}
                                                src={employeeData ? employeeData.pictureUrl : ''}
                                            />
                                        </UserBox>
                                        <Typography variant="h4">{`${employeeData.firstName} ${employeeData.lastName} | ${employeeData.category}`}</Typography>
                                        <Typography variant="h6">{employeeData.email}</Typography>
                                        <Typography variant="caption">{`Hire Date: ${format(
                                            employeeData.hireDate
                                                ? new Date(employeeData.hireDate).setHours(0, 0, 0, 0)
                                                : new Date(),
                                            DATE_FORMAT
                                        )}`}</Typography>
                                    </Grid>
                                    <Divider sx={{ marginTop: '25px', marginBottom: '5px' }} />

                                    <Box>
                                        <Grid container sx={{ position: 'relative' }}>
                                            <Grid item>
                                                <Typography
                                                    sx={{
                                                        textAlign: 'center',
                                                        marginTop: '36px',
                                                        color: theme.palette.error.main,
                                                    }}
                                                >
                                                    {errorGetEmployeeInfo &&
                                                        'There is currently no employee information'}
                                                </Typography>
                                            </Grid>
                                            {hasViewPermission() && (
                                                <Grid
                                                    item
                                                    justifySelf={'flex-end'}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        right: '0',
                                                    }}
                                                >
                                                    <IconButtonWithTooltip
                                                        onClick={handleEmployeeInformation}
                                                        icon={<Edit />}
                                                        tooltipText="Edit"
                                                        color={theme.palette.primary.main}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                    {employeeInfoData && (
                                        <>
                                            {hasViewPermission() && (
                                                <>
                                                    <Box>
                                                        <Grid
                                                            container
                                                            sx={{ position: 'relative' }}
                                                        >
                                                            <Grid item>
                                                                <Grid
                                                                    container
                                                                    direction={'row'}
                                                                    spacing={2}
                                                                    alignItems={'center'}
                                                                >
                                                                    <Grid item>
                                                                        <Avatar
                                                                            sx={{
                                                                                bgcolor: theme.palette.primary.main,
                                                                            }}
                                                                        >
                                                                            <PersonIcon />
                                                                        </Avatar>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '18px',
                                                                                margin: '16px 0px',
                                                                            }}
                                                                            variant="boldText"
                                                                        >{`Personal information`}</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid
                                                                    container
                                                                    spacing={6}
                                                                >
                                                                    <Grid item>
                                                                        <Grid item>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    marginTop: '8px',
                                                                                }}
                                                                                variant='h6'
                                                                            >
                                                                                {`Date of Birth: `}
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                }}
                                                                                variant='h6'
                                                                            >
                                                                                {`JMBG: `}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid item>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    marginTop: '8px',
                                                                                    width: '600px',
                                                                                    wordWrap: 'break-word',
                                                                                }}
                                                                                variant='body2'
                                                                            >
                                                                                {employeeInfoData.dateOfBirth
                                                                                    ? format(
                                                                                        employeeInfoData.dateOfBirth
                                                                                            ? new Date(
                                                                                                employeeInfoData.dateOfBirth
                                                                                            ).setHours(0, 0, 0, 0)
                                                                                            : replaceNullValues(),
                                                                                        DATE_FORMAT
                                                                                    )
                                                                                    : replaceNullValues(
                                                                                        employeeInfoData.dateOfBirth
                                                                                    )}
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    width: '600px',
                                                                                    wordWrap: 'break-word',
                                                                                }}
                                                                                variant='body2'
                                                                            >
                                                                                {employeeInfoData.jmbg}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid
                                                                            container
                                                                            spacing={6}
                                                                        >
                                                                            <Grid item>
                                                                                <Grid item>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: '16px',
                                                                                            marginTop: '8px',
                                                                                        }}
                                                                                        variant='h6'
                                                                                    >
                                                                                        {`Allergies: `}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid item>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: '16px',
                                                                                            marginTop: '8px',
                                                                                            width: '600px',
                                                                                            wordWrap: 'break-word',
                                                                                        }}
                                                                                        variant='body2'
                                                                                    >
                                                                                        {employeeInfoData.allergies}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '25px',
                                                            marginBottom: '5px',
                                                        }}
                                                    />
                                                </>
                                            )}

                                            <Box>
                                                <Grid container>
                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction={'row'}
                                                            spacing={2}
                                                            alignItems={'center'}
                                                        >
                                                            <Grid item>
                                                                <Avatar
                                                                    sx={{
                                                                        bgcolor: theme.palette.primary.main,
                                                                    }}
                                                                >
                                                                    <Phone />
                                                                </Avatar>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '18px',
                                                                        margin: '16px 0px',
                                                                    }}
                                                                    variant='boldText'
                                                                >{`Phone numbers`}</Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid
                                                            container
                                                            spacing={6}
                                                        >
                                                            <Grid item>
                                                                <Grid item>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            marginTop: '8px',
                                                                        }}
                                                                        variant='h6'
                                                                    >{`Private phone number: `}</Typography>
                                                                    <Typography
                                                                        sx={{ fontSize: '16px' }}
                                                                        variant='h6'
                                                                    >{`Softhouse phone number: `}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid item>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            marginTop: '8px',
                                                                            width: '600px',
                                                                            wordWrap: 'break-word',
                                                                        }}
                                                                        variant='body2'
                                                                    >
                                                                        {employeeInfoData.privatePhoneNumber}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            width: '600px',
                                                                            wordWrap: 'break-word',
                                                                        }}
                                                                        variant='body2'
                                                                    >
                                                                        {employeeInfoData.softhousePhoneNumber}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {hasViewPermission() && (
                                                <>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '25px',
                                                            marginBottom: '5px',
                                                        }}
                                                    />
                                                    <Box>
                                                        <Grid container>
                                                            <Grid item>
                                                                <Grid
                                                                    container
                                                                    direction={'row'}
                                                                    spacing={2}
                                                                    alignItems={'center'}
                                                                >
                                                                    <Grid item>
                                                                        <Avatar
                                                                            sx={{
                                                                                bgcolor: theme.palette.primary.main,
                                                                            }}
                                                                        >
                                                                            <HomeIcon />
                                                                        </Avatar>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '18px',
                                                                                margin: '16px 0px',
                                                                            }}
                                                                            variant='boldText'
                                                                        >{`Adresses`}</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container spacing={6}>
                                                                    <Grid item>
                                                                        <Grid item>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    marginTop: '8px',
                                                                                }}
                                                                                variant='h6'
                                                                            >{`CIPS Address: `}</Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                }}
                                                                                variant='h6'
                                                                            >{`Residence Address: `}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid item>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    marginTop: '8px',
                                                                                    width: '600px',
                                                                                    wordWrap: 'break-word',
                                                                                }}
                                                                                variant='body2'
                                                                            >
                                                                                {employeeInfoData.cipsAddress}
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    width: '600px',
                                                                                    wordWrap: 'break-word',
                                                                                }}
                                                                                variant='body2'
                                                                            >
                                                                                {employeeInfoData.residenceAddress}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '25px',
                                                            marginBottom: '5px',
                                                        }}
                                                    />

                                                    <Box>
                                                        <Grid container direction={'row'}>
                                                            <Grid item>
                                                                <Grid
                                                                    container
                                                                    direction={'row'}
                                                                    spacing={2}
                                                                    alignItems={'center'}
                                                                >
                                                                    <Grid item>
                                                                        <Avatar
                                                                            sx={{
                                                                                bgcolor: theme.palette.primary.main,
                                                                            }}
                                                                        >
                                                                            <FlightIcon />
                                                                        </Avatar>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '18px',
                                                                                margin: '16px 0px',
                                                                            }}
                                                                            variant='boldText'
                                                                        >{`Passport`}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                direction={'rox'}
                                                                spacing={6}
                                                            >
                                                                <Grid item>
                                                                    <Grid container spacing={6}>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Passport Number: `}</Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Passport Issued In: `}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {employeeInfoData.passportNumber}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {employeeInfoData.passportIssuedIn}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container spacing={6}>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Issue Date: `}</Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Expiration Date: `}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {format(
                                                                                        employeeInfoData.passportIssueDate
                                                                                            ? new Date(
                                                                                                employeeInfoData.passportIssueDate
                                                                                            ).setHours(0, 0, 0, 0)
                                                                                            : replaceNullValues(),
                                                                                        DATE_FORMAT
                                                                                    )}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {employeeInfoData.passportExpirationDate
                                                                                        ? format(
                                                                                            employeeInfoData.passportExpirationDate
                                                                                                ? new Date(
                                                                                                    employeeInfoData.passportExpirationDate
                                                                                                ).setHours(0, 0, 0, 0)
                                                                                                : replaceNullValues(),
                                                                                            DATE_FORMAT
                                                                                        )
                                                                                        : replaceNullValues(
                                                                                            employeeInfoData.passportExpirationDate
                                                                                        )}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '25px',
                                                            marginBottom: '5px',
                                                        }}
                                                    />

                                                    <Box>
                                                        <Grid container direction={'row'}>
                                                            <Grid item>
                                                                <Grid
                                                                    container
                                                                    direction={'row'}
                                                                    spacing={2}
                                                                    alignItems={'center'}
                                                                >
                                                                    <Grid item>
                                                                        <Avatar
                                                                            sx={{
                                                                                bgcolor: theme.palette.primary.main,
                                                                            }}
                                                                        >
                                                                            <ContactEmergencyIcon />
                                                                        </Avatar>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '18px',
                                                                                margin: '16px 0px',
                                                                            }}
                                                                            variant='boldText'
                                                                        >{`First Emergency Contact`}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                direction={'rox'}
                                                                spacing={6}
                                                            >
                                                                <Grid item>
                                                                    <Grid container spacing={6}>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Name: `}</Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Relation: `}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {
                                                                                        employeeInfoData.firstEmergencyContactName
                                                                                    }
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {
                                                                                        employeeInfoData.firstEmergencyContactRelation
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container spacing={6}>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Phone Number: `}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {
                                                                                        employeeInfoData.firstEmergencyContactPhoneNumber
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '25px',
                                                            marginBottom: '5px',
                                                        }}
                                                    />

                                                    <Box>
                                                        <Grid container direction={'row'}>
                                                            <Grid item>
                                                                <Grid
                                                                    container
                                                                    direction={'row'}
                                                                    spacing={2}
                                                                    alignItems={'center'}
                                                                >
                                                                    <Grid item>
                                                                        <Avatar
                                                                            sx={{
                                                                                bgcolor: theme.palette.primary.main,
                                                                            }}
                                                                        >
                                                                            <ContactEmergencyIcon />
                                                                        </Avatar>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '18px',
                                                                                margin: '16px 0px',
                                                                            }}
                                                                            variant='boldText'
                                                                        >{`Second Emergency Contact`}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                direction={'rox'}
                                                                spacing={6}
                                                            >
                                                                <Grid item>
                                                                    <Grid container spacing={6} sx={{ height: "100%" }}>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Name: `}</Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Relation: `}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {
                                                                                        employeeInfoData.secondEmergencyContactName
                                                                                    }
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {
                                                                                        employeeInfoData.secondEmergencyContactRelation
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container spacing={6}>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                    }}
                                                                                    variant='h6'
                                                                                >{`Phone Number: `}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        marginTop: '8px',
                                                                                        width: '600px',
                                                                                        wordWrap: 'break-word',
                                                                                    }}
                                                                                    variant='body2'
                                                                                >
                                                                                    {
                                                                                        employeeInfoData.secondEmergencyContactPhoneNumber
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </>
                                            )}
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        )}
                    </div>
                    {showProfileEditModal && (
                        <EmployeeInformationModal
                            open={showProfileEditModal}
                            employeeId={employeeId}
                            employeeInformationId={employeeInformationId}
                            onClose={handleEmployeeInformationModalClose}
                            onSuccess={handleCreateSuccess}
                        />
                    )}
                    {snackbars.map((snackbar) => (
                        <AlertSnackBar
                            key={snackbar.key}
                            message={snackbar.message}
                            severity={snackbar.severity}
                            onClose={() => closeSnackbar(snackbar.key)}
                        />
                    ))}
                </Box>
            </Grid>
        </>
    );
};

GeneralData.propTypes = {
    selectedEmployeeId: PropTypes.string,
    modalClose: PropTypes.func,
};

export default GeneralData;
