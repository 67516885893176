import { REPLACE_NULL_VALUE } from 'constants';

export const isActive = (row) => row.active === 'true';
export const isWrittenOff = (row) => row.status === 'Written off';

export const isLoading = (...loadingStates) => {
    return loadingStates.some((loading) => loading);
};

export const hexToRgba = (hex, alpha = 1) => {
    if (hex) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return '';
};

export const replaceNullValues = (value) => {
    return value ? value : REPLACE_NULL_VALUE;
};
