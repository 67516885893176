import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function CustomToolbar({ menuItems }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action) => () => {
        action();
        handleMenuClose();
    };

    return (
        <GridToolbarContainer>
            <GridToolbarExport />
            {menuItems.length > 0 && (
                <Button
                    aria-controls="action-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    endIcon={<MoreVertIcon />}
                    variant="contained"
                >
                    Actions
                </Button>
            )}
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={handleMenuItemClick(item.action)}>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
        })
    ).isRequired,
    onExport: PropTypes.func.isRequired,
};

export const CommonDataGrid = ({
    rows,
    columns,
    onCellClick,
    disableRowSelectionOnClick = false,
    useCustomToolbar = true,
    menuItems = [],
}) => {
    const toolbar = useCustomToolbar ? () => <CustomToolbar menuItems={menuItems} /> : null;
    return (
        <DataGrid
            rows={rows}
            columns={columns.filter((column) => column.field !== 'id')}
            disableColumnSelector={true}
            autoHeight={true}
            pageSizeOptions={[25, 50]}
            style={{ width: '100%' }}
            onCellClick={onCellClick}
            slots={{ toolbar }}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
        />
    );
};

CommonDataGrid.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    onCellClick: PropTypes.func,
    disableRowSelectionOnClick: PropTypes.bool,
    useCustomToolbar: PropTypes.bool,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
        })
    ),
};
