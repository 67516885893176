import React, { useContext } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import loginImage from 'assets/images/login.jpg';
import AuthContext from 'contexts/auth-context';
import { Card, Grid, Typography, CircularProgress } from '@mui/material';
import Image from 'components/Image';
import { MessageBox } from 'components/MessageBox/MessageBox';

const Login = () => {
    const authContext = useContext(AuthContext);
    const { isLoading, loginMessage, onLogin } = authContext;
    const googleResponse = (response) => {
        onLogin(response);
    };

    const renderSignInCard = () => {
        if (isLoading) {
            return <CircularProgress />;
        }

        return (
            <>
                <Typography variant="h5" marginBottom="20px" fontWeight="bold">
                    Welcome
                </Typography>
                {loginMessage && <MessageBox messageType="error">{loginMessage}</MessageBox>}
                <GoogleLogin size="large" onSuccess={googleResponse} />
            </>
        );
    };

    return (
        <Grid container width="100%" height="100vh" overflow="hidden">
            <Grid item xs={6}>
                <Image
                    src={loginImage}
                    alt="Softhouse login page image"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
            </Grid>
            <Grid container item xs={6} alignItems="center" justifyContent="center">
                <Card variant="signInCard">{renderSignInCard()}</Card>
            </Grid>
        </Grid>
    );
};

export default Login;
