import React, { useEffect, useState } from 'react';
import { styled, Button, Box, Grid, TextField, InputAdornment } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Groups from '@mui/icons-material/Groups';
import useHttp from 'hooks/useHttp';
import { DEFAULT_TEAM_URL } from 'api/team';
import { CommonDataGrid } from 'components/CommonDataGrid';
import { TeamModal } from 'components/TeamModal';
import { DeleteTeamModal } from 'components/DeleteTeamModal';
import { TeamEmployeesModal } from 'components/TeamEmployeesModal';
import { AlertSnackBar } from 'components/AlertSnackBar';
import { teamColumns, renderEditButton, renderDeleteButton } from 'utils/dataColumns/teamColumns';

const StyledCells = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const Teams = () => {
    const { data: teamsData, error: errorGetTeams, sendRequest: getTeams } = useHttp();
    const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
    const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false);
    const [isTeamEmployeesModalOpen, setIsTeamEmployeesModalOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [teamId, setTeamId] = useState('');
    const [isGridDataRefreshNeeded, setIsGridDataRefreshNeeded] = useState(true);
    const [snackbars, setSnackbars] = useState([]);

    useEffect(() => {
        if (isGridDataRefreshNeeded) {
            getTeams({
                url: DEFAULT_TEAM_URL,
            });
            setIsGridDataRefreshNeeded(false);
        }
    }, [isGridDataRefreshNeeded]);

    useEffect(() => {
        if (errorGetTeams !== null) {
            showSnackbar('Failed to load teams.', 'error');
        }
    }, [errorGetTeams]);

    const teamTableData = [
        ...(teamsData || []).map((team) => ({
            id: team.id,
            name: team.name,
        })),
    ];

    const filteredData = teamTableData.filter((team) => {
        return team.name.toLowerCase().includes(filter.toLowerCase());
    });

    const handleTeamEmployeesClick = (id) => () => {
        setTeamId(id);
        setIsTeamEmployeesModalOpen(true);
    };

    const columnsForTeam = teamColumns.map((column) => {
        if (column.field === 'edit') {
            return {
                ...column,
                renderCell: (params) => renderEditButton(params, setTeamId, setIsTeamModalOpen),
            };
        } else if (column.field === 'delete') {
            return {
                ...column,
                renderCell: (params) =>
                    renderDeleteButton(params, setTeamId, setIsDeleteTeamModalOpen),
            };
        }
        return column;
    });

    columnsForTeam.push({
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
            <GridActionsCellItem
                key="add-to-team"
                icon={<Groups />}
                label="Employees"
                onClick={handleTeamEmployeesClick(params.row.id)}
                showInMenu
            />,
        ],
    });

    const showSnackbar = (message, severity) => {
        const newSnackbar = {
            key: new Date().getTime(),
            message,
            severity,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleAddNewTeamClick = () => {
        setTeamId('');
        setIsTeamModalOpen(true);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleTeamModalClose = () => {
        setIsTeamModalOpen(false);
    };

    const handleDeleteTeamModalClose = () => {
        setIsDeleteTeamModalOpen(false);
    };

    const handleTeamEmployeesModalClose = () => {
        setIsTeamEmployeesModalOpen(false);
    };

    const handleCreateSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const handleDeleteSuccess = (message, severity) => {
        setIsGridDataRefreshNeeded(true);
        showSnackbar(message, severity);
    };

    const renderContent = () => {
        return (
            <>
                <Grid item xs={5} marginBottom="16px">
                    <StyledCells>
                        <h1>Teams</h1>
                        <Button onClick={handleAddNewTeamClick} variant="contained">
                            + Add new team
                        </Button>
                    </StyledCells>
                </Grid>
                <Grid item xs={5} marginBottom="16px">
                    <StyledCells>
                        <TextField
                            placeholder="Search"
                            className="subvariant-search"
                            size="small"
                            value={filter}
                            onChange={handleFilterChange}
                            focused
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {filter ? (
                                            <IconButton
                                                onClick={() => setFilter('')}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        ) : (
                                            <SearchIcon style={{ color: 'black' }} />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </StyledCells>
                </Grid>
                {isTeamModalOpen && (
                    <TeamModal
                        open={isTeamModalOpen}
                        onClose={handleTeamModalClose}
                        onSuccess={handleCreateSuccess}
                        teamId={teamId}
                    />
                )}
                {isDeleteTeamModalOpen && (
                    <DeleteTeamModal
                        open={isDeleteTeamModalOpen}
                        onClose={handleDeleteTeamModalClose}
                        onSuccess={handleDeleteSuccess}
                        teamId={teamId}
                    />
                )}

                {isTeamEmployeesModalOpen && (
                    <TeamEmployeesModal
                        open={isTeamEmployeesModalOpen}
                        onClose={handleTeamEmployeesModalClose}
                        teamId={teamId}
                    />
                )}
                <div style={{ height: '100%', width: '100%' }}>
                    <CommonDataGrid rows={filteredData} columns={columnsForTeam} />
                </div>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </>
        );
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {renderContent()}
        </Box>
    );
};

export default Teams;
