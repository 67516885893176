import { AlertSnackBar } from 'components/AlertSnackBar';
import { Box, Grid, Tabs, Tab, FormControlLabel, Checkbox, TextField, InputAdornment } from '@mui/material'
import React, { useState } from 'react'
import GeneralData from './GeneralData';
import MyInventory from './MyInventory';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const Profile = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [snackbars, setSnackbars] = useState([]);
    const [filter, setFilter] = useState('');
    const [showInactive, setShowInactive] = useState(false);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const closeSnackbar = (key) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleShowInactiveChange = (event) => {
        setShowInactive(event.target.checked);
    };

    const renderSearchTextField = (label) => (
        <Grid item xs={5} marginBottom="16px" marginTop="16px">
            <TextField
                placeholder="Search"
                className="subvariant-search"
                size="small"
                value={filter}
                onChange={handleFilterChange}
                focused
                style={{ marginRight: '16px' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {filter ? (
                                <IconButton onClick={() => setFilter('')} style={{ cursor: 'pointer' }}>
                                    <ClearIcon />
                                </IconButton>
                            ) : (
                                <SearchIcon style={{ color: 'black' }} />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <FormControlLabel
                control={
                    <Checkbox checked={showInactive} onChange={handleShowInactiveChange} />
                }
                label={label}
            />
        </Grid>
    );

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={1} >
                    <Box height="100%" display="flex" alignItems="top" justifyContent="right">
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            orientation="vertical"
                            variant="scrollable"
                        >
                            <Tab label="General data" />
                            <Tab label="My inventory" />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={11} paddingRight={'24px'}>
                    <Box>
                        {selectedTab === 0 && (
                            <GeneralData />
                        )}
                    </Box>
                    <Box>
                        {selectedTab === 1 && (
                            <MyInventory
                                renderSearchTextField={renderSearchTextField}
                                showInactive={showInactive}
                                filter={filter}
                            />
                        )}
                    </Box>
                </Grid>
                {snackbars.map((snackbar) => (
                    <AlertSnackBar
                        key={snackbar.key}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => closeSnackbar(snackbar.key)}
                    />
                ))}
            </Grid>
        </Box>
    )
}

export default Profile;