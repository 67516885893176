export const COLORS = {
    BLACK: '#000',
    WHITE: '#fff',
    LIGHT_GRAY: '#e7e7e7',
    SHADE_OF_GRAY: '#DEDEDE',
    GRAY: '#808080',
    DARK_GRAY: '#605f5f',
    ORANGE: '#dc8045',
    SHADOW: '#919191',
    RED: '#be0b0b',
    GREEN: '#4ABC4E',
    BLUE: '#618FE8',
    GRAY_PLACEHOLDER: '#1A1A1A',
    DARK_BLUE: '#334F68',
    VERY_LIGHT_GRAY: '#f8f8f8',
};
export const API_URL = process.env.REACT_APP_BACKEND_API_BASE_URI;
export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_WITH_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const DATE_FORMAT_BACKEND = 'yyyy-MM-dd';
export const SNACKBAR_CLOSE_TIMEOUT = 2000;
export const DATE_MONTH_DAY = 'MM-dd';
export const HTTP_METHODS = {
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    GET: 'GET',
};

export const ABSENCE_COLOR = {
    Vacation: COLORS.LIGHT_GRAY,
    Education: COLORS.GRAY,
    'Religious holiday - Paid': COLORS.ORANGE,
    'Religious holiday - Unpaid': COLORS.ORANGE,
    'Parental/Marriage/Death in family': COLORS.GRAY,
    'Sick leave': COLORS.ORANGE,
    'Work abroad': COLORS.GRAY,
    'Work from home ': COLORS.RED,
    'Team building': COLORS.GRAY,
};

export const DETAILED_ABSENCE_TYPE_NAMES = [
    'Vacation',
    'Religious holiday - Paid',
    'Religious holiday - Unpaid',
    'Parental/Marriage/Death in family',
];

export const FIRST_YEAR = 2023;

export const INVENTORY_ITEM_STATUS = {
    writtenOff: 'Written off',
    inUse: 'In use',
    available: 'Available',
};

export const INVENTORY_ITEM_STATE = {
    good: 'Good',
    average: 'Average',
    bad: 'Bad',
    critical: 'Critical',
    unknown: 'Unknown',
};
export const LOADER_APPEAR_AFTER = 300;

export const REPLACE_NULL_VALUE = '-';

export const EMPLOYEE_CATEGORY = {
    consultant: 'Consultant',
    overhead: 'Overhead',
    officeAdministrator: 'Office Administrator',
};

export const DEFAULT_ROLE_NAME = 'Employee';

export const EMPLOYEE = {
    notFound: 'Not Found',
    failedToLoad: 'Failed to load',
};
